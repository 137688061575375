export default {
    async deleteGeofenceFromDB(state,uuid) {
        await this.dispatch('geofences/deleteGeofFromDb',uuid)
    },
    async syncGeofencesSuccess(state, payload) {
        if (payload?.deleted){
            payload.deleted.forEach(uuid=>{
                this.commit('geofences/deleteGeofenceFromStore',uuid)
                this.commit('geofences/deleteGeofenceFromMap',uuid)
                this.commit('geofences/deleteGeofenceFromDB',uuid)
            })
        }
        if (payload?.new){
            this.dispatch('geofences/addManyGeofsToDb',payload.new)
        }
        if (payload?.modifyed){
            this.dispatch('geofences/addManyGeofsToDb',payload.modifyed)
        }
    },
    getGeoCatsSuccess(state, payload) {
        state.geocats=[]
        payload.forEach(function (item) {
            state.geocats.push(item)
        })
    },
    getGeofencesSuccess(state, payload) {
        state.geofences=[]
        payload.forEach(function (item) {
            state.geofences.push(item)
        })
    },
    deleteGeofenceFromStore(state,uuid) {
        state.geofences.forEach((geo,index)=>{
            if(geo.uuid===uuid){
                state.geofences.splice(index,1)
            }
        })
    },
    deleteGeofenceFromMap(state,uuid) {
        state.geofenceVectorSource.getFeatures().forEach(ft=>{
            const ftuuid=ft.get('uuid')
            if(ftuuid===uuid){
                state.geofenceVectorSource.removeFeature(ft)
            }
        })
    },
    addGeofencetoStore(state,geofence) {
        state.geofences.push(geofence)
    },
    loading(state) {
        state.status = 'loading'
    },
}