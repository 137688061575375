<template>
  <div CLASS="loginbody" v-on:keypress.enter="logIn">
    <div>
      <div class="login" @submit.prevent="logIn">
        <h1>Maxima Cargo</h1>
        <v-form :aria-errormessage="err" style="width: 100%">
          <v-text-field
              variant="underlined"
              v-model="login"
              label="Login"
              required
              :append-inner-icon="'mdi-account'"
              type="text"
          ></v-text-field>
          <v-text-field
              variant="underlined"
              v-model="password"
              :append-inner-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPass ? 'text' : 'password'"
              label="Password"
              @click:appendInner="showPass = !showPass"
          ></v-text-field>
          <div style="display: flex;justify-content: center">

            <v-btn append-icon="mdi-account-check-outline"
                   color="success"
                   class="mr-4"
                   @click="logIn"
                   style="width: 75%"
                   :aria-errormessage="err"
            >
              Войти
            </v-btn>
          </div>
          <p class="err_message">{{err}}</p>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loginPage",
  data(){
    return {
      login:'',
      password:'',
      showPass:false,
      err:''
    }
  },
  computed:{
    loading:{
      set: function (newval) {
        this.$store.dispatch('app/loading',newval)
      },
      get: function (){
        return this.$store.getters['app/loader']
      }
    }
  },
  methods: {
    logIn:async function () {
      let login = this.login
      let password = this.password
      //console.log('try login')
      this.$store.dispatch('auth/login', { login, password })
          .then(() => {
            //console.log('login ok, start load resurces...')
              this.loading=true
              Promise.all([
                this.$store.dispatch('devices/getDevices'),
                this.$store.dispatch('devices/getDevGroups'),
                this.$store.dispatch('geofences/getCatGeofsfromDB'),//get geofences from idb
                this.$store.dispatch('geofences/getGeoCategories'),
                this.$store.dispatch('auth/firstin',false),
                this.$store.dispatch('geofences/syncGeofances'),
                this.$store.dispatch('app/getGlobalSettings'),
              ])
                  .then(()=>{
                    //console.log('start get done, go homepage')
                    this.loading=false
                    this.$router.push('/')
                  })
                  .catch(err => console.log(err))
                  .finally(()=>{
                    this.loading=false
                  })
          })
          .catch((err) =>{
            this.err=err.response.data
          })
    }}
}
</script>

<style scoped>
.loginbody{
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: none;
  height: 100%;
}
.login{
  margin-top: 25vh;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: end;
  background: white;
  width: 30vw;
  /*box-shadow: 0 0 15px 0 rgb(53,167,110);*/
  border-radius: 2px;
}
.login input{
  width: 100%;
  height: 2rem;
  font-size: 1.2rem;
  text-align: center;
  border: 1px solid black;
  border-radius: 2px;
}

h1{
  margin-bottom: 28px;
  color: black;
  font-weight: bold;
  font-size: 48px;
  width: 100%;
  text-align: center;
}
.err_message{
  color:red;
  width: 100%;
  font-size: small;
  margin-top: 16px;
  text-align: end;
  vertical-align: center;
}
</style>