export default {
    trackers: [],
    types:[
        {
            name: 'Максима Трекер',
            value: 'maximaTracker'
        },
        {
            name: 'Трекер Китай',
            value: 'chinaTracker'
        },
        {
            name: 'АТБ',
            value: 'atbTracker'
        },
        {
            name: 'Кирсен А3',
            value: 'kirsen_A3'
        },
        {
            name: 'Кирсен T2',
            value: 'kirsen_T2'
        },
    ],
}