export default {
    getTasksSuccess(state, payload) {
        state.tasks.splice(0, state.tasks.length)
        payload.forEach(function (item) {
            state.tasks.push(item)
        })
    },
    loading(state) {
        state.status = 'loading'
    },
}