<template>
  <div class="treeline">
    <ul>
      <NodeTree
          :swVisible=swVisible
          :node=treeData
          :show-node=showNode
          :setCurrentObject=setCurrentObject
          :icon="icon"
          :selectedObject=selectedObject
          :main-color="mainColor"
          :selected-color="selectedColor"
      ></NodeTree>
    </ul>
  </div>
</template>

<script>
import NodeTree from './NodeTree';

export default {
  name: 'TreeComponent',
  props: {
    treeData: Object,
    showNode:Function,
    swVisible:Function,
    setCurrentObject:Function,
    icon:String,
    selectedObject:Object,
    mainColor:{
      default: 'black',
      type:String
    },
    selectedColor:{
      default: 'black',
      type:String
    }
  },
    components: {
      NodeTree
    }
};
</script>

<style>
.treeline, .treeline ul, .treeline li {
  margin: 0; padding: 0; line-height: 1; list-style: none;
}
.treeline ul {margin: 0 0 0 8px; /* отступ вертикальной линии */}
.treeline > li:not(:only-child), .treeline li li {
  position: relative;
  padding: 2px 0 0 12px; /* отступ текста */
}
/* Стиль вертикальной линии */
.treeline li:not(:last-child) {
  border-left: 1px solid #ccc;
}
/* Стили горизонтальной линии*/
.treeline li li:before, .treeline > li:not(:only-child):before {
  content: ""; position: absolute; top: 0; left: 0;
  width: 1em; height: 1rem; border-bottom:1px solid #ccc;
}
/* Вертикальная линия последнего пункта в списка */
.treeline li:last-child:before {
  width: 1rem; border-left: 1px solid #ccc;
}
</style>