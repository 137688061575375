export default {
    getLastData({commit},payload) {
        return new Promise((resolve, reject) => {
            this.$http.get('/api/data/lastdata?uuid='+payload)
                .then(resp => {
                    commit('getLastDataSuccess', {
                        uuid:payload,
                        data:resp.data
                    })
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    getMultipleLastData({commit},payload) {
        return new Promise((resolve, reject) => {
            this.$http.post('/api/data/multiplelastdata',payload)
                .then(resp => {
                    commit('loading')
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    getDataTrack({commit},payload) {
        commit('loading')
        return new Promise((resolve, reject) => {
            this.$http.post('/api/data/track',{
                device:payload.device,
                from:payload.from,
                to:payload.to
            })
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}