<template>
<v-container>
  <v-card>
    <v-card-title>
      Аудит действий пользователей
    </v-card-title>
    <v-card-text>

      <template v-if="role.issu">
        <v-table>
          <tbody>
          <tr >
            <td>
              <v-text-field
                  variant="underlined"
                  type="date"
                  v-model="from"
                  label="Начало периода"
                  hide-details
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                  variant="underlined"
                  type="date"
                  v-model="to"
                  label="Конец периода"
                  hide-details
              ></v-text-field>
            </td>
            <td>
              <v-btn
                  prepend-icon="mdi-reload"
                  color="success"
                  @click="getData"
              >
                Запросить
              </v-btn>
            </td>
          </tr>
          </tbody>
        </v-table>
        <v-data-table
            v-if="response"
            :headers="headers"
            :items="response"
        >
          <template v-slot:[`item.user`]="{ item }">
            {{item.raw.user.name}}
          </template>
          <template v-slot:[`item.time`]="{ item }">
            {{new Date(item.raw.time*1000).toLocaleString()}}
          </template>
          <template v-slot:[`item.object`]="{ item }">
            <p>Type:{{item.raw.object.type}}</p>
            <p>Name:{{item.raw.object.name}}</p>
            <p>ID:{{item.raw.object.uuid}}</p>
          </template>
          <template v-slot:[`item.action_result`]="{ item }">
            <v-chip
                v-if="item.raw.action_result"
                color="success"
            >
              Успешно
            </v-chip>
            <v-chip
                v-else
                color="error"
            >
              Ошибка
            </v-chip>
          </template>
        </v-data-table>
        <div>
        </div>
      </template>
      <template v-else>
        <v-chip
            size="large"
            color="error"
        >
          У Вас не трав на просмотр контента этого раздела
        </v-chip>
      </template>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
export default {
  name: "auditPage",
  data(){
    return{
      from:undefined,
      to:undefined,
      response:undefined,
      headers:[
        {
          title:'Дата',
          key:'time'
        },
        {
          title:'ip адрес',
          key:'ip'
        },
        {
          title:'Пользователь',
          key:'user'
        },
        {
          title:'Действие',
          key:'action'
        },
        {
          title:'Результат',
          key:'action_result'
        },
        {
          title:'Модуль',
          key:'module_name'
        },
        {
          title: 'Объект',
          key:'object'
        },
        {
          title: 'Описание',
          key:'description'
        }
      ]
    }
  },
  methods:{
    getData(){
      const from=new Date(this.from).getTime()/1000
      const to=new Date(this.to).getTime()/1000
      this.$http.get(`/api/audit?from=${from}&to=${to}`)
          .then(r=>{
            this.response=r.data
          })
          .catch(e=>{
            alert(e.response.data)
          })
    }
  },
  computed:{
    role(){
      return this.$store.getters['user/role']
    }
  }
}
</script>

<style scoped>

</style>