<template>
  <v-container>
    <v-btn
        class="restart-item"
        icon="mdi-file-excel-outline"
        color="success"
        title="Выгрузить в Excel"
        @click="createXLSX"
    ></v-btn>
    <v-card>
      <v-card-title>Устройства</v-card-title>
      <v-card-subtitle>Список всех трекеров в системе мониторинга</v-card-subtitle>
      <v-card-text>
        <v-input>
          <v-text-field
              v-model="search"
              append-inner-icon="mdi-magnify"
              label="Поиск..."
              single-line
              hide-details
              variant="underlined"
              clearable
          ></v-text-field>
        </v-input>
        <v-data-table
            :height="dataheight"
            :items-per-page="itemsPerPage"
            :headers="headers"
            :items="filteredItems"
            fixed-header
            fixed-footer
        >
          <template v-slot:[`item.active`]="{ item }">
            <v-chip
                label
                variant="outlined"
                v-if="item.raw.active"
                color="success"
            >
              Активен
            </v-chip>
            <v-chip
                v-else
                label
                variant="outlined"
            >
              Отключен
            </v-chip>
          </template>
          <template v-slot:[`item.containerUUID`]="{ item }">
            <v-chip
                label
                variant="outlined"
                v-if="item.raw.containerUUID"
                color="success"
            >
              {{ $store.getters['devices/containerNameByUUID'](item.raw.containerUUID) }}
            </v-chip>
            <v-chip
                v-else
                label
                variant="outlined"
            >
              Отсутствует
            </v-chip>
          </template>
          <template v-slot:[`item.onBoard`]="{ item }">
            <v-chip
                v-if="item.raw.onBoard"
                label
                variant="outlined"
                color="success"
            >
              Установлен
            </v-chip>
            <v-chip
                v-else
                label
                variant="outlined"
            >
              Снят
            </v-chip>
          </template>
          <template v-slot:[`item.montageDate`]="{ item }">
            {{ new Date(item.raw.montageDate * 1000).toLocaleDateString() }}
          </template>
          <template v-slot:[`item.createDate`]="{ item }">
            {{ new Date(item.raw.createDate * 1000).toLocaleDateString() }}
          </template>
          <template v-slot:[`item.batteryEndTime`]="{ item }">
            {{ new Date(item.raw.batteryEndTime * 1000).toLocaleDateString() }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn-group>
              <v-btn
                  icon="mdi-pencil"
                  @click="editTracker(item.raw)"
              ></v-btn>
              <v-btn
                  icon="mdi-trash-can-outline"
                  @click="setDeletedTracker(item.raw)"
              ></v-btn>
            </v-btn-group>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
  <v-btn
      class="add-new-item"
      icon="mdi-plus-box-multiple"
      color="success"
      @click="setNewTracker();modals.create=true"
  ></v-btn>
  <!-- CREATE -->
  <v-dialog
      max-width="600px"
      v-model="modals.create"
  >
    <v-card>
      <v-card-title>
        Добавление нового устройства
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-input>
            <v-text-field
                type="text"
                v-model="newTracker.identificator"
                label="Уникальный Идентификатор"
            >
            </v-text-field>
          </v-input>
          <v-input>
            <v-select
                label="Выберите Тип Устройства"
                :items="trackerTypes"
                item-title="name"
                item-value="value"
                v-model="newTracker.type"
            >
            </v-select>
            <v-divider
                vertical
                class="border-opacity-0"
                thickness="32"
            ></v-divider>
            <v-switch
                style="width: 46px"
                label="Активен"
                v-model="newTracker.active"
                color="success"
            >
            </v-switch>
          </v-input>
          <v-input>
            <v-text-field
                type="text"
                v-model="newTracker.MSISDN"
                label="MSISDN SIM-Карты"
            ></v-text-field>
          </v-input>
          <v-input>
            <v-text-field
                type="text"
                v-model="newTracker.ICCID"
                label="ICCID SIM-Карты"
            ></v-text-field>
          </v-input>
          <v-input>
            <v-text-field
                label="Дата плановой замены АКБ"
                type="date"
                v-model="newTracker.batteryEndTime"
            >
            </v-text-field>
          </v-input>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row justify="space-around">
          <v-btn
              @click="setNewTracker();modals.create=false"
          >
            Отмена
          </v-btn>
          <v-btn
              @click="createTracker()"
          >
            Создать
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- EDIT -->
  <v-dialog
      max-width="600px"
      v-model="modals.edit"
  >
    <v-card>
      <v-card-title>
        Редактирование
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-input>
            <v-text-field
                type="text"
                v-model="editedTracker.identificator"
                label="Уникальный Идентификатор"
            >
            </v-text-field>
          </v-input>
          <v-input>
            <v-select
                label="Выберите Тип Устройства"
                :items="trackerTypes"
                item-title="name"
                item-value="value"
                v-model="editedTracker.type"
            >
            </v-select>
            <v-divider
                vertical
                class="border-opacity-0"
                thickness="32"
            ></v-divider>
            <v-switch
                style="width: 46px"
                label="Активен"
                v-model="editedTracker.active"
                color="success"
            >
            </v-switch>
          </v-input>
          <v-input>
            <v-text-field
                type="text"
                v-model="editedTracker.MSISDN"
                label="MSISDN SIM-Карты"
            ></v-text-field>
          </v-input>
          <v-input>
            <v-text-field
                type="text"
                v-model="editedTracker.ICCID"
                label="ICCID SIM-Карты"
            ></v-text-field>
          </v-input>
          <v-input>
            <v-text-field
                label="Дата плановой замены АКБ"
                type="date"
                v-model="editedTracker.batteryEndTime"
            >
            </v-text-field>
          </v-input>
          <v-input>
            <v-text-field
                label="Дата Монтажа на Контейнер"
                type="date"
                v-model="editedTracker.montageDate"
            >
            </v-text-field>
          </v-input>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row justify="space-around">
          <v-btn
              @click="modals.edit=false"
          >
            Отмена
          </v-btn>
          <v-btn
              @click="updateTracker()"
          >
            Сохранить
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- DEL -->
  <v-dialog
      v-model="modals.delete"
      max-width="300px"
      transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
          color="red"
          title="Требуется подтверждение"
      >
      </v-toolbar>
      <v-card-text>
        Вы дествительно ходите удалить устройство <span
          style="font-weight: bold">{{ deletedTrtacker.name }}</span>?
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row justify="space-around">
          <v-btn
              @click="modals.delete = false"
          >
            Закрыть
          </v-btn>
          <v-btn
              @click="deleteTracker();modals.delete=false"
          >
            Удалить
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
let XLSX = require("xlsx");
export default {
  name: "trackersPage",
  data() {
    return {
      search: undefined,
      headers: [
        {
          title: 'ID',
          align: 'start',
          sortable: true,
          key: 'id',
        }, {
          title: 'Дата создания',
          align: 'start',
          sortable: true,
          key: 'createDate',
        }, {
          title: 'Идентификатор',
          align: 'start',
          sortable: true,
          key: 'identificator',
        }, {
          title: 'Тип',
          align: 'start',
          sortable: true,
          key: 'type',
        }, {
          title: 'Активность',
          align: 'start',
          sortable: true,
          key: 'active',
        }, {
          title: 'Установлен',
          align: 'start',
          sortable: true,
          key: 'onBoard',
        }, {
          title: 'Контейнер',
          align: 'start',
          sortable: true,
          key: 'containerUUID',
        }, {
          title: 'Дата монтажа',
          align: 'start',
          sortable: true,
          key: 'montageDate',
        }, {
          title: 'Дата замены АКБ',
          align: 'start',
          sortable: true,
          key: 'batteryEndTime',
        }, {
          title: 'MSISDN',
          align: 'start',
          sortable: true,
          key: 'MSISDN',
        }, {
          title: 'ICCID',
          align: 'start',
          sortable: true,
          key: 'ICCID',
        }, {
          title: 'Действия',
          align: 'start',
          key: 'actions',
          sortable: false
        },
      ],
      itemsPerPage: 10,
      modals: {
        create: false,
        edit: false,
        delete: false
      },
      currentTracker: undefined,
      newTracker: undefined,
      editedTracker: undefined,
      deletedTrtacker: undefined,
    }
  },
  methods: {
    createXLSX() {
      let wb = XLSX.utils.book_new()
      let worksheet = XLSX.utils.aoa_to_sheet([
        ['ID', 'Дата Создания', 'Идентификатор', 'Тип', 'Активность', 'Установлен', 'Контейнер', 'Дата Установки', 'Дата замены АКБ', 'MSISDN', 'ICCID']
      ]);
      this.filteredItems.forEach((dev) => {
        XLSX.utils.sheet_add_aoa(worksheet, [
          [
            dev.id,
            new Date(dev.createDate * 1000).toLocaleDateString(),
            dev.identificator,
            dev.type,
            (dev.active ? 'Активен' : 'Отключен'),
            (dev.onBoard ? 'Да' : 'Нет'),
            (dev.containerUUID ? dev.containerUUID : 'Не Установлен'),
            (dev.montageDate > 0 ? new Date(dev.montageDate * 1000).toLocaleDateString() : 'Не Установлена'),
            new Date(dev.batteryEndTime * 1000).toLocaleDateString(),
            dev.MSISDN,
            dev.ICCID,
          ]
        ], {origin: -1});
      })
      XLSX.utils.book_append_sheet(wb, worksheet, "Трекеры");
      XLSX.writeFile(wb, `Выгрузка Трекеров ${new Date().toLocaleString()}.xlsx`);
    },
    setDeletedTracker(obj) {
      this.deletedTrtacker = {
        id: obj.id,
        name: obj.identificator
      }
      this.modals.delete = true
    },
    deleteTracker() {
      this.$store.dispatch('trackers/deleteTracker', this.deletedTrtacker)
          .then(() => alert("Устройство успешно удалено из системы"))
          .catch(e => alert(e.response.data))
          .finally(() => this.deletedTrtacker = undefined)
    },
    editTracker(obj) {
      this.editedTracker = {
        id: obj.id,
        identificator: obj.identificator,
        type: obj.type,
        active: obj.active,
        MSISDN: obj.MSISDN,
        ICCID: obj.ICCID,
        montageDate: this.dateFromTimestamp(obj.montageDate),
        batteryEndTime: this.dateFromTimestamp(obj.batteryEndTime),
      }
      this.modals.edit = true
    },
    createTracker() {
      const payload = {
        identificator: this.newTracker.identificator,
        type: this.newTracker.type,
        active: this.newTracker.active,
        MSISDN: this.newTracker.MSISDN,
        ICCID: this.newTracker.ICCID,
        batteryEndTime: Math.floor(new Date(this.newTracker.batteryEndTime).getTime() / 1000),
      }
      this.$store.dispatch('trackers/createTracker', payload)
          .then(() => {
            alert("Устройство успешно добавлено")
          })
          .catch(e => {
            alert(e.response.data)
          })
          .finally(() => {
            this.modals.create = false
          })
    },
    updateTracker() {
      const payload = {
        id: this.editedTracker.id,
        identificator: this.editedTracker.identificator,
        type: this.editedTracker.type,
        active: this.editedTracker.active,
        MSISDN: this.editedTracker.MSISDN,
        ICCID: this.editedTracker.ICCID,
        batteryEndTime: Math.floor(new Date(this.editedTracker.batteryEndTime).getTime() / 1000),
        montageDate: Math.floor(new Date(this.editedTracker.montageDate).getTime() / 1000),
      }
      this.$store.dispatch('trackers/updateTracker', payload)
          .then(() => {
            alert("Устройство успешно обновлено")
          })
          .catch(e => {
            alert(e.response.data)
          })
          .finally(() => {
            this.modals.edit = false
          })
    },
    setNewTracker() {
      this.newTracker = {
        identificator: undefined,
        type: undefined,
        active: true,
        MSISDN: undefined,
        ICCID: undefined,
        batteryEndTime: undefined
      }
    },
    dateFromTimestamp(timestamp) {
      const dateTime = new Date(timestamp * 1000)
      const year = String(dateTime.getFullYear())
      const month = ("0" + (dateTime.getMonth() + 1)).slice(-2)
      const day = ("0" + (dateTime.getDate())).slice(-2)
      return year + '-' + month + '-' + day
    }
  },
  computed: {
    objects() {
      return this.$store.getters['trackers/trackers']
    },
    trackerTypes(){
      return this.$store.getters['trackers/trackerTypes']
    },
    filteredItems() {
      const filter = (element, search) => {
        if (typeof (element) !== "string") {
          return false
        }
        return element.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      }
      return this.objects.filter(i => {
        if (!this.search) {
          return true
        } else {
          const bool = Object.keys(i).some(j => filter(i[j], this.search))
          console.log('bool', bool)
          return bool
        }
      })
    },
    dataheight() {
      return window.innerHeight - 340
    }
  },
  mounted() {
    this.$store.dispatch('trackers/getTrackers')
  }

}
</script>

<style scoped>
.add-new-item {
  position: fixed;
  bottom: 24px;
  right: 24px;
}

.restart-item {
  z-index: 1;
  position: fixed;
  top: 100px;
  right: 72px;
}
</style>