export default {
    getRolesSuccess(state, payload) {
        state.roles.splice(0, state.roles.length)
        payload.forEach(function (item) {
            state.roles.push(item)
        })
    },
    loading(state) {
        state.status = 'loading'
    },
}