const DB_NAME = 'geofences'
const STORAGE_NAME = 'geofs'
const OPTIONS='opts'
const DB_VERSION = 2
let DB

export default {
    async getDb () {
        return new Promise((resolve, reject) => {
            if (DB) {
                return resolve(DB)
            }
            const request = window.indexedDB.open(DB_NAME, DB_VERSION)

            request.onerror = () => {
                console.log('Error opening db', request.error)
                reject(request.error)
            }

            request.onsuccess = () => {
                DB = request.result
                resolve(DB)
            }

            request.onupgradeneeded = e => {
                //console.log('create DB')
                let db = e.target.result
                //console.log('e.oldVersion',e.oldVersion)
                switch(e.oldVersion) { // существующая (старая) версия базы данных
                    case 0://если нет базы вообще
                    {
                        let geofstore = db.createObjectStore(STORAGE_NAME, {autoIncrement: false, keyPath: 'uuid'})
                        geofstore.createIndex('super_idx', 'super')
                        geofstore.createIndex('parent_geo_idx', 'parent_geo')
                        geofstore.createIndex('category_Id_idx', 'category_Id')
                        geofstore.createIndex('admin_dev_idx', 'admin_dev')
                        db.createObjectStore(OPTIONS, {autoIncrement: false, keyPath: 'name'})
                        break;
                    }
                    case 1: {
                        db.deleteObjectStore(STORAGE_NAME)
                        let geofstore = db.createObjectStore(STORAGE_NAME, {autoIncrement: false, keyPath: 'uuid'})
                        geofstore.createIndex('super_idx', 'super')
                        geofstore.createIndex('parent_geo_idx', 'parent_geo')
                        geofstore.createIndex('category_Id_idx', 'category_Id')
                        geofstore.createIndex('admin_dev_idx', 'admin_dev')
                        break;
                    }
                }
            }
        })
    },
    async deleteGeo (uuid) {
        const db = await this.getDb()
        return new Promise((resolve,reject) => {
            const trans = db.transaction([STORAGE_NAME], 'readwrite')
            const store = trans.objectStore(STORAGE_NAME)
            const res=store.delete(uuid)
            res.onsuccess=()=>{
                resolve(res.result)
            }
            res.onerror=()=>{
                reject(res.error)
            }
        })
    },
    async getGeofs () {
        let db = await this.getDb()

        return new Promise((resolve,reject) => {
            let trans = db.transaction([STORAGE_NAME], 'readonly')
            trans.oncomplete = () => {
                resolve(geofences)
            }
            trans.onerror = () => {
                reject(trans.error)
            }

            const store = trans.objectStore(STORAGE_NAME)
            const geofences = []

            const request=store.openCursor()
            request.onsuccess =()=>{
                const cursor = request.result
                if (cursor) {
                    geofences.push(cursor.value)
                    cursor.continue()
                }
            }
            request.onerror=()=>{
                    reject(request.error)
                }
        })
    },
    async getSuperGeofs () {
        let db = await this.getDb()

        return new Promise((resolve,reject) => {
            let trans = db.transaction([STORAGE_NAME], 'readonly')
            trans.oncomplete = () => {
                resolve(geofences)
            }
            trans.onerror = () => {
                reject(trans.error)
            }

            const store = trans.objectStore(STORAGE_NAME)

            const geofences = []

            const superIndex=store.index('super_idx')
            const request=superIndex.openCursor()
            request.onsuccess =()=>{
                const cursor = request.result
                if (cursor) {
                    geofences.push(cursor.value)
                    cursor.continue()
                }
            }
            request.onerror=()=>{
                    reject(request.error)
                }
        })
    },
    async getAdministrativGeofs () {
        let db = await this.getDb()
        return new Promise((resolve,reject) => {
            let trans = db.transaction([STORAGE_NAME], 'readonly')
            trans.oncomplete = () => {
                resolve(geofences)
            }
            trans.onerror = () => {
                reject(trans.error)
            }

            const store = trans.objectStore(STORAGE_NAME)

            const geofences = []

            const Index=store.index('admin_dev_idx')
            const request=Index.openCursor(1)
            request.onsuccess =()=>{
                const cursor = request.result
                if (cursor) {
                    geofences.push(cursor.value)
                    cursor.continue()
                }
            }
            request.onerror=()=>{
                    reject(request.error)
                }
        })
    },
    async getCatGeofs (catID) {
        let db = await this.getDb()

        return new Promise((resolve,reject) => {
            let trans = db.transaction([STORAGE_NAME], 'readonly')
            trans.oncomplete = () => {
                resolve(geofences)
            }
            trans.onerror = () => {
                reject(trans.error)
            }

            const store = trans.objectStore(STORAGE_NAME)
            const geofences = []

            const catIndex=store.index('category_Id_idx')
            const request=catIndex.openCursor(catID)
            request.onsuccess =()=>{
                const cursor = request.result
                if (cursor) {
                    geofences.push(cursor.value)
                    cursor.continue()
                }
            }
            request.onerror=()=>{
                    reject(request.error)
                }
        })
    },
    async deleteMany (uuids) {
        let db = await this.getDb()

        return new Promise((resolve,reject) => {
            let trans = db.transaction([STORAGE_NAME], 'readwrite')
            trans.oncomplete = () => {
                resolve()
            }
            trans.onerror = () => {
                reject(trans.error)
            }

            const store = trans.objectStore(STORAGE_NAME)
            uuids.forEach(uuid=>{
                store.delete(uuid)
            })
        })
    },
    async getOptions () {
        let db = await this.getDb()
        return new Promise((resolve,reject) => {
            let trans = db.transaction([OPTIONS], 'readonly')
            const store = trans.objectStore(OPTIONS)
            const request=store.getAll()
            request.onsuccess=()=>{
               resolve(request.result)}
            request.onerror=()=>{
                reject(request.error)
            }
            })
    },
    async saveGeof (geof) {
        let db = await this.getDb()

        return new Promise((resolve,reject) => {
            let trans = db.transaction([STORAGE_NAME], 'readwrite')
            let store = trans.objectStore(STORAGE_NAME)
            const res=store.put(geof)
            res.onsuccess=()=>{
                resolve(res.result)
            }
            res.onerror=()=>{
                reject(res.error)
            }
        })
    },
    async saveManyGeofs (geofs) {
        let db = await this.getDb()

        return new Promise((resolve,reject) => {
            let trans = db.transaction([STORAGE_NAME], 'readwrite')
            let store = trans.objectStore(STORAGE_NAME)
            geofs.forEach(geof=>{
                if(geof.super){
                    geof.super=1
                }
                if(geof.category_Id===null && !geof.super){
                    geof.category_Id=0
                }
                //console.log('geof.admin_dev:',geof.admin_dev)
                if(geof.admin_dev){
                    //console.log('geof.admin_dev=1')
                    geof.admin_dev=1
                    //console.log('geof.admin_dev:',geof.admin_dev)
                }else{
                    geof.admin_dev=0
                }
                store.put(geof)
            })

            store.onsuccess=()=>{
                resolve()
            }
            store.onerror=()=>{
                reject(store.error)
            }
        })
    },
    async saveOption (opt) {
        let db = await this.getDb()
        return new Promise((resolve,reject) => {
            let trans = db.transaction([OPTIONS], 'readwrite')
            let store = trans.objectStore(OPTIONS)
            const res=store.put(opt)
            res.onsuccess=()=>{
                resolve(res.result)
            }
            res.onerror=()=>{
                reject(res.error)
            }
        })
    },
    async clear() {
        let db = await this.getDb()
        return new Promise((resolve,reject) => {
            let trans = db.transaction([STORAGE_NAME], 'readwrite')
            let store = trans.objectStore(STORAGE_NAME)
            const res=store.clear()
            res.onsuccess=()=>{
                resolve(res.result)
            }
            res.onerror=()=>{
                reject(res.error)
            }
        })
    },
    async getALLuuids() {
        let db = await this.getDb()
        return new Promise((resolve,reject) => {
            let trans = db.transaction([STORAGE_NAME], 'readonly')
            let store = trans.objectStore(STORAGE_NAME)
            const uuids=store.getAllKeys()
            uuids.onsuccess=()=>{
                resolve(uuids)
            }
            uuids.onerror=()=>{
                reject(uuids.error)
            }
        })
    },
    async getOption(name) {
        let db = await this.getDb()
        return new Promise((resolve,reject) => {
            let trans = db.transaction([OPTIONS], 'readonly')
            let optStore = trans.objectStore(OPTIONS)
            const request=optStore.get(name)
            request.onsuccess =()=> {
                resolve(request.result)
            };
            request.onerror =()=> {
                reject(request.error)
            };
        })
    },
}