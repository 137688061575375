import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";

export default {
    geofenceVectorSource:new VectorSource({format: new GeoJSON()}),
    status: undefined,
    geofences: [],
    geogroups: [],
    geoNodes: {
        label: 'Геозоны',
        id: 0,
        parent:null,
        childrens: [],
        show: true,
        type: 'folder',
        center:[],
        geotype:''
    },
    geoTreeNodes: {
        label: 'Геозоны',
        id: 0,
        parent:null,
        childrens: [],
        show: true,
        type: 'folder',
        center:[],
        geotype:''
    },
    geocats:[],
}