import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'
import state from "./state";

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}