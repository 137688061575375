export default {
    devices: state => state.devices.filter(dev=>dev.current_dev),
    allDevices: state => state.devices,
    devgroups: state => state.devgroups,
    devNodes: state => state.devNodes,
    devTreeNodes: state => state.devTreeNodes,
    containerNameByUUID: (state) => (uuid)=>{
        return state.devices.find(container => container.uuid === uuid)?.name || "Имя Неизвестно"
    },
    devgroupNameById: (state) => (id)=>{
        return state.devgroups.find(devgroup => devgroup.id === id)?.name || "Имя Неизвестно"
    },
}