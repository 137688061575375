<template>
<v-layout full-height>
  <v-container>
    <v-col>
      <v-row justify="space-between" style="padding: 16px">
        <h2>Отчет по Геозонам</h2>
        <template v-if="reportReady">
          <v-menu transition="slide-x-transition">
            <template v-slot:activator="{ props }">
              <v-btn
                  icon="mdi-download"
                  color="success"
                  v-bind="props"
              >
              </v-btn>
            </template>
            <v-list>
              <v-list-subheader>Формат выгрузки</v-list-subheader>
              <v-list-item
                  @click="createPDF()"
              >
                <template v-slot:prepend>
                  <v-icon size="32px" color="#F40407" icon="mdi-file-pdf-box"></v-icon>
                </template>
                <v-list-item-title>PDF</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="createXLSX()"
              >
                <template v-slot:prepend>
                  <v-icon size="32px" color="#185C11" icon="mdi-microsoft-excel"></v-icon>
                </template>
                <v-list-item-title>XLSX</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-row>
      <v-table>
        <thead>
        <tr>
          <td>Группа геозон</td>
          <td>Геозона</td>
          <td>Начало периода</td>
          <td>Конец периода</td>
          <td>Добавить адреса точек</td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <v-input>
              <v-select
                  v-model="request.supergeoUUID"
                  :items="supergeo"
                  item-title="name"
                  item-value="uuid"
                  variant="underlined"
                  v-on:update:menu="request.uuid=undefined"
              ></v-select>
            </v-input>
          </td>
          <td>
            <v-input>
              <v-select
                  v-model="request.uuid"
                  :items="filteredgeofences"
                  item-title="name"
                  item-value="uuid"
                  variant="underlined"
              ></v-select>
            </v-input>
          </td>
          <td>
            <v-input>
              <v-text-field variant="underlined" type="date" v-model="request.startdate"></v-text-field>
            </v-input>
          </td>
          <td>
            <v-input>
              <v-text-field variant="underlined" type="date" v-model="request.enddate"></v-text-field>
            </v-input>
          </td>
          <td style="vertical-align: middle;">
            <v-switch
                v-model="request.withAddress"
                label="(Влияет на время формирования отчета)"
                color="green"
            ></v-switch>
          </td>
        </tr>
        </tbody>
      </v-table>
      <hr>
      <v-table
            v-if="responseData.length>0" id="reportData"
            :height="dataheight"
            fixed-header
        >
          <thead>
          <tr>
            <th>Широта</th>
            <th>Долгота</th>
            <th v-if="request.withAddress">Адрес</th>
            <th>ID контейнера</th>
            <th>ID трекера</th>
            <th>Дата и время</th>
            <th>Событие</th>
            <th>АКБ</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(data,index) in responseData" v-bind:key="index">
            <tr>
              <td>{{data.latitude}}</td>
              <td>{{data.longitude}}</td>
              <td v-if="request.withAddress">{{data.address}}</td>
              <td>{{data.container_name}}</td>
              <td>{{data.device_ident}}</td>
              <td>{{new Date(data.timestamp*1000).toLocaleString()}}</td>
              <td>
                <template v-if="data.event===true"><v-chip variant="outlined" color="success" size="small" prepend-icon="mdi-set-left-center" text="Вход в геозону"></v-chip></template>
                <template v-else><v-chip variant="outlined" prepend-icon="mdi-set-center-right" size="small" color="orange" text="Выход из геозоны"></v-chip></template>
              </td>
              <td>{{data.Battery}} %</td>
            </tr>
          </template>
          </tbody>
        </v-table>
    </v-col>
  </v-container>
</v-layout>
</template>

<script>
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
let XLSX = require("xlsx");
export default {
  name: "devicesPage",
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      request: {
        supergeoUUID:'',
        selected: '',
        startdate: '',
        enddate:'',
        withAddress:false
      },
      responseData:[],
      reportReady:false
    }
  },
  computed:{
    dataheight(){
      return window.innerHeight-340
    },
    geofences: function (){
      return this.$store.getters['geofences/geofences']
    },
    supergeo: function (){
      const res=[]
      this.geofences.forEach(geo=>{
       if(geo.super){
         res.push({
           name:geo.name,
           uuid:geo.uuid
         })
       }
      })
      return res
    },
    geogroups: function (){
      return this.$store.getters['geofences/geogroups']
    },
    filteredgeofences() {
      const res=[]
      this.geofences.forEach(item => {
        if(item.parent_geo === this.request.supergeoUUID || item.uuid===this.request.supergeoUUID){
          res.push({
            name:item.name,
            uuid:item.uuid
          })
        }
      })
      return res
    },
    loading:{
      set: function (newval) {
        this.$store.dispatch('app/loading',newval)
      },
      get: function (){
        return this.$store.getters['app/loader']
      }
    }
  },
  watch:{
    'request':{
      async handler(newval){
        if (newval.uuid!==""&&newval.startdate!==""&&newval.enddate!==""){
          this.loading=true
          this.reportReady=true
          const from=new Date(newval.startdate)
          const to=new Date(newval.enddate)
          const geofence=newval.uuid
          const payload={
            geofence:geofence,
            from:from.getTime()/1000,
            to:to.getTime()/1000,
            address:this.request.withAddress
          }
          this.$store.dispatch('reports/getGeofenceReport', payload)
              .then(response => {
                if(typeof(response.data)!=="undefined"&&response.data!==null){
                  this.responseData=response.data
                }else{
                  this.responseData=[]
                }
                this.loading=false
              })
              .catch(err => {
                this.responseData=[]
                console.log(err)
                this.loading=false
              })
        }
        else{
          this.reportReady=false
        }
      }, deep: true
    }
  },
  methods: {
    geofenceName() {
      let name=''
      this.geofences.forEach(geo=>{
        if(geo.uuid===this.request.uuid){
          name=geo.name
        }
      })
      return name
    },
    groupName() {
      let name=''
      this.geogroups.forEach(group=>{
        if(group.id===this.request.groupid){
          name=group.name
        }
      })
      return name
    },
    createPDF() {
      const tz=Date().toString().match(/([A-Z]+[\\+-][0-9]+.*)/)[1]
      var dd = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [ 40, 60, 40, 60 ],
        content: [
          {
            text: 'Отчет по Геозоне',
            style: 'header',
            alignment: 'left'
          },
          {
            text: 'Название Геозоны: '+this.geofenceName(),
            style: 'headText',
            alignment: 'left'
          },
          {
            text: 'Группа Геозон: '+this.groupName(),
            style: 'headText',
            alignment: 'left'
          },
          {
            text: 'Начало периода: '+new Date(this.request.startdate).toLocaleString(),
            style: 'time',
            alignment: 'left'
          },
          {
            text: 'Конец периода: '+new Date(this.request.enddate).toLocaleString(),
            style: 'time',
            alignment: 'left'
          },
          {
            text: 'Временная зона: '+ tz,
            style: 'time',
            alignment: 'left'
          },
          {
            style: 'tableExample',
            table: {
              body: [
                ['Широта','Долгота', 'ID Контейнера', 'ID Трекера', 'Дата и время', 'Событие', 'АКБ (%)','Адрес'],
              ]
            }
          }
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            alignment: 'justify',
            margin: [0, 20],
            color: 'black'
          },
          time:{
            fontSize: 10,
            bold: false,
            alignment: 'justify',
            margin: [0, 10]
          },
          headText:{
            fontSize: 12,
            bold: false,
            alignment: 'justify',
            margin: [0, 10]
          },
          tableExample: {
            margin: [0, 5, 0, 15]
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
          }
        }
      }
      this.responseData.forEach(item=>{
        const time=new Date(item.timestamp*1000).toLocaleString()
        let Latitude=""
        if(item?.latitude){
          Latitude=String(item.latitude.toFixed(6))
        }
        let Longitude=""
        if(item?.longitude){
          Longitude=String(item.longitude.toFixed(6))
        }
        let container_name=""
        if(item?.container_name){
          container_name=String(item.container_name)
        }
        let device_ident=""
        if(item?.device_ident){
          device_ident=String(item.device_ident)
        }
        let event=item.event?'Вход в зону':'Выход из зоны'

        let battery=""
        if(item?.Battery){
          battery=String(item.Battery)
        }

        let address=""
        if(item?.address){
          address=String(item.address)
        }
        //const link={text:"Карта", link:"http://www.google.com/maps/place/"+Latitude+","+Longitude, fontSize:12,color:'blue'}
        dd.content[6].table.body.push(
            [
              Latitude,
              Longitude,
              container_name,
              device_ident,
              time,
              event,
              battery,
              address
            ]
        )
      })
      pdfMake.createPdf(dd).open();
    },
    createXLSX(){
      //const dom_element=document.getElementById('reportData')
      const tz=Date().toString().match(/([A-Z]+[\\+-][0-9]+.*)/)[1]
      let wb=XLSX.utils.book_new()
      var worksheet = XLSX.utils.aoa_to_sheet([
          ["Отчет по Геозонам"],
          ["Название Геозоны",this.geofenceName()],
          ["Группа Геозон",this.groupName()],
          ["Дата начала периода:",new Date(this.request.startdate)],
          ["Дата окончания периода:",new Date(this.request.enddate)],
          ["Временная зона отчета:", tz],
          [],
          ['Широта','Долгота', 'ID Контейнера', 'ID Трекера', 'Дата и время', 'Событие', 'АКБ (%)','Адрес']
      ]);
      this.responseData.forEach((row)=>{
        XLSX.utils.sheet_add_aoa(worksheet, [
            [
              row.latitude,
              row.longitude,
              row.container_name,
              row.device_ident,
              new Date(row.timestamp*1000),
              row.event?'Вход в зону':'Выход из зоны',
              row.Battery,
              row.address
            ]
        ], {origin:-1});
      })
      XLSX.utils.book_append_sheet(wb, worksheet, "Отчет по Геозоне");
      XLSX.writeFile(wb, 'ОтчетПоГеозоне_'+this.geofenceName()+'.xlsx');
    }
  }
}
</script>

<style scoped>
</style>