import {createApp} from "vue"
import {createVuetify} from 'vuetify'
import {createRouter,createWebHistory} from 'vue-router'

import App from './App.vue'
import routes from './router'
import store from "@/store/index";
import axios from "axios";
import * as comps from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'
import * as labs from 'vuetify/labs/components'

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
const vuetify = createVuetify({
    components: {
        ...comps,
        ...labs
    },
    directives,
    icons: {
        defaultSet: 'mdi',
    },
    theme: {
        defaultTheme: 'light',
    },
    defaults: {
        VDataTable: {
            noDataText: 'Нет Данных',
        },
    },
})
const app = createApp(App)
router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters["auth/isLoggedIn"]) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})
//console.log('process.env:',process.env)
axios.defaults.baseURL=process.env.VUE_APP_PORTAL_BASE_URL
//axios.defaults.baseURL='https://cargotracker.wi-fi.ru/portal-backend'
axios.interceptors.response.use(
    response => {
        return response;
    },
    err => {
        const originalRequest = err.config;
        if (err.response.status === 401 && !originalRequest._retry && originalRequest.url!=='/auth/login' && originalRequest.url!=='/auth/refresh') {
            //Если идет рефреш
            //console.log('originalRequest.url:',originalRequest.url)
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({resolve, reject});
                })
                    .then(token => {
                        return axios.request({
                            url:originalRequest.url,
                            method:originalRequest.method,
                            headers:{
                                'Authorization':'Bearer '+token
                            },
                            data:originalRequest.data,
                            _retry:true
                        });
                    })
                    .catch(err => {
                        return Promise.reject(err);
                    });
            }
            originalRequest._retry = true;
            isRefreshing = true;
            //console.log('start refreshing')
            const refr = store.getters['auth/refreshToken']
            return new Promise( (resolve, reject)=> {
                const payload = {
                    token: refr
                }
                axios.put('/auth/refresh', payload)
                    .then((data) => {
                        //Получили успешный ответ с новой парой токенов и устанавливыаем новый токен исходному запросу
                        // Записываем новую пару в сторадж
                        //console.log(data)
                        // Перевыполняем накопившиеся запросы с новым токеном
                        processQueue(null, data.data.token);
                        //завершаем процедуру обновления токенов
                        store.dispatch('auth/refresh',data)
                        isRefreshing = false;
                        resolve(axios.request({
                            url:originalRequest.url,
                            method:originalRequest.method,
                            headers:{
                                'Authorization':'Bearer '+data.data.token
                            },
                            data:originalRequest.data,
                            _retry:true
                        }));
                        //console.log('refrashed...')
                    })
                    .catch(err => {
                        //console.log('refresh err', err)
                        processQueue(err, null);
                        store.dispatch('app/loading',false)
                        router.push('/login')
                        store.dispatch('auth/logout')
                        reject(err);
                    })
            });
        }
        return Promise.reject(err);
    }
);
app.config.globalProperties.$http = axios
store.$http = axios
let isRefreshing = false;
let failedQueue = [];
const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

const token = store.getters["auth/token"]

if (token) {
    //console.log(token)
    app.config.globalProperties.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

app.use(store)
app.use(router)
app.use(vuetify)
app.mount('#app')