export default {
    getTrackersSuccess(state, payload) {
        state.trackers.splice(0, state.trackers.length)
        payload.forEach(function (item) {
            state.trackers.push(item)
        })
    },
    loading(state) {
        state.status = 'loading'
    },
}