export default {
    drawner(state,pl){
        state.navDrawner=pl
    },
    loading(state) {
        state.status = 'loading'
    },
    load(state,status){
        state.loading=status
    },
    setGlobalSettingsToStorage(state,settings){
        state.globalSettings=settings
    }
}