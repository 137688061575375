<template>
    <v-btn
        class="restart-item"
        icon="mdi-file-excel-outline"
        color="success"
        title="Выгрузить в Excel"
        @click="createXLSX"
    ></v-btn>
    <v-container>
        <v-card>
            <v-card-title>
                Контейнеры
            </v-card-title>
            <v-card-subtitle>
                Управление контейнерами в системе
            </v-card-subtitle>
            <v-card-text>
                <v-text-field
                    v-model="search"
                    append-inner-icon="mdi-magnify"
                    label="Поиск..."
                    single-line
                    hide-details
                    variant="underlined"
                    clearable
                ></v-text-field>
                <v-data-table
                    ref="vDataTable"
                    :page="options.page"
                    :items="filteredItems"
                    :headers="headers"
                    @update:options="options = $event"
                    :items-per-page="options.itemsPerPage"
                    fixed-footer
                    fixed-header
                    :height="dataheight"
                    no-data-text="Ничего не найдено"
                >
                    <template v-slot:[`item.geofences`]="{ item }">
                        <v-chip-group v-if="item.raw.geofences?.length && item.raw.geofences.length<4">
                            <v-chip
                                v-for="(g,i) in item.raw.geofences"
                                :key="i"
                                variant="outlined"
                                label
                                size="small"
                            >
                                {{ g }}
                            </v-chip>
                        </v-chip-group>
                        <v-chip-group v-else>
                            <v-chip
                                variant="outlined"
                                label
                                size="small"
                            >
                                {{ item.raw.geofences[0] }}
                            </v-chip>
                            <v-chip
                                variant="outlined"
                                label
                                size="small"
                            >
                                {{ item.raw.geofences[1] }}
                            </v-chip>
                            <v-chip
                                variant="outlined"
                                label
                                size="small"
                            >
                                {{ item.raw.geofences[2] }}
                            </v-chip>
                            <v-chip
                                variant="outlined"
                                label
                                size="small"
                            >
                                И еще {{ item.raw.geofences.length - 3 }} шт
                            </v-chip>
                        </v-chip-group>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn-group :key="item.raw.current_dev_id">
                            <v-btn
                                icon="mdi-pencil"
                                title="Редактировать"
                                @click="modals.update=true;setCurrentObject(item.raw)"
                            ></v-btn>
                            <v-btn
                                title="Удалить Контейнер"
                                icon="mdi-trash-can"
                                @click="modals.delete=true;setDeletedObject(item.raw)"
                            ></v-btn>
                            <v-btn
                                v-if="item.raw.current_dev_id!==''"
                                icon="mdi-arrow-u-left-top-bold"
                                title="Открепить Текущее Устройство От Контейнера"
                                @click="modals.unmount=true;setCurrentObject(item.raw)"
                            ></v-btn>
                            <v-btn
                                v-if="item.raw.current_dev_id!==''"
                                icon="mdi-autorenew"
                                title="Заменить Устройство На Новое"
                                @click="modals.reconnect=true;setCurrentObject(item.raw)"
                            ></v-btn>
                            <v-btn
                                v-if="item.raw.current_dev_id===''"
                                icon="mdi-shape-rectangle-plus"
                                title="Назначить Устройство на Контейнер"
                                @click="modals.mount=true;setCurrentObject(item.raw)"
                            ></v-btn>
                        </v-btn-group>
                    </template>
                    <template v-slot:[`item.type`]="{item}">
                        <v-chip
                            v-if="item.raw.type!=='Трекер Ну Установлен'"
                            label
                            color="success"
                            :text="item.raw.type"
                        >
                        </v-chip>
                        <v-chip
                            v-else
                            label
                            color="orange"
                            text="Трекер Не Установлен"
                        >
                        </v-chip>
                    </template>
                    <template v-slot:[`item.old_devs`]="{item}">
                        <template v-if="item.raw.old_devs">
                            <v-chip-group>
                                <v-chip
                                    v-for="dev in item.raw.old_devs"
                                    :key="dev.montageDate"
                                    :text="dev.identificator+'-'+new Date(dev.montageDate*1000).toLocaleString()+'-'+new Date(dev.removeDate*1000).toLocaleString()"></v-chip>
                            </v-chip-group>
                        </template>
                        <template v-else>
                            Пусто
                        </template>
                    </template>
                    <template v-slot:[`item.group`]="{item}">
                        <v-chip
                            label
                            :text="item.raw.group.name"></v-chip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
    <template v-if="role.issu">
        <v-menu>
            <template v-slot:activator="{ props }">
                <v-btn class="add-container" icon="mdi-cog-outline" v-bind="props" color="success"></v-btn>
            </template>
            <v-list>
                <v-list-item
                    prepend-icon="mdi-plus-box-multiple"
                    color="success"
                    @click="modals.createContainer=true;setNewObject()"
                >
                    <v-list-item-title>Добавить Контейнер</v-list-item-title>
                </v-list-item>
                <v-list-item
                    prepend-icon="mdi-file-tree-outline"
                    color="success"
                    @click="modals.createFolder=true;setNewObject()"
                >
                    <v-list-item-title>Управление Папками</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </template>
    <!--Create Container-->
    <v-dialog
        v-model="modals.createContainer"
        max-width="500px"
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar
                color="black"
                title="Добавление Контейнера"
            >
            </v-toolbar>
            <v-card-text>
                <v-input type="text">
                    <v-text-field
                        v-model="newObject.container.name"
                        label="Название"
                        variant="underlined"></v-text-field>
                </v-input>
                <v-autocomplete
                    v-model="newObject.container.current_dev"
                    :items='freeTrackers'
                    item-title="identificator"
                    item-value="id"
                    label="Трекер"
                    variant="underlined"
                    @click:clear="newObject.container.current_dev=undefined"
                    clearable
                >
                </v-autocomplete>
                <v-label>Родительская Папка</v-label>
                <v-expansion-panels>
                    <v-expansion-panel
                    >
                        <v-expansion-panel-title>{{ newObject.container.group.label }}</v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <v-col>
                                <TreeComponent
                                    :set-current-object="setNewObgperentid"
                                    :show-node="showNode"
                                    :tree-data="treeGroups"
                                ></TreeComponent>
                            </v-col>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
            <v-card-actions>
                <v-row justify="space-around">
                    <v-btn
                        @click="modals.createContainer = false"
                    >
                        Закрыть
                    </v-btn>
                    <v-btn
                        @click="createObject()"
                    >
                        Создать
                    </v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!--Edit Tree-->
    <v-dialog
        v-model="modals.createFolder"
        max-width="800px"
        transition="dialog-bottom-transition"
    >
        <v-card height="600px">
            <v-toolbar
                color="black"
                title="Добавление Папки"
            >
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <TreeComponent
                        :set-current-object="setNewObgperentid"
                        :show-node="showNode"
                        :tree-data="treeGroups"
                        ></TreeComponent>
                    </v-col>
                    <v-col cols="8">
                        <template
                            v-if="newObject.folder.group.id!=undefined"
                        >
                            Выбранная папка: <v-chip
                                label
                                :text="newObject.folder.group.label"
                            >
                            </v-chip>
                            <v-btn-group>
                                <v-btn
                                    icon="mdi-plus"
                                    @click="newObject.addFolderMode=true"
                                    variant="text"
                                    title="Добавление Подкаталога"
                                >
                                </v-btn>
                                <v-btn
                                    icon="mdi-pencil"
                                    @click="newObject.editFolderMode=true"
                                    variant="text"
                                    title="Изменить"
                                >

                                </v-btn>
                                <v-btn
                                    icon="mdi-delete-forever"
                                    @click="deleteObject"
                                    variant="text"
                                    title="Удаление"
                                >
                                </v-btn>
                            </v-btn-group>
                            <v-input type="text"
                                     v-if="newObject.addFolderMode"
                            >
                                <v-text-field
                                    append-inner-icon="mdi-close"
                                    append-icon="mdi-content-save"
                                    v-model="newObject.folder.label"
                                    label="Название Новой Папки"
                                    variant="underlined"
                                    @click:append-inner="newObject.folder.label=undefined;newObject.addFolderMode=false"
                                    @click:append="createObject"
                                ></v-text-field>
                            </v-input>
                            <v-input type="text"
                                     v-if="newObject.editFolderMode"
                            >
                                <v-text-field
                                    append-inner-icon="mdi-close"
                                    append-icon="mdi-content-save"
                                    v-model="newObject.folder.group.label"
                                    label="Название Текущей Выбранной Папки"
                                    variant="underlined"
                                    @click:append-inner="newObject.editFolderMode=false"
                                    @click:append="updateObject"
                                ></v-text-field>
                            </v-input>
                        </template>

                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-row justify="space-around">
                    <v-btn
                        @click="modals.createFolder = false"
                    >
                        Закрыть
                    </v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!--Edit Container-->
    <v-dialog
        v-model="modals.update"
        max-width="500px"
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar
                color="black"
                title="Редактирование Контейнера"
            >
            </v-toolbar>
            <v-card-text>
                <v-input type="text">
                    <v-text-field
                        v-model="currentObj.name"
                        label="Название"
                        variant="underlined"></v-text-field>
                </v-input>
                <v-label>Родительская Папка</v-label>
                <v-expansion-panels>
                    <v-expansion-panel
                    >
                        <v-expansion-panel-title>{{ currentObj.group.name }}</v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <v-col>
                                <TreeComponent
                                    :set-current-object="setNewObgperentid"
                                    :show-node="showNode"
                                    :tree-data="treeGroups"
                                ></TreeComponent>
                            </v-col>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
            <v-card-actions>
                <v-row justify="space-around">
                    <v-btn
                        @click="modals.update = false"
                    >
                        Закрыть
                    </v-btn>
                    <v-btn
                        @click="updateObject()"
                    >
                        Сохранить Изменения
                    </v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!--Del-->
    <v-dialog
        v-model="modals.delete"
        max-width="300px"
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar
                color="red"
                title="Требуется подтверждение"
            >
            </v-toolbar>
            <v-card-text>
                Вы дествительно ходите удалить Контейнер
                <span style="font-weight: bold"> "{{ deletedObject.name }}"</span>
                ?
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-row justify="space-around">
                    <v-btn
                        @click="modals.delete = false"
                    >
                        Закрыть
                    </v-btn>
                    <v-btn
                        @click="deleteObject()"
                    >
                        Удалить
                    </v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!--UNMOUNT-->
    <v-dialog
        v-model="modals.unmount"
        max-width="300px"
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar
                color="red"
                title="Требуется подтверждение"
            >
            </v-toolbar>
            <v-card-text>
                Вы дествительно ходите открепить трекер от  Контейнера
                <span style="font-weight: bold"> "{{ currentObj.name }}"</span>?
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-row justify="space-around">
                    <v-btn
                        @click="modals.unmount = false"
                    >
                        Закрыть
                    </v-btn>
                    <v-btn
                        @click="reconnectTracker('dismantle');modals.unmount=false"
                    >
                        Открепить
                    </v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!--MOUNT-->
    <v-dialog
        v-model="modals.mount"
        max-width="400px"
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-card-title>
                Установка Трекера на Контейнер
            </v-card-title>
            <v-autocomplete
                :items="freeTrackers"
                v-model="currentObj.trackerid"
                item-title="identificator"
                item-value="id"
                label="Доступные к установке устройства"
            >
            </v-autocomplete>
            <v-divider></v-divider>
            <v-card-actions>
                <v-row justify="space-around">
                    <v-btn
                        @click="modals.mount = false"
                    >
                        Закрыть
                    </v-btn>
                    <v-btn
                        @click="reconnectTracker('mount');modals.mount=false"
                    >
                        Установить
                    </v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!--Reconnect-->
    <v-dialog
        v-model="modals.reconnect"
        max-width="400px"
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-card-title>
                Установка Трекера на Контейнер
            </v-card-title>
            <v-autocomplete
                :items="freeTrackers"
                v-model="currentObj.trackerid"
                item-title="identificator"
                item-value="id"
                label="Доступные для замены устройства"
            >
            </v-autocomplete>
            <v-divider></v-divider>
            <v-card-actions>
                <v-row justify="space-around">
                    <v-btn
                        @click="modals.reconnect = false"
                    >
                        Закрыть
                    </v-btn>
                    <v-btn
                        @click="reconnectTracker('reconnect');modals.reconnect=false"
                    >
                        Заменить
                    </v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import TreeComponent from "@/components/Tree.vue";

let XLSX = require("xlsx");

export default {
    name: "devicesPage",
    components: {TreeComponent},
    data() {
        return {
            currentObj: undefined,
            deletedObject: undefined,
            paginationItems: [
                {
                    title: 5,
                    value: 5
                },
                {
                    title: 10,
                    value: 10
                },
                {
                    title: 15,
                    value: 15
                },
                {
                    title: 'Все',
                    value: undefined
                },
            ],
            options: {
                page: 1,
                itemsPerPage: 10,
            },
            search: null,
            searched: undefined,
            loading: false,
            modals: {
                createFolder: false,
                createContainer: false,
                update: false,
                delete: false,
                mount: false,
                unmount: false,
                reconnect: false,
            },
            newObject: {
                addFolderMode:false,
                editFolderMode:false,
                type: 'car',
                container: undefined,
                folder: undefined,
            },
        }
    },
    computed: {
        headers(){
            const headers=[
                {
                    title: 'Название',
                    key: 'name',
                    align: 'center',
                },
                {
                    title: 'Папка',
                    key: 'group',
                    align: 'center',
                },
                {
                    title: 'Геозоны',
                    key: 'geofences',
                    align: 'center',
                    sortable: false
                },
                {
                    title: 'Тип трекера',
                    key: 'type',
                    align: 'center',
                },
                {
                    title: 'История',
                    key: 'old_devs',
                    align: 'center',
                },
                {
                    title: 'Идентификатор',
                    key: 'identificator',
                    align: 'center',
                },
                {
                    title: 'ICCID',
                    key: 'iccid',
                    align: 'center',
                    sortable: true
                },
                {
                    title: 'MSISDN',
                    key: 'msisdn',
                    align: 'center',
                    sortable: true
                },
                {
                    title: 'Дата Установки',
                    key: 'montage_date',
                    align: 'center',
                    sortable: true
                },
                {
                    title: 'Дата замены АКБ',
                    key: 'battaryendtime',
                    align: 'center',
                },
            ]
            if (this.role.issu){
                headers.push({
                    title: 'Действия',
                    align: 'center',
                    sortable: false,
                    key: 'actions'
                })
            }
            return headers
        },
        treeGroups() {
            return this.$store.getters['devices/devTreeNodes']
        },
        dataheight() {
            return window.innerHeight - 300
        },
        devices() {
            return this.$store.getters['devices/allDevices']
        },
        freeTrackers() {
            return this.$store.getters['trackers/freeTrackers']
        },
        modyfiedDevs() {
            let data = []
            this.devices.forEach(device => {
                if (device.current_dev) {
                    data.push({
                        uuid: device.uuid,
                        name: device.name,
                        type: device.current_dev.type,
                        current_dev_id: device.current_dev_id,
                        group: {
                            id: device.GROUPID,
                            name: this.getDevGroupNameByid(device.GROUPID)
                        },
                        identificator: device.current_dev.identificator,
                        battaryendtime: device.current_dev.battaryendtime ? new Date(device.current_dev.battaryendtime * 1000).toLocaleDateString() : 'Нет Информации',
                        montage_date: device.current_dev.montage_date ? new Date(device.current_dev.montage_date * 1000).toLocaleDateString() : 'Нет Информации',
                        geofences: this.getGeofNames(device.geofences),
                        iccid: device.current_dev.iccid,
                        msisdn: device.current_dev.msisdn,
                        old_devs:device.old_devs
                    })
                } else {
                    data.push({
                        uuid: device.uuid,
                        name: device.name,
                        type: 'Трекер Ну Установлен',
                        current_dev_id: '',
                        group: {
                            id: device.GROUPID,
                            name: this.getDevGroupNameByid(device.GROUPID)
                        },
                        identificator: '',
                        battaryendtime: '',
                        montage_date: '',
                        geofences: this.getGeofNames(device.geofences),
                        iccid: '',
                        msisdn: '',
                        old_devs:device.old_devs
                    })
                }
            })
            return data
        },
        filteredItems() {
            const filter = (element, search) => {
                if (typeof (element) !== "string") {
                    return false
                }
                return element.toLowerCase().indexOf(search.toLowerCase()) !== -1;
            }
            return this.modyfiedDevs.filter(i => {
                if (!this.search) {
                    return true
                } else {
                    const bool = Object.keys(i).some(j => filter(i[j], this.search))
                    return bool
                }
            })
        },
        devgroups() {
            return this.$store.getters['devices/devgroups']
        },
        geofences() {
            return this.$store.getters['geofences/geofences']
        },
        user() {
            return this.$store.getters['user/user']
        },
        role() {
            return this.$store.getters['user/role']
        },
    },
    methods: {
        setNewObgperentid(obj) {
            if (this.modals.createContainer) {
                this.newObject.container.group.id = obj.id
                this.newObject.container.group.label = obj.label
            }
            if (this.modals.createFolder) {
                this.newObject.folder.group.id = obj.id
                this.newObject.folder.group.label = obj.label
                this.newObject.folder.group.parent = obj.parent
            }
            if (this.modals.update) {
                this.currentObj.group.id = obj.id
                this.currentObj.group.name = obj.label
            }
        },
        setNewObject() {
            if (this.modals.createFolder) {
                this.newObject.type = 'folder'
            }
            if (this.modals.createContainer) {
                this.newObject.type = 'car'
            }
            this.newObject.container = {
                name: undefined,
                group: {
                    id: undefined,
                    label: undefined
                },
                current_dev: undefined
            }
            this.newObject.folder = {
                name: undefined,
                group: {
                    id: undefined,
                    label: undefined
                }
            }
        },
        createObject() {
            if (this.newObject.type === 'car') {
                this.$store.dispatch('devices/createDevice', this.newObject.container)
                    .then(() => alert('Контейнер Успешно Создан'))
                    .catch(e => alert(e.response.data))
                    .finally(() => this.modals.createContainer = false)
            }
            if (this.newObject.type === 'folder') {
                this.$store.dispatch('devices/createDevCategory', this.newObject.folder)
                    .then(() => alert('Папка Успешно Создана'))
                    .catch(e => alert(e.response.data))
            }

        },
        updateObject() {
            if(this.newObject.editFolderMode){
                this.$store.dispatch('devices/updataDevCategory',{
                    id:this.newObject.folder.group.id,
                    name:this.newObject.folder.group.label,
                    parent:this.newObject.folder.group.parent
                })
                    .then(()=>alert("Успешно обновлено"))
                    .catch(e=>alert(e.response.data))
            }else{
                const payload = {
                    name: this.currentObj.name,
                    uuid: this.currentObj.uuid,
                    GROUPID: this.currentObj.group.id
                }
                this.$store.dispatch('devices/updataDevice', payload)
                    .then(() => alert('Успешно Обновлено'))
                    .catch(e => alert(e.response.data))
                    .finally(() => this.modals.update = false)
            }
        },
        deleteObject(){
            if (this.modals.createFolder){
                this.$store.dispatch('devices/deleteDevCategory',{id:this.newObject.folder.group.id})
                    .then(()=> {
                        alert('Папка Удалена!')
                    })
                    .catch(e=>alert(e.response.data))
                return
            }
            if(this.modals.delete){
                this.$store.dispatch('devices/deleteDevice',{uuid:this.deletedObject.uuid})
                    .then(()=>alert('Контейнер удален Успешно!'))
                    .catch(e=>alert(e.response.data))
                    .finally(()=>this.modals.delete=false)
                return;
            }
        },
        reconnectTracker(action){
            const pl={
                action:action,
                uuid:this.currentObj.uuid,
                trackerid:this.currentObj.trackerid
            }
            this.$store.dispatch('devices/reconnectTracker',pl)
                .then(()=>alert('Успешно'))
                .catch(e=>alert(e.response.data))
        },
        setCurrentObject(obj) {
            this.currentObj = {
                name: obj.name,
                group: obj.group,
                uuid: obj.uuid,
                trackerid:obj.current_dev_id
            }
        },
        setDeletedObject(obj) {
            this.deletedObject = obj
        },
        showNode: function (node) {
            node.show = !node.show
        },
        createXLSX() {
            let wb = XLSX.utils.book_new()
            let worksheet = XLSX.utils.aoa_to_sheet([
                ['Название', 'Тип', 'Группа', 'Идентификатор', 'Время замены АКБ', 'Дата Установки', 'ICCID', 'MSISDN']
            ]);
            this.filteredItems.forEach((dev) => {
                XLSX.utils.sheet_add_aoa(worksheet, [
                    [
                        dev.name,
                        dev.type,
                        dev.group.name,
                        dev.identificator,
                        dev.battaryendtime,
                        dev.montage_date,
                        dev.iccid,
                        dev.msisdn,
                    ]
                ], {origin: -1});
            })
            XLSX.utils.book_append_sheet(wb, worksheet, "Контейнеры");
            XLSX.writeFile(wb, `Выгрузка Контейнеров ${new Date().toLocaleString()}.xlsx`);
        },
        getDevGroupNameByid(id) {
            let name = ""
            this.devgroups.forEach(group => {
                if (group.id === id) {
                    name = group.name
                }
            })
            return name
        },
        getGeofNames(uuids) {
            let names = []
            if (!uuids) {
                names.push("Не установлены")
                return names
            }
            uuids.forEach(uuid => {
                this.geofences.forEach(fence => {
                    if (fence.uuid === uuid) {
                        names.push(fence.name)
                    }
                })
            })
            return names
        }
    },
    watch: {
        'modyfiedDevs'(newval) {
            this.paginationItems[3].value = newval.length
        }
    },
    mounted() {
        if (this.role.issu) {
            this.$store.dispatch('trackers/getTrackers')
        }
    }
}
</script>

<style scoped>
.add-container {
    position: fixed;
    bottom: 24px;
    right: 24px;
}

.add-folder {
    position: fixed;
    bottom: 24px;
    right: 88px;
}

.table-container {
    display: flex;
    margin-top: 20px;
    flex-grow: 1;
    overflow: hidden;
}

.flex-table {
    display: flex;
    flex-grow: 1;
    width: 100%;
}

.flex-table > div {
  width: 100%;
}

.restart-item{
    z-index: 1;
    position: fixed;
    top: 100px;
    right: 72px;
}

</style>