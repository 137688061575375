export default {
    getTasks({commit}) {
        return new Promise((resolve, reject) => {
            this.$http.get('/api/tasks')
                .then(resp => {
                    commit('getTasksSuccess', resp.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    updateTask({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            this.$http.put('/api/tasks',payload)
                .then(resp => {
                    dispatch('getTasks')
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    createTask({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            this.$http.post('/api/tasks',payload)
                .then(()=>{
                    dispatch('getTasks')
                    resolve()
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    deleteTask({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            this.$http.delete(`/api/tasks?id=${payload}`)
                .then(resp => {
                    dispatch('getTasks')
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    restartTaskManager({dispatch}) {
        return new Promise((resolve, reject) => {
            this.$http.patch(`/api/tasks`)
                .then(resp => {
                    dispatch('getTasks')
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}