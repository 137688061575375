<template>
<v-container>
  <v-col v-if="response">
    <v-row justify="space-between">
      <h2>Текущая ситуация по местоположению контейнеров</h2>
      <v-btn
          icon="mdi-update"
          style="margin: 16px"
          @click="getdata"
          color="success"
      ></v-btn>
    </v-row>
    <v-row>
      <v-card
          style="background: rgba(41,131,222,0.13);margin-right: 1rem;margin-top: 1rem"
          :loading="loading"
          variant="text"
          min-height="220px"
          width="300px"
          max-height="500px"
          elevation="1"
      >
        <v-card-text>
          <h2>Всего в этих странах сейчас находится</h2>
          <br>
          <h1 style="margin-left:1rem;color: green;font-weight: bold">{{response.AllDevsCount}}</h1>
          <br>
          <h2>Контейнеров(а)</h2>
        </v-card-text>
      </v-card>
      <template v-for="item in response.Scope" v-bind:key="item.ZoneName">
        <v-card
            style="margin-right: 1rem;margin-top: 1rem"
            v-if="item.DevCount!==null &&item.DevCount>0"
            :loading="loading"
            elevation="2"
            min-height="220px"
            width="300px"
            max-height="500px"
        >
          <v-card-title>
            <v-chip
                class="ma-2"
                label
                variant="outlined"
                :text="item.ZoneName"
                prepend-icon="mdi-flag"
                color="success"
            >
            </v-chip>
          </v-card-title>
          <v-card-text v-if="item.DevCount!==null &&item.DevCount>0">
            <h3><h1 style="margin-left:1rem;color: green;font-weight: bold">{{item.DevCount}}</h1><br>Контейнеров</h3><br>
            <v-expansion-panels>
              <v-expansion-panel hide-actions="true" >
                <v-expansion-panel-title>Подробнее</v-expansion-panel-title>
                <v-expansion-panel-text class="overflow-auto" style="max-height: 200px">
                  <template v-for="dev in item.Devices" v-bind:key="dev.DeviceId">
                    <v-row style="margin: 8px">
                      Имя: {{dev.DeviceName}} устройство: {{dev.DeviceId}}
                    </v-row>
                  </template>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-card-text v-else><h4>В настоящий момент в этой геозоне нет контейнеров</h4></v-card-text>
        </v-card>

      </template>
    </v-row>
  </v-col>
</v-container>
</template>

<script>
export default {
  name: "SuperGeoBoard",
  data(){
    return{
      response:undefined
    }
  },
  methods:{
    getdata(){
      this.loading=true
      this.$store.dispatch('reports/getDevsInSuperZones')
          .then(resp=>{
            this.response=resp.data
            this.loading=false
          })
          .catch(e=>{
            console.log(e)
            this.loading=false
          })
    }
  },
  computed: {
    loading:{
      set: function (newval) {
        this.$store.dispatch('app/loading',newval)
      },
      get: function (){
        return this.$store.getters['app/loader']
      }
    }
  },
  created() {
    this.getdata()
  }
}
</script>

<style scoped>

</style>