export default {
    getUsers({commit}) {
        return new Promise((resolve, reject) => {
            this.$http.get('/api/users')
                .then(resp => {
                    commit('getUsersSuccess', resp.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    updateUser({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            this.$http.put('/api/users',payload)
                .then(resp => {
                    dispatch('getUsers')
                    resolve(resp)
                })
                .catch(err => {
                    console.log(err)
                    reject(err)
                })
        })
    },
    createUser({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            this.$http.post('/api/users',payload)
                .then(()=>{
                    dispatch('getUsers')
                    resolve()
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    deleteUser({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            this.$http.delete('/api/users',{data:payload})
                .then(resp => {
                    dispatch('getUsers')
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}