import api from "@/api/idb";

export default {
    async getGeofs({commit,dispatch}){
        let geofs = await api.getGeofs()
        commit('geofences/getGeofencesSuccess', geofs,{root:true})
        dispatch('geofences/renderGeofences',null,{root:true})
    },
    addGeofToDb ({commit} ,geof ) {
        commit('loading')
        return api.saveGeof(geof)
    },
    addManyGeofsToDb ({commit} ,geofs ) {
        commit('loading')
        return api.saveManyGeofs(geofs)
    },
    clearGeofDb ({commit}) {
        commit('loading')
        return api.clear()
    },
    getUUIDSfromDB({commit}){
        commit('loading')
        return api.getALLuuids()
    },
    getSupersfromDB({commit}){
        commit('loading')
        return api.getSuperGeofs()
    },
    getCatGeofsfromDB({commit},catID){
        commit('loading')
        return api.getCatGeofs(catID)
    },
    async deleteGeofFromDb ({dispatch}, uuid ) {
        api.deleteGeo(uuid)
        dispatch('geofences/delteteGeofenceFromStore',uuid,{root:true})
    },
    async updateGeoInDB ({commit,dispatch}, geof ) {
        commit('loading')
        await api.deleteGeo(geof.uuid)
        await api.saveGeof(geof)
        await dispatch('geofences/renderGeofences', geof,{root:true})
    }
}