export default {
    login({commit}, candidate) {
        return new Promise((resolve, reject) => {
            this.$http.post('/auth/login', {login: candidate.login, password: candidate.password})
                .then((resp) => {
                    const token = resp.data.token
                    const refresh = resp.data.refresh
                    const user = resp.data.user
                    const role = resp.data.role
                    localStorage.setItem('token', token)
                    localStorage.setItem('refresh', refresh)
                    localStorage.setItem('user', JSON.stringify(user))
                    localStorage.setItem('role', JSON.stringify(role))
                    localStorage.setItem('authstatus', 'true')
                    this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
                    commit('auth_success', resp.data)
                    commit('user/login',resp.data,{root:true})
                    resolve(resp)
                }).catch((err) => {
                commit('auth_error')
                reject(err)
            })
        })
    },
    refresh({commit},payload) {
        const token = payload.data.token
        const refresh = payload.data.refresh
        const user = payload.data.user
        const role = payload.data.role
        localStorage.setItem('token', token)
        localStorage.setItem('refresh', refresh)
        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('role', JSON.stringify(role))
        localStorage.setItem('authstatus', true)
        this.$http.defaults.headers.common.Authorization='Bearer '+token
        commit('auth_success', payload.data)
    },
    logout({commit}) {
        return new Promise((resolve) => {
            commit('enterFirst',true)
            localStorage.removeItem('token')
            localStorage.removeItem('refresh')
            localStorage.removeItem('user')
            localStorage.removeItem('role')
            localStorage.removeItem('authstatus')
            delete this.$http.defaults.headers.common['Authorization']
            commit('logout')
            resolve()
        })
    },
    firstin({commit},val) {
        commit('enterFirst',val)
    },

}