export default {
    options(state,pl){
        state.options=pl
    },
    setOption(state,pl){
        state.options[pl.name]=pl.value
    },
    loading(state) {
        state.status = 'loading'
    },
}