<template>
<v-container>
  <v-col cols="12">
    <v-row justify="space-between">
      <h2>Текущая ситуация по простою контейнеров</h2>
      <v-btn
          icon="mdi-update"
          @click="getReport"
          color="success"
      ></v-btn>
    </v-row>
      <v-row>
        <v-col cols="12">
          <v-row style="margin-top: 16px">
            <v-input>
              <v-text-field
                  type="number"
                  variant="outlined"
                  v-model="days"
                  label="Дней"
              >
              </v-text-field>
            </v-input>
            <v-input>
              <v-text-field
                  type="number"
                  variant="outlined"
                  label="недель"
                  v-model="weeks"
              >
              </v-text-field>
            </v-input>
            <v-input>
              <v-text-field
                  type="number"
                  variant="outlined"
                  label="Месяцев"
                  v-model="months"
              >
              </v-text-field>
            </v-input>
          </v-row>
        </v-col>
      </v-row>
    <template v-if="result">
      <v-row style="max-height: calc(100vh - 300px);overflow: auto">
        <template
            v-for="(item,index) in result.Items"
            :key="item[0]"
        >
          <template v-if="Object.getOwnPropertyNames(item[1].UniqueContainers).length">
            <v-col cols="12">
              <v-row style="padding: 16px">
                <v-card style="margin-right: 1rem;margin-top: 1rem" min-width="300px" min-height="220px" color="#fbeb9a">
                  <v-card-title>
                    <v-chip
                        label
                        variant="outlined"
                        :text="item[1].SuperGeoName"
                        prepend-icon="mdi-flag"
                        color="success"
                    >
                    </v-chip>
                  </v-card-title>
                  <v-card-text>
                    <h1 class="cont-count">{{getLength(index)}}</h1>
                    <h2 class="cont-caption">Контейнера в простое</h2>
                  </v-card-text>
                </v-card>
                <v-card style="margin-right: 1rem;margin-top: 1rem" min-width="300px" min-height="220px" color="#e8eff7" v-if="getAdmLength(index)">
                  <v-card-title>
                    <v-chip
                        label
                        variant="outlined"
                        text="Админ. Деление"
                        prepend-icon="mdi-shield-crown-outline"
                        color="success"
                    >
                    </v-chip>
                  </v-card-title>
                  <v-card-text>
                    <h1 class="cont-count">{{getAdmLength(index)}}</h1>
                    <h2 class="cont-caption">Контейнера в простое</h2>
                  </v-card-text>
                  <v-card-actions>
                    <v-checkbox
                        v-model="showAdm"
                        label="Показать подробно"
                    ></v-checkbox>
                  </v-card-actions>
                </v-card>
                <template v-if="showAdm">
                  <template v-for="adm in item[1].Administrative" :key="adm.name">
                    <template v-if="adm.Count">
                      <v-card style="margin-right: 1rem;margin-top: 1rem" min-width="300" min-height="220px" variant="outlined" color="orange">
                        <v-card-title>
                          <v-chip
                              :text="adm.div_name"
                              color="success"
                              variant="outlined"
                              prepend-icon="mdi-vector-polyline"
                              label
                          >
                          </v-chip>
                        </v-card-title>
                        <v-card-subtitle>{{adm.name}}</v-card-subtitle>
                        <v-card-text>
                          <h1 class="cont-count">{{adm.Count}}</h1>
                          <h2 class="cont-caption">Контейнеров</h2>
                          <v-expansion-panels>
                            <v-expansion-panel >
                              <v-expansion-panel-title>
                                Подробнее
                              </v-expansion-panel-title>
                              <v-expansion-panel-text>
                                <div class="exptext">
                                  <v-table>
                                    <tbody>
                                    <tr v-for="cont in adm.Containers" :key="cont.Container">
                                      <td>
                                        <v-chip
                                            :text="cont.Container+'-'+cont.GeofenceName"
                                            label
                                            variant="outlined"
                                        >
                                        </v-chip>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </v-table>
                                </div>
                              </v-expansion-panel-text>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-card-text>
                      </v-card>
                    </template>
                  </template>
                </template>
                <template v-for="cat in item[1].Categories" :key="cat.CategoryName">
                  <template v-if="cat.Count">
                    <v-card style="margin-right: 1rem;margin-top: 1rem" min-width="300" min-height="220px" variant="outlined">
                     <v-card-title>
                        <v-chip
                            :text="cat.CategoryName"
                            color="success"
                            variant="outlined"
                            prepend-icon="mdi-vector-polyline"
                            label
                        >
                        </v-chip>
                      </v-card-title>
                      <v-card-text>
                        <h1 class="cont-count">{{cat.Count}}</h1>
                        <h2 class="cont-caption">Контейнеров</h2>
                        <v-expansion-panels>
                          <v-expansion-panel >
                            <v-expansion-panel-title>
                              Подробнее
                            </v-expansion-panel-title>
                            <v-expansion-panel-text>
                              <div class="exptext">
                                <v-table>
                                  <tbody>
                                  <tr v-for="cont in cat.Containers" :key="cont.Container">
                                    <td>
                                      <v-chip
                                          :text="cont.Container+'-'+cont.GeofenceName"
                                          label
                                          variant="outlined"
                                      >
                                      </v-chip>
                                    </td>
                                  </tr>
                                  </tbody>
                                </v-table>
                              </div>
                            </v-expansion-panel-text>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-card-text>
                    </v-card>
                  </template>
                </template>
              </v-row>
            </v-col>
          </template>
        </template>
      </v-row>
    </template>
  </v-col>
</v-container>
</template>

<script>
export default {
  name: "DowntimeReport",
  data(){
    return{
      days:2,
      weeks:0,
      months:0,
      result:undefined,
      showAdm:false,
    }
  },
  methods:{
    getReport(){
      this.loading=true
      this.currentObj=undefined
      const days=Number(this.days)+Number(this.weeks)*7+Number(this.months*30)
      this.$store.dispatch('reports/getDownTime',days)
          .then(r=>{
            this.result=r.data
            this.sortedResult()
            this.loading=false
          })
          .catch(e=>{
            console.log(e)
            this.loading=false
          })
    },
    getLength(item){
      let length=0

      for (let cat in this.result.Items[item][1].Categories){
        length+=this.result.Items[item][1].Categories[cat].Count
      }
      return length+this.getAdmLength(item)
    },
    getAdmLength(item){
      let length=0
      for (let adm in this.result.Items[item][1].Administrative){
        length+=this.result.Items[item][1].Administrative[adm].Count
      }
      return length
    },
    getItemsLength(obj){
      let res=0
      for (let cat in obj){
        if(obj[cat].Count){
          res+=1
        }
      }

      return res
    },
    sortedResult(){
      this.result.Items=[...Object.entries(this.result.Items)]
      this.result.Items=this.result.Items.sort((a,b)=>{
        if (this.getItemsLength(a[1].Categories)<this.getItemsLength(b[1].Categories)) return 1;
        if (this.getItemsLength(a[1].Categories)===this.getItemsLength(b[1].Categories)) return 0;
        if (this.getItemsLength(a[1].Categories)>this.getItemsLength(b[1].Categories)) return -1;
      });
    }
  },
  computed:{
    loading:{
      set: function (newval) {
        this.$store.dispatch('app/loading',newval)
      },
      get: function (){
        return this.$store.getters['app/loader']
      }
    },
    geofences() {
      return this.$store.getters['geofences/geofences']
    }
  }
}
</script>

<style scoped>
#mapContent{
  width: 100%;
  height: 600px;
  /*background: #00C3DA;*/
}
.exptext{
  max-height: 16rem;
  overflow: auto;
}
.cont-count{
  font-size: 45px;
  font-weight: bolder;
  color:darkgreen;
  width: 100%;
  text-align: start;
  line-height: normal;
  padding-left: 2rem;
}.cont-caption{
  width: 100%;
  text-align: start;
  line-height: normal;
  margin-top: 1rem;
  margin-bottom: 1rem;

}
</style>