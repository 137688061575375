  <template>
    <v-container>
      <v-col cols="12">
        <v-table v-if="roles">
          <thead>
          <tr>
            <th rowspan=2>Имя</th>
            <th rowspan=2>Признак суперпользователя</th>
            <th colspan="6" style="text-align: center">Права доступа</th>
            <th rowspan="2" v-if="myrole.issu || myrole.permissions.roles.write">Действия</th>
          </tr>
          <tr>
            <th>Пользователи</th>
            <th>Устройства</th>
            <th>Геозоны</th>
            <th>Группы устройств</th>
            <th>Группы геозон</th>
            <th>Роли</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="role in roles" v-bind:key="role.uuid">
            <tr>
              <td>{{ role.name }}</td>
              <td>{{ role.issu }}</td>
              <template v-if="!role.issu">
                <td>
                  <v-icon color="green" icon="mdi-checkbox-marked" v-if="role.permissions.users.read"></v-icon><v-icon color="red" icon="mdi-checkbox-intermediate" v-else></v-icon>чтение
                  <br>
                  <v-icon color="green" icon="mdi-checkbox-marked" v-if="role.permissions.users.write"></v-icon><v-icon color="red" icon="mdi-checkbox-intermediate" v-else></v-icon>запись
                </td>
                <td>
                  <v-icon color="green" icon="mdi-checkbox-marked" v-if="role.permissions.devices.read"></v-icon><v-icon color="red" icon="mdi-checkbox-intermediate" v-else></v-icon>чтение
                  <br>
                  <v-icon color="green" icon="mdi-checkbox-marked" v-if="role.permissions.devices.write"></v-icon><v-icon color="red" icon="mdi-checkbox-intermediate" v-else></v-icon>запись
                </td>
                <td>
                  <v-icon color="green" icon="mdi-checkbox-marked" v-if="role.permissions.geofences.read"></v-icon><v-icon color="red" icon="mdi-checkbox-intermediate" v-else></v-icon>чтение
                  <br>
                  <v-icon color="green" icon="mdi-checkbox-marked" v-if="role.permissions.geofences.write"></v-icon><v-icon color="red" icon="mdi-checkbox-intermediate" v-else></v-icon>запись
                </td>
                <td>
                  <v-icon color="green" icon="mdi-checkbox-marked" v-if="role.permissions.devgroups.read"></v-icon><v-icon color="red" icon="mdi-checkbox-intermediate" v-else></v-icon>чтение
                  <br>
                  <v-icon color="green" icon="mdi-checkbox-marked" v-if="role.permissions.devgroups.write"></v-icon><v-icon color="red" icon="mdi-checkbox-intermediate" v-else></v-icon>запись
                </td>
                <td>
                  <v-icon color="green" icon="mdi-checkbox-marked" v-if="role.permissions.geogroups.read"></v-icon><v-icon color="red" icon="mdi-checkbox-intermediate" v-else></v-icon>чтение
                  <br>
                  <v-icon color="green" icon="mdi-checkbox-marked" v-if="role.permissions.geogroups.write"></v-icon><v-icon color="red" icon="mdi-checkbox-intermediate" v-else></v-icon>запись
                </td>
                <td>
                  <v-icon color="green" icon="mdi-checkbox-marked" v-if="role.permissions.roles.read"></v-icon><v-icon color="red" icon="mdi-checkbox-intermediate" v-else></v-icon>чтение
                  <br>
                  <v-icon color="green" icon="mdi-checkbox-marked" v-if="role.permissions.roles.write"></v-icon><v-icon color="red" icon="mdi-checkbox-intermediate" v-else></v-icon>запись
                </td>
              </template>
              <template v-else>
                <td colspan="6" style="text-align: center">
                  <h2>Полный доступ</h2>
                </td>
              </template>
              <td
                  v-if="myrole.issu || myrole.permissions.roles.write"
              >
                <v-btn-group>
                  <v-btn
                      icon="mdi-account-edit-outline"
                      @click="editedRole=role;modals.edit=true"
                  ></v-btn>
                  <v-btn
                      icon="mdi-trash-can-outline"
                      @click="cpDeletedRole(role);modals.delete=true"
                  ></v-btn>
                </v-btn-group></td>
            </tr>
          </template>
          </tbody>
        </v-table>
      </v-col>
    </v-container>
    <v-dialog
        max-width="500px"
        transition="dialog-bottom-transition"
        v-model="modals.create"
    >
      <v-card>
        <v-toolbar color="black"
                   title="Добавление новой роли" >
        </v-toolbar>
        <v-card-text>
          <v-input
              hide-details=true
          >
            <v-text-field
                type="text"
                label="Название новой роли"
                variant="underlined"
                v-model="newRole.name"
            ></v-text-field>
          </v-input>
          <h4>Настройка прав доступа</h4>
          <v-switch
              v-model="newRole.issu"
              color="green"
              label="Суперпользователь"
          ></v-switch>
          <v-table>
            <tbody>
            <template v-if="!newRole.issu">
              <tr>
                <th>Роли</th>
                <td><v-checkbox color="green"  hide-details=true v-model="newRole.permissions.roles.read" label="Чтение"></v-checkbox></td>
                <td><v-checkbox color="green" hide-details=true v-model="newRole.permissions.roles.write" label="Запись"></v-checkbox></td>
              </tr>
              <tr>
                <th>Пользователи</th>
                <td><v-checkbox color="green" hide-details=true v-model="newRole.permissions.users.read" label="Чтение"></v-checkbox></td>
                <td><v-checkbox color="green" hide-details=true v-model="newRole.permissions.users.write" label="Запись"></v-checkbox></td>
              </tr>
              <tr>
                <th>Группы объектов</th>
                <td><v-checkbox color="green" hide-details=true v-model="newRole.permissions.devgroups.read" label="Чтение"></v-checkbox></td>
                <td><v-checkbox color="green" hide-details=true v-model="newRole.permissions.devgroups.write" label="Запись"></v-checkbox></td>
              </tr>
              <tr>
                <th>Группы геозон</th>
                <td><v-checkbox color="green" hide-details=true v-model="newRole.permissions.geogroups.read" label="Чтение"></v-checkbox></td>
                <td><v-checkbox color="green" hide-details=true v-model="newRole.permissions.geogroups.write" label="Запись"></v-checkbox></td>
              </tr>
              <tr>
                <th>Объекты</th>
                <td><v-checkbox color="green" hide-details=true v-model="newRole.permissions.devices.read" label="Чтение"></v-checkbox></td>
                <td><v-checkbox color="green" hide-details=true v-model="newRole.permissions.devices.write" label="Запись"></v-checkbox></td>
              </tr>
              <tr>
                <th>Геозоны</th>
                <td><v-checkbox color="green" hide-details=true v-model="newRole.permissions.geofences.read" label="Чтение"></v-checkbox></td>
                <td><v-checkbox color="green" hide-details=true v-model="newRole.permissions.geofences.write" label="Запись"></v-checkbox></td>
              </tr>
            </template>
            </tbody>
          </v-table>
        </v-card-text>
        <v-card-actions>
          <v-row justify="space-around">
            <v-btn
                @click="modals.create=false"
            >Закрыть</v-btn>
            <v-btn
                @click="createrole();modals.create=false"
            >Добавить</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        max-width="300px"
        transition="dialog-bottom-transition"
        v-model="modals.delete">
      <v-card>
        <v-toolbar
            color="red"
            title="Требуется подтверждение"
        ></v-toolbar>
        <v-card-text>
          Вы уверены что хотите удалить роль <span style="font-weight: bold">"{{deletedRole.name}}"</span>?
        </v-card-text>
        <v-card-actions>
          <v-row
              justify="space-around"
          >
            <v-btn
                @click="modals.delete=false"
            >Отмена</v-btn>
            <v-btn
                @click="deleteRole();modals.delete=false"
            >Удалить</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        max-width="500px"
        transition="dialog-bottom-transition"
        v-model="modals.edit"
    >
      <v-card>
        <v-toolbar color="black"
                   title="Редактирование роли" >
        </v-toolbar>
        <v-card-text>
          <v-input
              hide-details=true
          >
            <v-text-field
                type="text"
                label="Название роли"
                variant="underlined"
                v-model="editedRole.name"
            ></v-text-field>
          </v-input>
          <h4>Настройка прав доступа</h4>
          <v-switch
              v-model="editedRole.issu"
              color="green"
              label="Суперпользователь"
          ></v-switch>
          <v-table>
            <tbody>
            <template v-if="!editedRole.issu">
              <tr>
                <th>Роли</th>
                <td><v-checkbox color="green"  hide-details=true v-model="editedRole.permissions.roles.read" label="Чтение"></v-checkbox></td>
                <td><v-checkbox color="green" hide-details=true v-model="editedRole.permissions.roles.write" label="Запись"></v-checkbox></td>
              </tr>
              <tr>
                <th>Пользователи</th>
                <td><v-checkbox color="green" hide-details=true v-model="editedRole.permissions.users.read" label="Чтение"></v-checkbox></td>
                <td><v-checkbox color="green" hide-details=true v-model="editedRole.permissions.users.write" label="Запись"></v-checkbox></td>
              </tr>
              <tr>
                <th>Группы объектов</th>
                <td><v-checkbox color="green" hide-details=true v-model="editedRole.permissions.devgroups.read" label="Чтение"></v-checkbox></td>
                <td><v-checkbox color="green" hide-details=true v-model="editedRole.permissions.devgroups.write" label="Запись"></v-checkbox></td>
              </tr>
              <tr>
                <th>Группы геозон</th>
                <td><v-checkbox color="green" hide-details=true v-model="editedRole.permissions.geogroups.read" label="Чтение"></v-checkbox></td>
                <td><v-checkbox color="green" hide-details=true v-model="editedRole.permissions.geogroups.write" label="Запись"></v-checkbox></td>
              </tr>
              <tr>
                <th>Объекты</th>
                <td><v-checkbox color="green" hide-details=true v-model="editedRole.permissions.devices.read" label="Чтение"></v-checkbox></td>
                <td><v-checkbox color="green" hide-details=true v-model="editedRole.permissions.devices.write" label="Запись"></v-checkbox></td>
              </tr>
              <tr>
                <th>Геозоны</th>
                <td><v-checkbox color="green" hide-details=true v-model="editedRole.permissions.geofences.read" label="Чтение"></v-checkbox></td>
                <td><v-checkbox color="green" hide-details=true v-model="editedRole.permissions.geofences.write" label="Запись"></v-checkbox></td>
              </tr>
            </template>
            </tbody>
          </v-table>
        </v-card-text>
        <v-card-actions>
          <v-row justify="space-around">
            <v-btn
                @click="modals.edit=false"
            >Закрыть</v-btn>
            <v-btn
                @click="editRole();modals.edit=false"
            >Обновить</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
        v-if="myrole.issu || myrole.permissions.roles.write"
        class="add-new-item"
        icon="mdi-account-plus"
        color="success"
        @click="modals.create=true"
    ></v-btn>
  </template>
  <script>
  export default {
    name: "rolesPage",
    data(){
      return{
        newRole:{
          name:'Название',
          issu:false,
          permissions:{
            users:{
              read:false,
              write:false
            },
            roles:{
              read:false,
              write:false
            },
            devgroups:{
              read:false,
              write:false
            },
            geogroups:{
              read:false,
              write:false
            },
            devices:{
              read:false,
              write:false
            },
            geofences:{
              read:false,
              write:false
            },
          }
        },
        editedRole:undefined,
        deletedRole:undefined,
        modals:{
          create:false,
          edit:false,
          delete:false
        }
      }
    },
    computed: {
      roles: function () {
        return this.$store.getters['roles/roles']
      },
      user: function () {
        return this.$store.getters['user/user']
      },
      myrole: function () {
        return this.$store.getters['user/role']
      }
    },
    methods:{
      createrole(){
        this.$store.dispatch('roles/createRole',this.newRole)
            .catch(err=>{
              console.log(err)
            })
      },
      deleteRole(){
        this.$store.dispatch('roles/deleteRole',{
          uuid:this.deletedRole.uuid
        })
            .catch(err=>{
              console.log(err)
            })
      },
      editRole(){
        this.$store.dispatch('roles/updateRole',this.editedRole)
            .catch(err=>{
              console.log(err)
            })
      },
      cpDeletedRole(role){
        this.deletedRole={
          uuid:role.uuid,
          name:role.name
        }
      }
    },
    created() {
      this.$store.dispatch('roles/getRoles')
      this.$store.dispatch('users/getUsers')
    }
  }
  </script>

  <style scoped>
  .add-new-item{
    position: fixed;
    bottom: 24px;
    right: 24px;
  }
  th{
    font-size: 14px !important;
  }

  </style>
