export default {
    getTrackers({commit}) {
        return new Promise((resolve, reject) => {
            this.$http.get('/api/trackers')
                .then(resp => {
                    commit('getTrackersSuccess', resp.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    updateTracker({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            this.$http.put(`/api/trackers`,payload)
                .then(resp => {
                    dispatch('getTrackers')
                    resolve(resp)
                })
                .catch(err => {
                    console.log(err)
                    reject(err)
                })
        })
    },
    createTracker({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            this.$http.post('/api/trackers',payload)
                .then(()=>{
                    dispatch('getTrackers')
                    resolve()
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    deleteTracker({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            this.$http.delete(`/api/trackers?id=${payload.id}`)
                .then(resp => {
                    dispatch('getTrackers')
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}