export default {
    auth_success(state, payload) {
        state.refresh = payload.refresh
        state.token = payload.token
        state.authstatus = true
        state.user = payload.user
        state.role = payload.role
        state.status = 'success'
    },
    auth_error(state) {
        state.status = 'error'
    },
    logout(state) {
        state.status = ''
        state.user = {}
        state.refresh = ""
        state.role = {}
        state.token = ""
        state.authstatus = false
    },
    loading(state) {
        state.status = 'loading'
    },
    enterFirst(state,val) {
        state.first = val
    },
}