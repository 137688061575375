<template>
<v-container>
  <v-col >
    <v-row justify="space-between" style="padding: 16px">
      <h2>Сводный отчет по контейнерам</h2>
      <template v-if="reportReady">
        <v-menu transition="slide-x-transition">
          <template v-slot:activator="{ props }">
            <v-btn
                icon="mdi-download"
                color="success"
                v-bind="props"
            >
            </v-btn>
          </template>
          <v-list>
            <v-list-subheader>Формат выгрузки</v-list-subheader>
            <v-list-item
                @click="createPDF()"
            >
              <template v-slot:prepend>
                <v-icon size="32px" color="#F40407" icon="mdi-file-pdf-box"></v-icon>
              </template>
              <v-list-item-title>PDF</v-list-item-title>
            </v-list-item>
            <v-list-item
                @click="createXLSX()"
            >
              <template v-slot:prepend>
                <v-icon size="32px" color="#185C11" icon="mdi-microsoft-excel"></v-icon>
              </template>
              <v-list-item-title>XLSX</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-row>
    <v-table>
      <thead>
      <tr>
        <td>Группа Контейнеров</td>
        <td>Контейнер</td>
        <td>Начало периода</td>
        <td>Конец периода</td>
        <td></td>
        <td>Добавить адреса точек</td>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>
          <v-select
              v-model="request.group"
              :items="requestSource"
              item-title="groupname"
              :item-value="item=>item"
              variant="underlined"
          ></v-select>
        </td>
        <td style="min-width: 200px">
          <v-autocomplete
              clearable
              v-model="request.uuid"
              :items="filtereddevices"
              :item-title="item => item?item.name +' - '+ item.device:''"
              :item-value="item=>item"
              variant="underlined"
              hide-no-data
              label="Выберите Контейнер"
              no-data-text="Ничего не найдено"
          >
          </v-autocomplete>
        </td>
        <td>
          <v-text-field variant="underlined" type="date" v-model="request.startdate"></v-text-field>
        </td>
        <td>
          <v-text-field variant="underlined" type="date" v-model="request.enddate"></v-text-field>
        </td>
        <td>    <v-btn color="success" @click="getReport">Запросить</v-btn></td>
        <td style="vertical-align: middle;">
          <v-switch
              v-model="request.withAddress"
              label="(Влияет на время формирования отчета)"
              color="green"
          ></v-switch>
        </td>
      </tr>
      </tbody>
    </v-table>
    <v-chip
        v-if="lastError"
        color="error"
        variant="outlined"
        label
        style="margin: 8px"
    >{{lastError}}</v-chip>
    <hr>
    <v-table
        v-if="responseData.length>0"
        fixed-header
        :height="dataheight"
    >
      <thead>
      <tr>
        <th>Широта</th>
        <th>Долгота</th>
        <th v-if="request.withAddress">Адрес</th>
        <th>Наименование Геозон</th>
        <th>Скорость</th>
        <th>Дата и время</th>
        <th>АКБ</th>
      </tr>
      </thead>
      <tbody>
      <template v-for="(data,index) in responseData" v-bind:key="index">
        <tr>
          <td>{{data.latitude}}</td>
          <td>{{data.longitude}}</td>
          <td v-if="request.withAddress">{{data.address}}</td>
          <td>
            <trmplate v-for="geo in data.geofences" v-bind:key="geo.Uuid">
              <v-chip variant="outlined" color="success" prepend-icon="mdi-vector-polyline" size="small">{{geo}}</v-chip>
            </trmplate>
          </td>
          <td>{{data.speed}}</td>
          <td>{{new Date(data.timestamp*1000).toLocaleString()}}</td>
          <td>{{data.Battery}} %</td>
        </tr>
      </template>
      </tbody>
    </v-table>
  </v-col>
</v-container>
</template>

<script>
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
let XLSX = require("xlsx");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: "devicesPage",
  data() {
    return {
      lastError:undefined,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      request: {
        group:undefined,
        devicename: '',
        uuid: '',
        startdate: '',
        enddate:'',
        withAddress:false,
        rs:[]
    },
      responseData:[],
      reportReady:false
    }
  },
  computed:{
    dataheight(){
      return window.innerHeight-320
    },
    devices: function (){
      return this.$store.getters['devices/devices']
    },
    devgroups: function (){
      return this.$store.getters['devices/devgroups']
    },
    filtereddevices() {
      let res=[]
      this.requestSource.forEach(item=>{
        if(item.id === this.request.group?.id){
          res= item.items
        }
      })
      return res
    },
    loading:{
      set: function (newval) {
        this.$store.dispatch('app/loading',newval)
      },
      get: function (){
        return this.$store.getters['app/loader']
      }
    },
    requestSource(){
      let result={}
      let res=[]
      this.devices.forEach(dev=>{
        const groupname=this.groupName(dev.GROUPID)
        const groupid=dev.GROUPID
        if(result[groupid]){
          result[groupid].items.push({uuid:dev.uuid,name:dev.name,device:dev.current_dev.identificator})
        }else{
          result[groupid]={
            groupname:groupname,
            id:groupid,
            items:[]
          }
          result[groupid].items.push({uuid:dev.uuid,name:dev.name,device:dev.current_dev.identificator})
        }
      })
      for (let key in result) {
        res.push(result[key])
      }
      return res
    },
  },
  watch:{
    'request.group.id'(){
      this.request.uuid=undefined
    }
  },
  methods: {
    getReport(){
        if (this.request.uuid.uuid&&this.request.startdate!==""&&this.request.enddate!==""){
          this.loading=true
          this.reportReady=true
          const from=new Date(this.request.startdate)
          const to=new Date(this.request.enddate)
          const device=this.request.uuid.uuid
          const payload={
            device:device,
            from:from.getTime()/1000,
            to:to.getTime()/1000,
            address:this.request.withAddress
          }
          this.$store.dispatch('reports/getSummaryReport', payload)
              .finally(()=>{this.loading=false})
              .then(response => {
                if(response.data && response.data!==null ){
                  const temp=response.data
                  console.log(temp)
                  this.responseData=temp.sort((a,b) => {
                    return a.timestamp > b.timestamp ? 1
                        : a.timestamp === b.timestamp ? 0
                            : -1;
                  });
                  this.lastError=undefined
                }else{
                  this.responseData=[]
                  this.lastError="По заданным параметрам ничего не найдено"
                }
              })
              .catch(err => {
                this.responseData=[]
                console.log(err)
                this.lastError=err.response.data
              })
        }
        else{
          this.reportReady=false
        }
    },
    groupName(id) {
      let name=''
      this.devgroups.forEach(group=>{
        if(group.id===id){
          name=group.name
        }
      })
      return name
    },
    createPDF() {
      const tz=Date().toString().match(/([A-Z]+[\\+-][0-9]+.*)/)[1]
      let dd = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [ 40, 60, 40, 60 ],
        content: [
          {
            text: 'Сводный отчет по Контейнеру',
            style: 'header',
            alignment: 'left'
          },
          {
            text: 'Название Контейнера: '+this.request.uuid.name,
            style: 'headText',
            alignment: 'left'
          },
          {
            text: 'Группа Контейнеров: '+this.request.group.groupname,
            style: 'headText',
            alignment: 'left'
          },
          {
            text: 'Начало периода: '+new Date(this.request.startdate).toLocaleString(),
            style: 'time',
            alignment: 'left'
          },
          {
            text: 'Конец периода: '+new Date(this.request.enddate).toLocaleString(),
            style: 'time',
            alignment: 'left'
          },
          {
            text: 'Временная зона: '+ tz,
            style: 'time',
            alignment: 'left'
          },
          {
            style: 'tableExample',
            table: {
              body: [
                ['Широта','Долгота','Геозоны','Скорость','Дата и время','АКБ(%)','Адрес'],
              ]
            }
          }
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            alignment: 'justify',
            margin: [0, 20],
            color: 'black'
          },
          headText:{
            fontSize: 12,
            bold: false,
            alignment: 'justify',
            margin: [0, 10]
          },
          time:{
            fontSize: 10,
            bold: false,
            alignment: 'justify',
            margin: [0, 10]
          },
          tableExample: {
            margin: [0, 5, 0, 15]
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
          }
        }
      }
      this.responseData.forEach(item=>{
        let latitude=""
        if(typeof(item.latitude)!="undefined"){
          latitude=String(item.latitude.toFixed(6))
        }
        let longitude=""
        if(typeof(item.longitude)!="undefined"){
          longitude=String(item.longitude.toFixed(6))
        }
        let geofs=""
        if(item.geofences?.length){
          item.geofences.forEach(geof=>{
            geofs+=geof+', '
          })
        }
        if (geofs.length>2){
          geofs=geofs.slice(0,geofs.length-2)
        }
        let speed=""
        if(typeof(item.speed)!="undefined"){
          speed=String(item.speed)
        }
        const time=new Date(item.timestamp*1000).toLocaleString()
        let battery=""
        if(typeof(item.Battery)!="undefined"){
          battery=String(item.Battery)
        }
        let address=""
        if(typeof(item.address)!="undefined"){
          address=String(item.address)
        }
        //const link={text:"Карта", link:"http://www.google.com/maps/place/"+Latitude+","+Longitude, fontSize:12,color:'blue'}
        dd.content[6].table.body.push(
            [
                latitude,
                longitude,
                geofs,
                speed,
                time,
                battery,
                address
            ]
        )
      })
      pdfMake.createPdf(dd).open();
    },
    createXLSX(){
      //const dom_element=document.getElementById('reportData')
      const tz=Date().toString().match(/([A-Z]+[\\+-][0-9]+.*)/)[1]
      let wb=XLSX.utils.book_new()
      var worksheet = XLSX.utils.aoa_to_sheet([
        ["Сводный отчет по Контейнеру"],
        ["Название Контейнера",this.request.uuid.name],
        ["Группа Контейнеров",this.request.group.groupname],
        ["Дата начала периода:",new Date(this.request.startdate)],
        ["Дата окончания периода:",new Date(this.request.enddate)],
        ["Временная зона отчета:", tz],
        [],
        ['Широта','Долгота','Геозоны','Скорость','Дата и время','АКБ(%)','Адрес']
      ]);
      this.responseData.forEach((row)=>{
        let geofs=""
        if(row.geofences.length){
          row.geofences.forEach(geof=>{
            geofs+=geof+', '
          })
        }
        if (geofs.length>2){
          geofs=geofs.slice(0,geofs.length-2)
        }
        XLSX.utils.sheet_add_aoa(worksheet, [
          [
            row.latitude,
            row.longitude,
            geofs,
            row.speed,
            new Date(row.timestamp*1000),
            row.Battery,
            row.address
          ]
        ], {origin:-1});
      })
      XLSX.utils.book_append_sheet(wb, worksheet, "Отчет по Геозоне");
      XLSX.writeFile(wb, 'СводныйОтчет_'+this.request.uuid.name+'.xlsx');
    },
  }
}
</script>

<style scoped>

</style>