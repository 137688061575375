<template>
  <div style="display: flex;flex-direction: row;width: 100%;height: 100%">
    <div class="filters">
      <div class="actionscontainer">
        <div class="actions">
          <v-icon
              v-if="(role.issu||role.permissions.geofences.write)&&!newVectorSource.getFeatures().length"
              icon="mdi-shape-polygon-plus"
              color="#5d5d5d"
              @click="initNewGeo();modals.newObject=true"
              title="Добавление новой геозоны"
          ></v-icon>
          <template v-if="currentObject">
            <v-icon
                v-if="role.issu || role.permissions.geofences.write"
                icon="mdi-pencil"
                color="#5d5d5d"
                @click="modals.editGeo=true"
                title="Изменение Геозоны"
            ></v-icon>
            <v-icon
                v-if="role.issu || role.permissions.geofences.write"
                icon="mdi-delete-forever"
                color="#5d5d5d"
                @click="modals.deleteObj=true"
                title="Удаление объекта"
            ></v-icon>
          </template>
        </div>
      </div>
      <div class="treeContainer">
        <v-list v-if="lists?.super?.length"
                v-model:selected="currUUID"
                select-strategy="single-leaf"
                variant="flat"
                density="compact"
        >
            <v-list-group
                fluid
                v-for="sup in lists.super"
                :key="sup.uuid"
            >
              <template v-slot:activator="{ props }">
                <v-list-item
                    v-bind="props"
                    prepend-icon="mdi-folder-outline"
                    :title="sup.name"
                    color="#bfbfbf"
                ></v-list-item>
              </template>
              <v-list-item
                  prepend-icon="mdi-vector-polygon-variant"
                  :title="sup.name"
                  :value="sup.uuid"
                  density="compact"
              ></v-list-item>
                <v-list-group
                    fluid
                    v-for="cat in sup.categories"
                    :key="cat.id"
                    :title="cat.name"
                >
                  <template v-slot:activator="{ props }">
                    <v-list-item
                        v-bind="props"
                        :title="cat.catname"
                        color="#bfbfbf"
                        v-if="cat.geofences?.length"
                        prepend-icon="mdi-ungroup"
                        density="compact"
                    ></v-list-item>
                  </template>
                    <v-list-item
                        v-for="geo in cat.geofences"
                        :key="geo.uuid"
                        :title="geo.name"
                        :value="geo.uuid"
                        prepend-icon="mdi-vector-polyline"
                    ></v-list-item>
                </v-list-group>
            </v-list-group>
          <template v-if="lists?.other?.length">
            <v-list-group>
              <template v-slot:activator="{ props }">
                <v-list-item
                    v-bind="props"
                    prepend-icon="mdi-folder-alert"
                    title="Не распределенные Геозоны"
                    color="orange"
                ></v-list-item>
              </template>
              <v-list-item
                  v-for="geo in lists.other"
                  :key="geo.uuid"
                  :title="geo.name"
                  :value="geo.uuid"
                  prepend-icon="mdi-vector-polyline"
              ></v-list-item>
            </v-list-group>
          </template>
        </v-list>
      </div>
    </div>
    <div id="mapContent">
      <v-btn v-if="searchVectorSource.getFeatures().length" class="save-from-search-btn" prepend-icon="mdi-magnify"  title="Поиск"  @click="saveFromSearch()">
        Сохранить выбранное как геозону
      </v-btn>
      <v-btn class="search-btn" prepend-icon="mdi-magnify"  title="Поиск"  @click="modals.addrsearch=true">
        Поиск
      </v-btn>
      <v-btn class="clear-search-btn" color="orange" prepend-icon="mdi-autorenew" v-if="searchVectorSource.getFeatures().length" title="Убрать с карты выбранное из поиска"  @click="searchVectorSource.clear()">
        Очистить выбранное
      </v-btn>
      <v-btn class="save-btn" color="orange" v-if="loadcontroller.maploaded&&newVectorSource.getFeatures().length" prepend-icon="mdi-content-save-check-outline" @click="saveZones()">Сохранить новые геозоны</v-btn>
      <v-btn class="clear-save-btn" color="error" v-if="loadcontroller.maploaded&&newVectorSource.getFeatures().length" prepend-icon="mdi-alert-circle" @click="newVectorSource.clear()">Сбросить без сохранения</v-btn>
      <div v-if="loadcontroller.maploaded" class="layersSwitcher">
        <v-menu>
          <template v-slot:activator="{ props  }">
            <v-btn
                color="gray"
                v-bind="props"
                title="Управление Отображением Геозон"
                icon="mdi-layers-plus"
            >
            </v-btn>
          </template>
          <v-list>
            <template v-if="olMap.getAllLayers()[1].values_.visible">
              <v-list-item
                  v-for="opt in renderedCats"
                  :key="opt.id"
              >
                <v-list-item-title><v-checkbox hide-details :label="opt.name" v-model="opt.visible"  v-on:change="setGeoCatVisible"></v-checkbox></v-list-item-title>
              </v-list-item>
            </template>
            <v-list-item><v-switch color="success" hide-details="true" v-model="olMap.getAllLayers()[1].values_.visible" style="width: auto"  @click="olMap.render()" label="Отображать геозоны"></v-switch></v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div ref="map" style="width: 100%; height: 100%">
        <div id="popup" class="ol-popup">
          <a href="#" id="popup-closer" class="ol-popup-closer"></a>
          <div id="popup-content"></div>
        </div>
      </div>
    </div>
  </div>
  <!--Edit Geofence-->
  <v-dialog
      v-model="modals.editGeo"
      max-width="75vw"
      transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
          color="black"
          title="Редакторование Геозоны"
      >
      </v-toolbar>
      <v-card-text>
          <v-row>
            <v-col>
              <v-input>
                <v-text-field
                    v-model="currentObject.name"
                    label="Название геозоны"
                    variant="underlined"
                ></v-text-field>
              </v-input>
              <v-switch
                  v-model="currentObject.super"
                  label="Супергеозона"
              >
              </v-switch>
              <v-switch
                  :disabled="currentObject.super"
                  v-model="currentObject.admin_dev"
                  label="Административная пренадлежность"
                  v-on:change="currentObject.category_Id=null;currentObject.devision_name=null"
              ></v-switch>
              <v-select
                  label="Административная принадлежность"
                  v-model="currentObject.devision_name"
                  :disabled="!currentObject.admin_dev"
                  :items="admin_devs"
                  item-value="item"
                  item-title="item"
              ></v-select>
              <v-select
                  v-model="currentObject.category_Id"
                  label="Категория геозоны"
                  :items="categories"
                  item-title="NAME"
                  item-value="ID"
                  no-data-text="Нет Данных"
              >
              </v-select>
              <v-select
                  label="Родительская Геозона"
                  no-data-text="Нет Данных"
                  :items="soperGeofs"
                  item-title="name"
                  item-value="uuid"
                  v-model="currentObject.parent_geo"
              >
              </v-select>
              <v-input
              >
                <v-text-field
                    v-model="currentObject.downtime_Radius"
                    type="number"
                    label="Радиус фиксации простоев (м.)"
                ></v-text-field>
              </v-input>
              <v-input>
                <v-text-field
                    v-model="currentObject.description"
                    label="Описание"
                    variant="underlined"
                >
                </v-text-field>
              </v-input>
            </v-col>
            <v-col>
              <v-row>
                <v-col>
                  <h4 style="text-align: center">Цвет заливки</h4>
                  <v-color-picker
                      v-model="currentObject.style.fill.color"
                      :hide-inputs=true
                      canvas-height=60
                      dot-size=10
                      :modes="['rgba']"
                  ></v-color-picker>
                </v-col>
                <v-col>
                  <h4 style="text-align: center">Цвет обводки</h4>
                  <v-color-picker
                      v-model="currentObject.style.stroke.color"
                      :hide-inputs=true
                      canvas-height=60
                      dot-size=10
                      :modes="['rgba']"
                  ></v-color-picker>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-slider
                      v-model="currentObject.style.stroke.width"
                      :color="currentObject.style.stroke.color"
                      label="Толщина линии"
                      max="10"
                      min="1"
                      step="1"
                      style="width: 100%"
                  ></v-slider>
                  <div
                      :style="{backgroundImage:`url('/img/geoBackground.jpg')`,backgroundRepeat:'repeat',display:'flex',flexDirection:'row',justifyContent:'center',width:'100%',height: '100px'}">
                    <div
                        :style="{display:'flex',flexDirection:'column',alignSelf: 'center',background:currentObject.style.fill.color,width:'50%',height:'50%',borderColor:currentObject.style.stroke.color,borderWidth:currentObject.style.stroke.width+'px',borderStyle:'solid'}">
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-row justify="center">
                    <v-col><h4 style="width: 100%;text-align: center">Правила фиксации входа в геозону</h4></v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-switch
                          v-model="editedObj.setRulesMode"
                          :hide-details=true
                          :ripple=false
                          color="success"
                          label="Изменить правила фиксации входа"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col><h5 style="width: 100%;text-align: center">Дни недели</h5></v-col>
                  </v-row>
                  <v-row>
                    <v-checkbox
                        v-model="currentObject.watch_days[0]"
                        :disabled="!editedObj.setRulesMode"
                        label="ПН"
                    ></v-checkbox>
                    <v-checkbox
                        v-model="currentObject.watch_days[1]"
                        :disabled="!editedObj.setRulesMode"
                        label="ВТ"
                    ></v-checkbox>
                    <v-checkbox
                        v-model="currentObject.watch_days[2]"
                        :disabled="!editedObj.setRulesMode"
                        label="СР"
                    ></v-checkbox>
                    <v-checkbox
                        v-model="currentObject.watch_days[3]"
                        :disabled="!editedObj.setRulesMode"
                        label="ЧТ"
                    ></v-checkbox>
                    <v-checkbox
                        v-model="currentObject.watch_days[4]"
                        :disabled="!editedObj.setRulesMode"
                        label="ПТ"
                    ></v-checkbox>
                    <v-checkbox
                        v-model="currentObject.watch_days[5]"
                        :disabled="!editedObj.setRulesMode"
                        label="СБ"
                    ></v-checkbox>
                    <v-checkbox
                        v-model="currentObject.watch_days[6]"
                        :disabled="!editedObj.setRulesMode"
                        label="ВС"
                    ></v-checkbox>
                  </v-row>
                  <v-input>
                    <v-text-field
                        v-model="currentObject.min_period_sec"
                        :disabled="!editedObj.setRulesMode"
                        label="Минимальный период для фиксации входа в геозону (сек)"
                        type="number"
                        variant="underlined"
                    ></v-text-field>
                  </v-input>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="space-around">
          <v-btn
              @click="modals.editGeo = false"
          >
            Закрыть
          </v-btn>
          <v-btn
              @click="modifyGeo(currentObject.uuid);modals.editGeo=false"
          >Изменить геометрию</v-btn>
          <v-btn
              @click="saveModifyedZones();modals.editGeo=false"
          >Обновить</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!--Create Geofence-->
  <v-dialog
      v-model="modals.newObject"
      transition="dialog-bottom-transition"
      max-width="70vw"
  >
    <v-card>
      <v-toolbar
          color="black"
          title="Добавление новой Геозоны"
      >
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col>
            <v-input>
              <v-text-field
                  v-model="newGeo.payload.name"
                  label="Название геозоны"
                  variant="underlined"
              ></v-text-field>
            </v-input>
            <v-select
                v-model="newGeo.payload.type"
                :items="[
                  {name:'Многоугольник',value:'polygon'},
                  {name:'Окружность',value:'circle'}
              ]"
                item-title="name"
                item-value="value"
                label="Тип геозоны"
                variant="underlined"
            >
            </v-select>
            <v-switch
                v-model="newGeo.payload.super"
                label="Супергеозона"
                v-on:change="newGeo.payload.category_Id=null;newGeo.payload.parent_geo=null;newGeo.payload.devision_name=null;newGeo.payload.admin_dev=false"
            >
            </v-switch>
            <v-switch
                :disabled="newGeo.payload.super"
                v-model="newGeo.payload.admin_dev"
                label="Административная пренадлежность"
                v-on:change="newGeo.payload.category_Id=null;newGeo.payload.devision_name=null"
            >
            </v-switch>
            <v-select
                label="Административная принадлежность"
                v-model="newGeo.payload.devision_name"
                :disabled="!newGeo.payload.admin_dev"
                :items="admin_devs"
                item-value="item"
                item-title="item"
            >
            </v-select>
            <v-select
                :disabled="newGeo.payload.super || newGeo.payload.admin_dev"
                v-model="newGeo.payload.category_Id"
                label="Категория геозоны"
                :items="categories"
                item-title="NAME"
                item-value="ID"
                no-data-text="Нет Данных"
            >
            </v-select>
            <v-select
                :disabled="newGeo.payload.super"
                label="Родительская Геозона"
                no-data-text="Нет Данных"
                :items="soperGeofs"
                item-title="name"
                item-value="uuid"
                v-model="newGeo.payload.parent_geo"
            >
            </v-select>
            <v-input
            >
              <v-text-field
                  v-model="newGeo.payload.downtime_Radius"
                  type="number"
                  label="Радиус фиксации простоев (м.)"
              ></v-text-field>
            </v-input>
            <v-input>
              <v-text-field
                  v-model="newGeo.payload.description"
                  label="Описание"
                  variant="underlined"
              >
              </v-text-field>
            </v-input>
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <h4 style="text-align: center">Цвет заливки</h4>
                <v-color-picker
                    v-model="newGeo.payload.style.fill"
                    :hide-inputs=true
                    :modes="['rgba']"
                    canvas-height=60
                    dot-size=10
                ></v-color-picker>
              </v-col>
              <v-col>
                <h4 style="text-align: center">Цвет обводки</h4>
                <v-color-picker
                    v-model="newGeo.payload.style.stroke"
                    :hide-inputs=true
                    :modes="['rgba']"
                    canvas-height=60
                    dot-size=10
                ></v-color-picker>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-slider
                    v-model="newGeo.payload.style.width"
                    :color="newGeo.payload.style.stroke"
                    label="Толщина линии"
                    max="10"
                    min="1"
                    step="1"
                    style="width: 100%"
                ></v-slider>
                <div
                    :style="{backgroundImage:`url('/img/geoBackground.jpg')`,backgroundRepeat:'repeat',display:'flex',flexDirection:'row',justifyContent:'center',width:'100%',height: '100px'}">
                  <div
                      :style="{display:'flex',flexDirection:'column',alignSelf: 'center',background:newGeo.payload.style.fill,width:'50%',height:'50%',borderColor:newGeo.payload.style.stroke,borderWidth:newGeo.payload.style.width+'px',borderStyle:'solid'}">
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row justify="center">
                  <v-col><h4 style="width: 100%;text-align: center">Правила фиксации входа в геозону</h4></v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-switch
                        v-model="newGeo.setRulesMode"
                        :hide-details=true
                        :ripple=false
                        color="success"
                        label="Изменить правила фиксации входа"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col><h5 style="width: 100%;text-align: center">Дни недели</h5></v-col>
                </v-row>
                <v-row>
                  <v-checkbox
                      v-model="newGeo.payload.watch_days[0]"
                      :disabled="!newGeo.setRulesMode"
                      label="ПН"
                  ></v-checkbox>
                  <v-checkbox
                      v-model="newGeo.payload.watch_days[1]"
                      :disabled="!newGeo.setRulesMode"
                      label="ВТ"
                  ></v-checkbox>
                  <v-checkbox
                      v-model="newGeo.payload.watch_days[2]"
                      :disabled="!newGeo.setRulesMode"
                      label="СР"
                  ></v-checkbox>
                  <v-checkbox
                      v-model="newGeo.payload.watch_days[3]"
                      :disabled="!newGeo.setRulesMode"
                      label="ЧТ"
                  ></v-checkbox>
                  <v-checkbox
                      v-model="newGeo.payload.watch_days[4]"
                      :disabled="!newGeo.setRulesMode"
                      label="ПТ"
                  ></v-checkbox>
                  <v-checkbox
                      v-model="newGeo.payload.watch_days[5]"
                      :disabled="!newGeo.setRulesMode"
                      label="СБ"
                  ></v-checkbox>
                  <v-checkbox
                      v-model="newGeo.payload.watch_days[6]"
                      :disabled="!newGeo.setRulesMode"
                      label="ВС"
                  ></v-checkbox>
                </v-row>
                <v-input>
                  <v-text-field
                      v-model="newGeo.payload.min_period_sec"
                      :disabled="!newGeo.setRulesMode"
                      label="Минимальный период для фиксации входа в геозону (сек)"
                      type="number"
                      variant="underlined"
                  ></v-text-field>
                </v-input>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="space-around">
          <v-btn
              @click="modals.newObject = false"
          >
            Закрыть
          </v-btn>
          <v-btn
              @click="onChange();modals.newObject=false"
          >Перейти к рисованию
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!--Delete-->
  <v-dialog
      v-model="modals.deleteObj"
      max-width="300px"
      transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title class="text-h6 ">
        Требуется подтверждение
      </v-card-title>
      <v-card-text>
        Вы дествительно ходите удалить геозону <span style="font-weight: bold">"{{currentObject.name}}"</span>?
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row justify="space-around">
          <v-btn
              color="green"
              @click="modals.deleteObj = false"
          >
            Закрыть
          </v-btn>
          <v-btn
              color="red"
              @click="deleteNode();modals.deleteObj=false"
          >
            Удалить
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!--Search-->
  <v-dialog max-width="600px" v-model="modals.addrsearch">
    <v-card>
      <v-toolbar
          color="black"
          title="Поиск местоположения по адресу"
      ></v-toolbar>
      <v-card-text>
        <v-input>
            <v-text-field
              variant="underlined"
              label="Адрес"
              v-model="addressSearch.search"
          ></v-text-field>
            <v-btn
                variant="plain"
                height="100%"
                @click="querySelections(addressSearch.search)"
            >Найти</v-btn>
        </v-input>
        <v-input>
          <v-select
              :loading="addressSearch.loading"
              v-model="addressSearch.select"
              :items="addressSearch.items"
              item-title="name"
              item-value="geo"
              item-children="display_name"
          ></v-select>
        </v-input>
        <v-input aria-label="Прямой переход по координатам">
          <v-text-field
              variant="underlined"
              v-model="addressSearch.coords[0]"
              label="Долгота"
              type="number"
          ></v-text-field>
          <v-text-field
              variant="underlined"
              v-model="addressSearch.coords[1]"
              label="Широта"
              type="number"
          ></v-text-field>
          <v-btn height="100%" variant="plain" @click="goToCoordsFromSearch();modals.addrsearch=false">Перейти к точке
          </v-btn>
        </v-input>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="modals.addrsearch=false">
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import Map from "ol/Map";
import {getDistance} from 'ol/sphere';
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import VectorLayer from "ol/layer/Vector";
import View from "ol/View";
import {fromLonLat, toLonLat, transform} from "ol/proj";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import {Collection, Feature} from "ol";
import {Circle, LinearRing, LineString, MultiLineString, MultiPoint, MultiPolygon, Point, Polygon} from "ol/geom";
import {Draw, Modify, Select, Snap} from "ol/interaction"
import {MousePosition} from "ol/control";
import {Fill, Icon, Stroke, Style} from "ol/style";
import Overlay from "ol/Overlay";

export default {
  name: "GefencesPage",
  data() {
    return {
      admin_devs:[
        'Провинция',
        'Город',
        'Район',
        'Регион',
        'Штат',
        'Территория',
        'Область',
        'Община',
        'Округ',
        'Автономный округ',
        'Край',
        'Комунна',
        'Административный округ',
        'Департамент',
        'Графство',
        'Муниципалитет',
        'Столица',
        'Префектура',
      ],
      tileCashe:new Map(),
      currUUID:undefined,
      overlay:undefined,
      view:undefined,
      addressSearch: {
        loading: false,
        items: [],
        search: null,
        select: null,
        states:[],
        ftStyles:{
          point: new Style({
            image: new Icon({
              crossOrigin: 'anonymous',
              src: '/img/pointer.png',
              scale: 0.5,
              color: "red"
            }),
          }),
          polygon: new Style({
                fill: new Fill({color: [0, 0, 204, 0.1]}),
                stroke: new Stroke({
                  color: 'black',
                  width: 1,
                })
              }
          ),
          line: new Style({
            stroke: new Stroke({
              color: 'black',
              width: 6,
            }),
          })
        },
        coords: [37.6188, 55.7457]
      },
      modals: {
        editGeo: false,
        editDir: false,
        newObject: false,
        newDir: false,
        deleteObj: false,
        addrsearch: false,
        realimage: false,
        showRealImage: false
      },
      loadcontroller: {
        maploaded: false,
        geofancesLoad: false,
        geogroupsLoad: false
      },
      currentObject: undefined,
      modyfiedGeo: {},
      addedGeoFences: {},
      newVectorSource: new VectorSource({format: new GeoJSON()}),
      featuresSource: new VectorSource({format: new GeoJSON()}),
      searchVectorSource: new VectorSource({
        format: new GeoJSON()
      }),
      editVectorSource: new VectorSource({
        format: new GeoJSON()
      }),
      myMapControls: new Collection([
        new MousePosition({
          coordinateFormat: function (coordinate) {
            const c = toLonLat(coordinate);
            return 'Lon: ' + c[0].toFixed(5) + ' Lat: ' + c[1].toFixed(5)
          },
          className: 'mousePos'
        })
      ]),
      editedObj: {
        type:'',
        id: -1,
        name: '',
        uuid: '',
        style: {
          fill: {color: ''},
          stroke: {
            color: '',
            width: 0
          },
        },
        description: '',
        parent: {
          id: -1,
          name: 'Геозоны'
        },
        image: {
          real: undefined,
          compressed: undefined
        },
        watch_days: undefined,
        min_period_sec: undefined,
        setRulesMode: false
      },
      editmode: false,
      ImageToShow: undefined,
      newGeo: undefined,
      olMap: '',
      rules: [
        value => {
          return !value || !value.length || value[0].size < 5242880 || 'Максимальный размер изображения не должен превышать 5 Мб'
        }],
    }
  },
  created() {
    this.initNewGeo()
    this.view=new View({
      zoom: 9,
      center: fromLonLat([37.55213985518653, 55.7449717807913]),
      constrainResolution: true,
      projection: 'EPSG:3857'
    })
  },
  computed: {
    globalSettings: function () {
      return this.$store.getters['app/globalSettings']
    },
    categories(){
      return  this.$store.getters['geofences/geocats']
    },
    geofences: function () {
      return this.$store.getters['geofences/geofences']
    },
    soperGeofs:function (){
      let res=[]
      this.geofences.forEach(geo=>{
        if(geo.super){
          res.push({uuid:geo.uuid,name:geo.name})
        }
      })
      return res.sort((a,b)=>{
        if (a.name===b.name){
          return 0
        }
        if (a.name>b.name){
          return 1
        }
        if(a.name<b.name){
          return -1
        }
      })
    },
    lists:function (){
      let result={
        super:[],
        other:[]
      }
      this.soperGeofs.forEach(geof=>{
        const cats=[
          {
            catname:"Без Категории",
            catid:0,
            geofences:[]
          },
          {
            catname:"Адм. Деление",
            catid:-1,
            geofences:[]
          },
        ]
        this.categories.forEach(cat=>{
          cats.push({
            catname:cat.NAME,
            catid:cat.ID,
            geofences:[]
          })
        })
        result.super.push({
          name:geof.name,
          uuid:geof.uuid,
          categories:cats
        })
      })
      this.geofences.forEach(geof=>{
        if(!geof.Super){//если простая
          if(geof.parent_geo){//и имеет родителя
            result.super.forEach(sup=>{
              if(sup.uuid===geof.parent_geo){//нашли родителя
                if(geof.category_Id){//если с категорией
                  sup.categories.forEach(cat=>{
                    if(cat.catid===geof.category_Id){//нашли категорию
                      cat.geofences.push({
                        name:geof.name,
                        uuid:geof.uuid,
                      })//засунули ва нее
                    }
                  })
                }else {//если без категории
                  if (geof.admin_dev){//смотрим административное деление
                    sup.categories[1].geofences.push({
                      name:geof.name,
                      uuid:geof.uuid,
                    })
                  }else {
                    sup.categories[0].geofences.push({
                      name:geof.name,
                      uuid:geof.uuid,
                    })
                  }

                }
              }
            })
          } else{//если не подчинена, суем в нераспределенные
            if(!geof.super){
              result.other.push({
                name:geof.name,
                uuid:geof.uuid
              })
            }
          }
        }
      })
      result.super.forEach(sup=>{
        sup.categories.forEach(cat=>{
          cat.geofences=cat.geofences.sort((a,b)=>{
            if(a.name>b.name){
              return 1
            }
            if(a.name===b.name){
              return 0
            }
            if(a.name<b.name){
              return -1
            }
          })
        })
      })
      result.other=result.other.sort((a,b)=>{
        if(a.name>b.name){
          return 1
        }
        if(a.name===b.name){
          return 0
        }
        if(a.name<b.name){
          return -1
        }
      })
      return result
    },
    geogroups: function () {
      return this.$store.getters['geofences/geogroups']
    },
    role:function (){
      return this.$store.getters['user/role']
    },
    geofenceVectorSource: function (){
      return this.$store.getters['geofences/geofenceVectorSource']
    },
    user:function (){
      return this.$store.getters['user/user']
    },
    loading:{
      set: function (newval) {
        this.$store.dispatch('app/loading',newval)
      },
      get: function (){
        return this.$store.getters['app/loader']
      }
    },
    drawner(){
      return this.$store.getters['app/navDrawner']
    },
    renderedCats(){
      return this.$store.getters['options/renderedCats']
    },
  },
  methods: {
    updateMap(){
      this.olMap.updateSize()
    },
    setGeoCatVisible(){
      const pl=[]
      this.renderedCats.forEach(cat=>{
        pl.push(cat)
      })
      this.$store.dispatch('options',{name:'geoCategories',value:pl})
    },
    initNewGeo(){
      this.newGeo={
        payload:{
          groupid:0,
          name: undefined,
          type: 'polygon',
          description: undefined,
          style: {
            fill: "#2634E014",
            stroke: "#0A0A0A",
            width: 1
          },
          watch_days: [true, true, true, true, true, true, true,],
          min_period_sec: 36000,
          super:false,
          parent_geo:null,
          category_Id:null,
          admin_dev:false,
          devision_name:null,
          downtime_Radius:this.globalSettings.stdDowntimeRadiusMeters?this.globalSettings.stdDowntimeRadiusMeters:0,
        },
        setRulesMode:false,
        templates: {
          snap: new Snap({
            source: this.newVectorSource,
          }),
          circle: {
            type: 'Circle',
          },
          poly: {
            type: 'Polygon',
          }
        },
        newGeoFence: false,
        mode: false,
        interactions: [],
      }
    },
    goToCoordsFromSearch() {
      let ft
      this.searchVectorSource.clear()
      ft = new Feature({
        geometry: new Point(fromLonLat(this.addressSearch.coords))
      })
      ft.setStyle(this.addressSearch.ftStyles.point)
      this.searchVectorSource.addFeature(ft)
      this.view.setCenter(fromLonLat(this.addressSearch.coords))
      this.view.setZoom(10)
    },
    coordsConverter(coords, geometry, from, to) {
      let result = []
      switch (geometry) {
        case 'Point':
          return transform(coords, from, to)
        case 'LineString':
          coords.forEach(coord => {
            result.push(this.coordsConverter(coord, 'Point', from, to))
          })
          return result;
        case 'LinearRing':
          coords.forEach(coord => {
            const transformed = transform(coord, from, to)
            result.push(transformed)
          })
          return result
        case 'Polygon':
          coords.forEach(linearring=>{
            let lring=this.coordsConverter(linearring,'LinearRing',from,to)
            result.push(lring)
          })
          return result
        case 'MultiPoint':
          coords.for(coord=>{
            result.push(this.coordsConverter(coord,'Point',from,to))
          })
          return result;
        case 'MultiLineString':
          coords.forEach(ls=>{
            result.push(this.coordsConverter(ls,'LineString',from,to))
          })
              return result;
        case 'MultiPolygon':
          coords.forEach(pl=>{
            result.push(this.coordsConverter(pl,'Polygon',from,to))
          })
          return result;
        case 'Circle':
          return transform(coords,from,to)
        default: break;
      }
    },
    querySelections (v) {
      this.addressSearch.loading=true
      this.addressSearch.items=[]
      this.addressSearch.select=undefined
      this.$store.dispatch('app/getAddress',v)
          .then(resp=>{
            resp.data.forEach(res=>{
              this.addressSearch.items.push({
                name:res.display_name,
                geo:{
                  name:res.display_name,
                  geo:res.geojson
                }
              })
            })
            this.addressSearch.loading=false
          }).catch(err=>{
            console.log(err)
        this.addressSearch.loading=false
      })
    },
    removeInteractions() {
      console.log(this.newGeo.interactions)
      this.newGeo.interactions.forEach(interaction => {
        this.olMap.removeInteraction(interaction)
      })
      this.newGeo.interactions.splice(0, this.newGeo.interactions.length)
    },
    modifyGeo(uuid) {
      this.removeInteractions()
      this.geofences.forEach(geofence => {
        if (geofence.uuid === uuid) {
          if (geofence.type === 'polygon') {
            let coords = []
            geofence.coords.coords.forEach(function (poly) {
              let polycoord = []
              poly.forEach(lineRing => {
                let coords = []
                lineRing.forEach(coord => {
                  coords.push(fromLonLat([coord[0], coord[1]]))
                })
                polycoord.push(coords)
              })
              coords.push(polycoord)
            })
            const ft = new Feature({
              geometry: new MultiPolygon(coords),
              name: geofence.name,
              uuid: geofence.uuid
            })
            this.editVectorSource.addFeature(ft)
          }
          if (geofence.type === 'circle') {
            const center = fromLonLat(geofence.coords.center)
            let c = new Circle(center)
            c.setCenter(center)
            c.setRadius(geofence.coords.radius)
            const ft = new Feature({
              geometry: c,
              name: geofence.name,
              uuid: geofence.uuid,
            })
            this.editVectorSource.addFeature(ft)
          }
        }
      })
      //отключим слой с геозонами
      this.olMap.getAllLayers().forEach(layer => {
        const name=layer.get('name')
        console.log('name',name,' vis:',layer.get('visible'))
        if (name === 'Geofences') {
          layer.set('visible', false)
          console.log("set vis false on name: ",name)
        }
      })
      this.editmode = true
      let ft = this.editVectorSource.getFeatures()[0]
      ft.set('name', this.editedObj.name)
      ft.set('groupid', this.editedObj.parent.id)
      let collection = new Collection()
      collection.push(ft)
      let select = new Select({
        features: collection
      })
      select.on('select', () => {
        this.removeInteractions()
        this.saveModifyedZones()
      })
      let modify = new Modify({
        features: select.getFeatures(),
      })
      this.newGeo.interactions.push(select);
      this.newGeo.interactions.push(modify)
      this.newGeo.interactions.push(new Snap({
        source: this.editVectorSource
      }));
      for (let i = 0; i < this.newGeo.interactions.length; i++) {
        this.olMap.addInteraction(this.newGeo.interactions[i]);
      }
    },
    onChange() {
      this.removeInteractions()
      this.newGeo.mode=true
      const props={
        name:this.newGeo.payload.name,
        groupid:this.newGeo.payload.groupid,
        description: this.newGeo.payload.description,
        watch_days: this.newGeo.payload.watch_days,
        min_period_sec: Number(this.newGeo.payload.min_period_sec),
        super:this.newGeo.payload.super,
        category_Id:this.newGeo.payload.category_Id,
        parent_geo:this.newGeo.payload.parent_geo,
        downtime_Radius:this.newGeo.payload.downtime_Radius,
        admin_devs:this.newGeo.payload.admin_dev,
        devision_name:this.newGeo.payload.devision_name,
      }
      console.log('props',props)
      switch (this.newGeo.payload.type) {
        case 'polygon': {
          this.newGeo.templates.poly.geometryName =JSON.stringify(props)
          this.newGeo.templates.poly.source = this.newVectorSource
          const draw = new Draw({
            type:'Polygon',
            geometryName:this.newGeo.templates.poly.geometryName,
            source:this.newGeo.templates.poly.source,
            style:new Style({
              fill:new Fill({
                color:this.newGeo.payload.style.fill,
              }),
              stroke:new Stroke({
                color:this.newGeo.payload.style.stroke,
                width:this.newGeo.payload.style.width
              })
            })
          })
          draw.on('drawend', e => {
            const style = new Style({
              fill:new Fill({
                color:this.newGeo.payload.style.fill,
              }),
              text:'',
              stroke:new Stroke({
                color:this.newGeo.payload.style.stroke,
                width:this.newGeo.payload.style.width
              })
            })
            e.feature.setStyle(style);
            this.removeInteractions()
          })
          this.newGeo.interactions.push(draw)
          this.newGeo.interactions.push(new Snap({source: this.newVectorSource}))
          for (let i = 0; i < this.newGeo.interactions.length; i++) {
            this.olMap.addInteraction(this.newGeo.interactions[i]);
          }
          break;
        }
        case 'circle': {
          this.newGeo.templates.circle.geometryName = JSON.stringify(props)
          this.newGeo.templates.circle.source = this.newVectorSource
          const draw =  new Draw({
            type:'Circle',
            geometryName:this.newGeo.templates.circle.geometryName,
            source:this.newGeo.templates.circle.source,
            style:new Style({
              fill:new Fill({
                color:this.newGeo.payload.style.fill,
              }),
              text:'',
              stroke:new Stroke({
                color:this.newGeo.payload.style.stroke,
                width:this.newGeo.payload.style.width
              })
            })
          })
          draw.on('drawend', e => {
            const style = new Style({
              fill:new Fill({
                color:this.newGeo.payload.style.fill,
              }),
              text:'',
              stroke:new Stroke({
                color:this.newGeo.payload.style.stroke,
                width:this.newGeo.payload.style.width
              })
            })
            e.feature.setStyle(style);
            this.removeInteractions()
          })
          this.newGeo.interactions.push(draw)
          this.newGeo.interactions.push(new Snap({
            source: this.newVectorSource,
          }));
          this.newGeo.interactions.forEach((interaction) => {
            this.olMap.addInteraction(interaction);
          })
          break;
        }
        default: {
          break
        }
      }
    },
    deleteNode:function (){
      this.loading=true
      this.$store.dispatch('geofences/deleteGeoFence',{
          uuid:this.currentObject.uuid
        }).then(()=>{
          this.loading=false
        })
            .catch(e=>{
              this.loading=false
              console.log(e)
            })
    },
    setCurrentObjectByUUID: function (uuid) {
      if(!uuid){
        this.currentObject=undefined
        return
      }
      this.geofenceVectorSource.getFeatures().forEach(ft=>{
        if(ft.values_.uuid===uuid){
          this.view.fit(ft.getGeometry())
          return
        }
      })
      this.olMap.updateSize()
      this.geofences.forEach(geo=>{
        if(geo.uuid===uuid){
          this.currentObject=geo
          this.currentObject.admin_dev=Boolean(this.currentObject.admin_dev)
        }
      })
    },
    visibleFeature(node){
      if(node.visible){
        this.geofenceVectorSource.getFeatures().forEach(ft=>{
          if(ft.values_.uuid===node.uuid){
            this.featuresSource.addFeature(ft)
            this.geofenceVectorSource.removeFeature(ft)
            node.visible=false
          }
        })
      }else{
        this.featuresSource.getFeatures().forEach(ft=>{
          if(ft.values_.uuid===node.uuid){
            this.geofenceVectorSource.addFeature(ft)
            this.featuresSource.removeFeature(ft)
            node.visible=true
          }
        })
      }
    },
    saveZones() {
      let fts = []
      this.newVectorSource.forEachFeature((ft) => {
        const props=JSON.parse(ft.geometryName_)
        const type = ft.getGeometry().getType()
        const styles=ft.getStyle()
        const style={
          fill:{
            color:styles.getFill().getColor()
          },
          stroke:{
            color:styles.getStroke().getColor(),
            width:styles.getStroke().getWidth()
          }
        }
        let payload={
            name:props.name,
            groupid:props.groupid,
            description: props.description,
            style: style,
            watch_days: props.watch_days,
            min_period_sec: Number(props.min_period_sec),
            admin_dev:props.admin_dev,
            devision_name:props.devision_name,
        }
        if(props.category_Id){
          payload.category_Id=props.category_Id
        }
        if(props.parent_geo){
          payload.parent_geo=props.parent_geo
        }
        if(props.downtime_Radius){
          payload.downtime_Radius=Number(props.downtime_Radius)
        }
        if (type === 'Polygon') {
          const ftcoords = ft.getGeometry().getCoordinates()
          const wgs84coords = this.coordsConverter(ftcoords,'Polygon', "EPSG:3857","EPSG:4326")
          payload.type='polygon'
          payload.coords={
            coords: [wgs84coords]
          }
          fts.push(payload)
        }
        if (type === 'Circle') {
          const center = toLonLat(ft.getGeometry().getCenter())
          let radius = ft.getGeometry().getRadius()
          if(radius<1){radius=1}
          const fcoord = ft.getGeometry().getFirstCoordinate()
          const lcoord = ft.getGeometry().getLastCoordinate()
          const radiusM = getDistance(toLonLat(fcoord), toLonLat(lcoord))
          payload.type='circle'
          payload.coords={
            center: center,
            radius: Math.floor(radius),
            rmeters: Math.floor(radiusM)
          }
          fts.push(payload)
        }
      })
      fts.forEach(ft=>{
        this.loading=true
        this.$store.dispatch('geofences/addGeofences',ft)
            .then(()=> {
              this.loading=false
            })
            .catch((err)=>{
              console.log(err)
              this.loading=true
            })
      })
      this.newGeo.mode=false
      this.newVectorSource.clear()
    },
    saveFromSearch() {
      let fts = []
      this.searchVectorSource.forEachFeature((ft) => {
        const type = ft.getGeometry().getType()
        const name = ft.get('name')
        const style = {
          fill: {
            color: "rgba(204,203,203,0.1)"
          },
          stroke: {
            color: 'black',
            width: 1
          }
        }
        if (type === 'LinearRing') {
          const ftcoords = ft.getGeometry().getCoordinates()
          const wgs84coords = this.coordsConverter(ftcoords, 'LinearRing', "EPSG:3857", "EPSG:4326")
          fts.push({
            type: 'polygon',
            name: name,
            groupid: this.user.geogroup,
            coords: {
              coords: [[wgs84coords]]
            },
            style:style,
            description: '',
            watch_days: [true,true,true,true,true,true,true,],
            min_period_sec: 36000
          })
        }
        if (type === 'Polygon') {
          const ftcoords = ft.getGeometry().getCoordinates()
          const wgs84coords = this.coordsConverter(ftcoords,'Polygon', "EPSG:3857","EPSG:4326")
          fts.push({
            type: 'polygon',
            name: name,
            groupid: this.user.geogroup,
            coords: {
              coords: [wgs84coords]
            },
            style:style,
            description: '',
            watch_days: [true,true,true,true,true,true,true,],
            min_period_sec: 36000
          })
        }
        if (type === 'MultiPolygon') {
          const ftcoords = ft.getGeometry().getCoordinates()

          const wgs84coords = this.coordsConverter(ftcoords,'MultiPolygon', "EPSG:3857","EPSG:4326")

          fts.push({
            type: 'polygon',
            name: name,
            groupid: this.user.geogroup,
            coords: {
              coords: wgs84coords
            },
            style: style,
            description: '',
            watch_days: [true,true,true,true,true,true,true,],
            min_period_sec: 36000
          })
        }
      })
      fts.forEach(ft=>{
        this.loading=true
        this.$store.dispatch('geofences/addGeofences',ft)
            .catch((err)=>{
              console.log(err)
              this.loading=false
            })
            .finally(()=>{
              this.loading=false
            })
      })
      this.searchVectorSource.clear()
    },
    saveModifyedZones() {
      this.loading=true
      let payload = {
        uuid: this.currentObject.uuid,
        name: this.currentObject.name,
        description: this.currentObject.description,
        style: this.currentObject.style,
        watch_days: this.currentObject.watch_days,
        min_period_sec: Number(this.currentObject.min_period_sec),
        super:this.currentObject.super,
        admin_dev:this.currentObject.admin_dev,
        devision_name:this.currentObject.devision_name
      }
      if(this.editVectorSource.getFeatures().length){
        const modyfiedFt = this.editVectorSource.getFeatures()[0]
        const type = modyfiedFt.getGeometry().getType()
        if (type === 'MultiPolygon') {
          const ftcoords = modyfiedFt.getGeometry().getCoordinates()
          const wgs84coords = this.coordsConverter(ftcoords, 'MultiPolygon', "EPSG:3857", "EPSG:4326")
          payload.coords = {
            coords: wgs84coords
          }
          payload.type='polygon'
        }
        if (type === 'Circle') {
          const center = toLonLat(modyfiedFt.getGeometry().getCenter())
          let radius = modyfiedFt.getGeometry().getRadius()
          const fcoord = modyfiedFt.getGeometry().getFirstCoordinate()
          const lcoord = modyfiedFt.getGeometry().getLastCoordinate()
          const radiusM = getDistance(toLonLat(fcoord), toLonLat(lcoord))
          if (radius < 1) {
            radius = 1
          }
          payload.coords = {
            center: center,
            radius: Math.floor(radius),
            rmeters: Math.floor(radiusM)
          }
          payload.type='circle'
        }
      }
      if(this.currentObject.category_Id){
          payload.category_Id=this.currentObject.category_Id
        }
      if(this.currentObject.parent_geo){
          payload.parent_geo=this.currentObject.parent_geo
        }
      if(this.currentObject.downtime_Radius){
          payload.downtime_Radius=Number(this.currentObject.downtime_Radius)
        }
      this.$store.dispatch('geofences/updataGeofences', payload)
          .catch((err) => {
            console.log(err)
            this.editVectorSource.clear()
            this.editmode=false
            this.olMap.getAllLayers().forEach(layer=>{
              if(layer.get('name')==='Geofences'){
                layer.set('visible',true)
              }
            })
            this.loading=false
          })
          .then(() => {
            this.editVectorSource.clear()
            this.editmode=false
            this.olMap.getAllLayers().forEach(layer=>{
              if(layer.get('name')==='Geofences'){
                layer.set('visible',true)
              }
            })
            this.loading=false
          })
    }
  },
  mounted() {
    const closer = document.getElementById('popup-closer');
    let content = document.getElementById('popup-content');
    content.onclick = (e) => {
      const elem = e.target
      const classname = elem.className
      if (classname.includes('uuid', 0)) {
        this.showRealImage(classname)
      }
    }
    this.overlay=new Overlay({
      element:document.getElementById('popup'),
      autoPan: {
        animation: {
          duration: 500
        },
      },
    });
    closer.onclick = () => {
      this.overlay.setPosition(undefined);
      closer.blur();
      return false;
    };
    this.olMap = new Map({
      target: this.$refs['map'],
      layers: [
        new TileLayer({
          source: new OSM({
            tileLoadFunction:(imageTile, src)=> {
              var imgElement = imageTile.getImage();
              const surce=this.tileCashe.get(src);
              if (surce) {
                imgElement.src = surce
              } else {
                imgElement.onload = ()=> {
                  let canvas = document.createElement("canvas");
                  canvas.width =imgElement.width;
                  canvas.height =imgElement.height;
                  let ctx = canvas.getContext("2d");
                  ctx.drawImage(imgElement, 0, 0);
                  const dataURL = canvas.toDataURL("image/png");
                  this.tileCashe.set(src,dataURL)
                }
                imgElement.src = src;
              }
            }
          }),
          name: 'OSM'
        }),
        new VectorLayer({
          source: this.geofenceVectorSource,
          name: 'Geofences'
        }),
        new VectorLayer({
          source: this.searchVectorSource,
          name: 'Search'
        }),
        new VectorLayer({
          source: this.newVectorSource,
          name: 'newGeo'
        }),
        new VectorLayer({
          source: this.featuresSource,
          visible: false,
          name: 'fts'
        }),
        new VectorLayer({
          source: this.editVectorSource,
          visible: true,
          style: {
                'fill-color': 'rgba(237,245,245,0.5)',
                'stroke-color': '#335cff',
                'stroke-width': 2,
                'circle-radius': 7,
                'circle-fill-color': '#3437fa',
          },
          name: 'for edit features'
        }),
      ],
      overlays:[this.overlay],
      view: this.view,
      controls: this.myMapControls
    })
    this.olMap.on('singleclick', evt=> {
      let fts = []
      if (this.newGeo.mode || this.editmode) {
        return
      }
      const coordinate = evt.coordinate;
      this.olMap.forEachFeatureAtPixel(evt.pixel, function (feature) {
        fts.push(feature);
      });
      if (fts.length > 0) {
        let names = ''
        let descrs = ''
        let cont
        fts.forEach(ft => {
          const geomName = ft.getGeometry().getType()
          const img = ft.get('img')
          if (geomName !== 'Point') {
            names += `"${ft.get('name')}", `

            let descr
            let image

            descr = ft.get('description')??''
            if (img.length < 3) {
              image = ''
            } else {
              image = `<img class="uuid${ft.get('uuid')}" style="width: auto" src=${img}>`
            }
            if (descr){
              descrs += `<p><span style="font-weight: bold">${ft.get('name')}</span>:${descr}</p>`
            }
            if(image){
              descrs += `<p>Изображение:<br>${image}</p>`
            }
            if (descrs){
              cont = `<h4>Геозоны в данной локации:</h4><p>${names.slice(0, -2)}</p><hr><span style="font-size: 12px">Долгота:${(toLonLat(evt.coordinate)[0]).toFixed(6)}</span><br><span style="font-size: 12px">Широта: ${(toLonLat(evt.coordinate)[1]).toFixed(6)}</span><hr><h4>Подробнее о геозонах</h4>${descrs}`;
            }else{
              cont = `<h4>Геозоны в данной локации:</h4><p>${names.slice(0, -2)}</p><hr><span style="font-size: 12px">Долгота:${(toLonLat(evt.coordinate)[0]).toFixed(6)}</span><br><span style="font-size: 12px">Широта: ${(toLonLat(evt.coordinate)[1]).toFixed(6)}</span>`;
            }
            //fts.length=0
          }
        })
        content.innerHTML=cont
        this.overlay.setPosition(coordinate);
      }
    });
    this.loadcontroller.maploaded = true
  },
  watch: {
    'addressSearch.select': function (newval) {
      if (!newval) {
        return
      }
      this.modals.addrsearch = false
      const coords = this.coordsConverter(newval.geo.coordinates, newval.geo.type, "EPSG:4326", "EPSG:3857")
      let ft
      switch (newval.geo.type) {
        case 'Point':
          this.searchVectorSource.clear()
          ft = new Feature({
            geometry: new Point(coords),
            name: newval.name
          })
          ft.setStyle(this.addressSearch.ftStyles.point)
          this.searchVectorSource.addFeature(ft)
          this.view.setCenter(coords)
            this.view.setZoom(10)
          break;
        case 'LineString':
          this.searchVectorSource.clear()
          ft = new Feature({
            geometry: new LineString(coords),
            name:newval.name
          })
          ft.setStyle(this.addressSearch.ftStyles.line)
          this.searchVectorSource.addFeature(ft)
          this.view.fit(ft.getGeometry())
          break;
        case 'LinearRing':
          this.searchVectorSource.clear()
          ft = new Feature({
            geometry: new LinearRing(
                coords
            ),
            name:newval.name
          })
          this.searchVectorSource.addFeature(ft)
          this.view.fit(ft.getGeometry())
          break;
        case 'Polygon':
          this.searchVectorSource.clear()
            ft=new Feature({
              geometry: new Polygon(coords),
              name:newval.name
            })
          ft.setStyle(this.addressSearch.ftStyles.polygon)
          this.searchVectorSource.addFeature(ft)
          this.view.fit(ft.getGeometry())
          break;
        case 'MultiPoint':
          this.searchVectorSource.clear()
            ft=new Feature({
              geometry: new MultiPoint(coords),
              name:newval.name
            })
          ft.setStyle(this.addressSearch.ftStyles.point)
          this.searchVectorSource.addFeature(ft)
          this.view.fit(ft.getGeometry())
          break;
        case 'MultiLineString':
          this.searchVectorSource.clear()
          ft=new Feature({
            geometry: new MultiLineString(coords),
            name:newval.name
          })
          ft.setStyle(this.addressSearch.ftStyles.line)
          this.searchVectorSource.addFeature(ft)
          break;
        case 'MultiPolygon':
          this.searchVectorSource.clear()
          ft=new Feature({
              geometry: new MultiPolygon(coords),
            name:newval.name
            })
          ft.setStyle(this.addressSearch.ftStyles.polygon)
          this.searchVectorSource.addFeature(ft)
          this.view.fit(ft.getGeometry())
          break;
        case 'Circle':
          this.searchVectorSource.clear()
          ft=new Feature({
              geometry: new Circle(this.coordsConverter(newval.center, 'Circle', "EPSG:4326", "EPSG:3857"), newval.radius),
            name:newval.name
            })
          ft.setStyle(this.addressSearch.ftStyles.line)
          this.searchVectorSource.addFeature(ft)
          this.view.fit(ft.getGeometry())
          break;
        default:
          break;
      }
    },
    currUUID: {
      handler(newval) {
        this.setCurrentObjectByUUID(newval[0])
      },
      deep: true
    },
    'drawner'(){
      setTimeout(this.updateMap,250)
    }
  }
}
</script>

<style scoped>
.v-icon:hover{
  background: #d9dada;
  border-radius: 4px;
}
.search-btn{
  position: absolute;
  top:.5rem;
  right:.5rem;
  z-index:20;
  text-transform: none;
  font-weight: bold;
  font-size:14px;
}
.save-from-search-btn{
  position: absolute;
  top:.5rem;
  right:8rem;
  z-index:20;
  text-transform: none;
  font-weight: bold;
  font-size:14px;
}
.clear-search-btn{
  position: absolute;
  top:4rem;
  right:.5rem;
  z-index:20;
  text-transform: none;
  font-weight: bold;
  font-size:14px;
}
.save-btn{
  position: absolute;
  top:.5rem;
  left:.5rem;
  z-index:20;
  text-transform: none;
  font-weight: bold;
  font-size:14px;
}
.clear-save-btn{
  position: absolute;
  top:4rem;
  left:.5rem;
  z-index:20;
  text-transform: none;
  font-weight: bold;
  font-size:14px;
}
.treeContainer {
    width: 100%;
    max-height: calc(100vh - 140px);
    overflow: auto;
    font-weight: normal;
    padding: 12px;
}
</style>