<template>
<v-container>
  <v-col cols="12">
    <v-row justify="end">
      <v-col cols="9">
        <h2>Дашборд по текущим простоям Контейнеров</h2>
      </v-col>
      <v-col cols="3">
        <v-input>
          <v-text-field
              type="number"
              variant="outlined"
              label="Укажите Количество дней простоя"
              v-model="days"
              append-inner-icon="mdi-reload"
              v-on:click:append-inner="getReport"
          >
          </v-text-field>
        </v-input>
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="9">
        <div id="mapContent">
          <div ref="map" style="height: 100%">
          </div>
        </div>
      </v-col>
      <v-col cols="3">
          <v-card v-if="result"
                  color="#c1c1c1"
          >
            <v-card-title>
              Фильтры отображения
            </v-card-title>
            <v-card-subtitle>
              Контейнеры в дочерних геозонах по категориям
            </v-card-subtitle>
            <v-card-text>
              <v-switch
                  hide-details
                  v-for="cat in categories.cats"
                  :key="cat.id"
                  :label="cat.name"
                  v-model="cat.visible"
                  color="blue"
                  :disabled="categories.showALL"
              ></v-switch>
            </v-card-text>
          </v-card>
          <v-card v-if="currentObj" color="orange">
            <v-card-title>
              Контейнер {{currentObj.Container}}
            </v-card-title>
            <v-card-subtitle>
              {{currentObj.Device}}
            </v-card-subtitle>
            <v-card-text>
              <p>Последние данные: {{new Date(currentObj.LastDataTime*1000).toLocaleString()}}</p>
              <p>Начало простоя: {{new Date(currentObj.DownTime*1000).toLocaleString()}}</p>
              <p>
                Геозона:
                <v-chip
                    variant="flat"
                    color="primary"
                    prepend-icon="mdi-vector-polygon"
                    size="small"
                >
                  {{currentObj.GeofenceName}}
                </v-chip>
              </p>
            </v-card-text>
          </v-card>
      </v-col>
    </v-row>
    <v-row v-if="result">
      <v-col>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-title>
              Всего в простое более {{result.Days}} дней сейчас находится: {{result.Count}} Контейнеров
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <template
                  v-for="(value,item) in result.Items"
                  :key="item"
              >
                <template v-if="Object.getOwnPropertyNames(this.result.Items[item].UniqueContainers).length">
                    <v-card-title>
                      <v-chip
                          :text="value.SuperGeoName"
                          label
                          color="success"
                          variant="outlined"
                      ></v-chip>
                      в простое
                      {{ Object.getOwnPropertyNames(this.result.Items[item].UniqueContainers).length }} Контейнеров</v-card-title>
                    <v-card-text>

                      <template v-for="cat in value.Categories" :key="cat.CategoryName">
                        <template
                            v-if="cat.Count"
                        >
                            <v-card-title>{{cat.CategoryName}}</v-card-title>
                            <v-card-text>
                              <v-expansion-panels>
                                <v-expansion-panel>
                                  <v-expansion-panel-title>
                                    Простаивает {{cat.Count}} Контейнеров
                                  </v-expansion-panel-title>

                                  <v-expansion-panel-text>
                                    <v-chip
                                        v-for="cont in cat.Containers"
                                        :key="cont.Container"
                                        :text="cont.Container+'-'+cont.GeofenceName"
                                        label
                                        variant="outlined"
                                    >
                                    </v-chip>
                                  </v-expansion-panel-text>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </v-card-text>
                        </template>
                      </template>
                    </v-card-text>
                </template>
              </template>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-col>
</v-container>
</template>

<script>


import Map from "ol/Map";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import {fromLonLat} from "ol/proj";
import {Collection, Feature} from "ol";
import {Circle, MultiPolygon, Point} from "ol/geom";
import {Fill, Stroke, Style, Text} from "ol/style";
import View from "ol/View";
import CircleStyle from "ol/style/Circle";

export default {
  name: "DowntimeReport",
  data(){
    return{
      categories:{
        cats:[]
      },
      currentObj:undefined,
      days:2,
      result:undefined,
      olMap:undefined,
      mapload:false,
      source: new VectorSource({format: new GeoJSON()}),
      points: new VectorSource({format: new GeoJSON()}),
    }
  },
  methods:{
    getReport(){
      this.loading=true
      this.currentObj=undefined
      this.$store.dispatch('reports/getDownTime',this.days)
          .then(r=>{
            this.result=r.data
            this.renderGeofs()
            this.renderCats()
            this.loading=false
          })
          .catch(e=>{
            console.log(e)
            this.loading=false
          })
    },
    renderGeofs(){
      this.source.refresh()
      this.points.refresh()
      let fts=[]
      for (let item in this.result.Items){
        this.geofences.forEach(geofence=>{
          if(geofence.uuid===item){
            if (geofence.type === 'polygon') {
              let coords = []
              geofence.coords.coords.forEach(function (poly) {
                let polycoord=[]
                poly.forEach(lineRing=>{
                  let coords=[]
                  lineRing.forEach(coord=>{
                    coords.push(fromLonLat([coord[0],coord[1]]))
                  })
                  polycoord.push(coords)
                })
                coords.push(polycoord)
              })
              const ft = new Feature({
                geometry: new MultiPolygon(coords)
              })
              if(Object.getOwnPropertyNames(this.result.Items[item].UniqueContainers).length){
                ft.setStyle(new Style({
                  fill:new Fill({
                    color:'orange'
                  })/*,
                        stroke:new Stroke({
                          color:'#fa0808',
                          width:1
                      })*/,
                  text:new Text({
                    text: geofence.name,
                    font: 'bold 16px Roboto',
                  })
                }))
              }else{
                ft.setStyle(new Style({
                  fill:new Fill({
                    color:'#11d011'
                  }),
                  stroke:new Stroke({
                    color:'#eaeaea',
                    width:1
                  })
                }))
              }
              fts.push(ft)
            }
            if (geofence.type === 'circle') {
              const center = fromLonLat(geofence.coords.center)
              let c = new Circle(center)
              c.setCenter(center)
              c.setRadius(geofence.coords.radius)
              const ft = new Feature({
                geometry: c,
                name: geofence.name,
              })
              if(typeof (geofence.style.fill.color)!=="undefined"){
                var circleTextStyle = new Style({
                  text:new Text({
                    text:geofence.name,
                    font:'bold 26px Roboto',
                    placement:'point',
                    overflow:true
                  })})
                var CircleStyle=new Style({
                  fill:new Fill({
                    color:geofence.style.fill.color
                  }),
                  stroke:new Stroke({
                    color:geofence.style.stroke.color,
                    width:geofence.style.stroke.width
                  }),
                })
                var cStyle = function(feature, resolution) {
                  var styles = [CircleStyle];
                  if (resolution<5) {
                    circleTextStyle.getText().setText(feature.get('name'))
                    styles.push(circleTextStyle);
                  }
                  return styles;
                }
                ft.setStyle(cStyle)
              }
              fts.push(ft)
            }
          }
        })
      }
      this.source.addFeatures(fts)
    },
    renderALL(){
      this.points.refresh()
      let points=[]
      for (let item in this.result.Items){
        if(this.result.Items[item].Containers?.length){
          this.result.Items[item].Containers.forEach(container=>{
            let ft=new Feature({
              geometry:new Point(fromLonLat([container.Longitude,container.Latitude])),
              data:container
            })
            ft.setStyle(new Style({
              image:new CircleStyle({
                radius:4,
                fill: new Fill({
                  color: '#070707'
                }),
                stroke:new Stroke({
                  width:1,
                  color:'#ffffff'
                })
              })
            }))
            points.push(ft)
          })
        }}
      this.points.addFeatures(points)
    },
    renderCats(){
      this.points.refresh()
      let points=[]
      this.categories.cats.forEach(cat=>{
        if(cat.visible){
          console.log(cat.name)
          for (let item in this.result.Items){
            if(this.result.Items[item]?.Categories[cat.id]?.Count){
              console.log('length:',this.result.Items[item].Categories[cat.id].Containers.length)
              for(let container in this.result.Items[item].Categories[cat.id].Containers){
                const cont=this.result.Items[item].Categories[cat.id].Containers[container]

                let ft=new Feature({
                  geometry:new Point(fromLonLat([cont.Longitude,cont.Latitude])),
                  data:cont
                })
                ft.setStyle(new Style({
                  image:new CircleStyle({
                    radius:4,
                    fill: new Fill({
                      color: '#070707'
                    }),
                    stroke:new Stroke({
                      width:1,
                      color:'#ffffff'
                    })
                  })
                }))
                points.push(ft)
              }
            }}
        }
      })
      this.points.addFeatures(points)
    }
  },
  computed:{
    loading:{
      set: function (newval) {
        this.$store.dispatch('app/loading',newval)
      },
      get: function (){
        return this.$store.getters['app/loader']
      }
    },
    geofences() {
      return this.$store.getters['geofences/geofences']
    },
    cats(){
      return this.$store.getters['geofences/geocats']
    },
  },
  created() {
    this.categories.cats=[]
    this.categories.cats.push({
      name:'Без Категории',
      id:0,
      visible:true
    })
    this.cats.forEach(d=>{
      this.categories.cats.push({
        name:d.NAME,
        id:d.ID,
        visible:true
      })
    })
  },
  mounted() {
    this.olMap = new Map({
      view: new View({
        center: fromLonLat([100, 62]),
        zoom: 2,
      }),
      controls:new Collection(),
      target: this.$refs['map'],
      layers: [
        new VectorLayer({
          source: this.source,
          visible:true,
          name: 'Geofences',
        }),
        new VectorLayer({
          source: this.points,
          visible:true,
          name: 'Containers',
        }),
      ],
    })
    this.olMap.on('singleclick', evt=> {
      let fts=[]
      this.olMap.forEachFeatureAtPixel(evt.pixel, function (feature) {
        fts.push(feature);
      });
      if (fts.length>0){
        fts.forEach(ft=>{
          const geomName=ft.getGeometry().getType()
          if(geomName==='Point'){
            const data=ft.get('data')
            this.currentObj=data
            fts.length=0
          }
        })
      }
    });
  },
  watch:{
    'categories':{
      handler(newval){
        this.currentObj=undefined
        if(!this.result){
          return;
        }
        if(newval.showALL){

          this.renderALL()
          return
        }else{
          this.renderCats()
          return;
        }
      },
      deep:true
    }
  }
}
</script>

<style scoped>
#mapContent{
  width: 100%;
  height: 600px;
  /*background: #00C3DA;*/
}
</style>