<template>
  <v-container>
    <v-btn
        v-if="result"
        class="restart-item"
        color="success"
        icon="mdi-file-excel-outline"
        title="Выгрузить в Excel"
        @click="createXLSX"
    ></v-btn>
    <v-btn-group>
      <v-btn prepend-icon="mdi-file-chart" @click="currentdata='techreport'" :active="currentdata==='techreport'">
        Отчет
      </v-btn>
      <v-btn prepend-icon="mdi-chart-bar" @click="currentdata='ripperday'" :active="currentdata==='ripperday'">Выход из
        строя
      </v-btn>
    </v-btn-group>
    <v-card v-if="currentdata==='techreport'">
      <v-card-title>
        Отчет по техническому мониторингу трекеров
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-input>
            <v-col cols="4">
              <v-row>
                <v-text-field
                    v-model="from"
                    hide-details
                    label="Начало Периода"
                    type="date"
                    variant="outlined"
                ></v-text-field>
                <v-text-field
                    v-model="to"
                    hide-details
                    label="Конец Периода"
                    type="date"
                    variant="outlined"
                ></v-text-field>
                <v-btn
                    icon="mdi-reload"
                    title="Построить Отчет"
                    variant="flat"
                    @click="getReport"
                ></v-btn>
              </v-row>
            </v-col>
          </v-input>
        </v-form>
        <template v-if="result">
          <v-data-table
              :headers="headers"
              :height="dataheight"
              :items="tableData"
              fixed-footer
              fixed-header
          >
            <template v-slot:[`item.container`]="{ item }">
              <v-chip
                  v-if="item.raw.container"
                  :text="item.raw.container"
                  color="success"
                  label
                  variant="outlined"
              >
              </v-chip>
              <v-chip
                  v-else
                  label
                  variant="outlined"
              >
                Отсутствует
              </v-chip>
            </template>
            <template v-slot:[`item.active`]="{ item }">
              <v-icon
                  v-if="item.raw.active"
                  color="success"
                  icon="mdi-check-bold"
              >
              </v-icon>
              <v-icon
                  v-else
                  color="orange"
                  icon="mdi-alert-circle-outline"
              >
              </v-icon>
            </template>
            <template v-slot:[`item.onboard`]="{ item }">
              <v-icon
                  v-if="item.raw.onboard"
                  color="success"
                  icon="mdi-check-bold"
              >
              </v-icon>
              <v-icon
                  v-else
                  color="orange"
                  icon="mdi-alert-circle-outline"
              >
              </v-icon>
            </template>

          </v-data-table>
        </template>
      </v-card-text>
    </v-card>
    <v-card v-if="currentdata==='ripperday'">
      <v-card-title>
        График количества вышедших из строя устройств по дням
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-input>
            <v-col cols="10">
              <v-row>
                <v-text-field
                    v-model="from"
                    hide-details
                    label="Начало Периода"
                    type="date"
                    variant="outlined"
                ></v-text-field>
                <v-text-field
                    v-model="to"
                    hide-details
                    label="Конец Периода"
                    type="date"
                    variant="outlined"
                ></v-text-field>
                <v-switch
                    v-model="accumulated"
                    label="Нарастающий итог"
                    color="success"
                ></v-switch>
                <v-btn
                    icon="mdi-reload"
                    title="Построить Отчет"
                    variant="flat"
                    @click="getRipPerDays"
                ></v-btn>
              </v-row>
            </v-col>
          </v-input>
        </v-form>
        <bar-chart :chart-data="ripPreDayDataset"></bar-chart>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import BarChart from "@/components/BarChart.vue";

let XLSX = require("xlsx-js-style");
export default {
  name: "ReportTechMonitoring",
  components: {BarChart},
  data() {
    return {
      from: undefined,
      to: undefined,
      result: undefined,
      countPerDaydata: undefined,
      ripPerDayData: undefined,
      accumulated: false,
      trackertypes: [{name: 'Китай', value: 'chinaTracker'}, {name: 'Максима', value: 'maximaTracker'}],
      currentdata: "techreport"
    }
  },
  methods: {
    getReport() {
      const from = Math.floor(new Date(this.from).getTime() / 1000)
      const to = Math.floor(new Date(this.to).getTime() / 1000)
      this.$store.dispatch('reports/getTechMonitoring', {from: from, to: to})
          .then(r => {
            this.result = r.data
          })
          .catch(e => alert(e.response.data))
    },
    getRipPerDays() {
      const from = Math.floor(new Date(this.from).getTime() / 1000)
      const to = Math.floor(new Date(this.to).getTime() / 1000)

      this.$store.dispatch('reports/getTechMonitoringRipPerDays', {from: from, to: to})
          .then(r => {
            this.ripPerDayData = r.data
            this.getCountPerDays()
          })
          .catch(e => alert(e.response.data))
    },
    getCountPerDays() {
      const from = Math.floor(new Date(this.from).getTime() / 1000)
      const to = Math.floor(new Date(this.to).getTime() / 1000)
      this.$store.dispatch('reports/getTechMonitoringCountPerDays', {from: from, to: to})
          .then(r => {
            this.countPerDaydata = r.data
          })
          .catch(e => alert(e.response.data))
    },
    createXLSX() {
      let wb = XLSX.utils.book_new()
      const data = []
      this.headers.forEach(header => {
        data.push(header.title)
      })
      let worksheet = XLSX.utils.aoa_to_sheet([
        data
      ]);
      this.tableData.forEach((dev) => {
        const td = []
        for (let item in dev) {
          if (item !== 'last_data') {
            td.push(dev[item])
          }
        }
        td.push(...[
          dev.last_data.connect_time,
          dev.last_data.data_time,
          dev.last_data.data_type,
          dev.last_data.longitude,
          dev.last_data.latitude,
          dev.last_data.gps_time,
          dev.last_data.battery_power,
          dev.last_data.temperature_time,
          dev.last_data.temperature,
          dev.last_data.LBStime,
          dev.last_data.mcc.country,
          dev.last_data.mcc.network,
        ])
        XLSX.utils.sheet_add_aoa(worksheet, [
          [
            ...td
          ]
        ], {origin: -1});
      })
      XLSX.utils.book_append_sheet(wb, worksheet, "Трекеры");
      XLSX.writeFile(wb, `Выгрузка Трекеров ${new Date().toLocaleString()}.xlsx`);
    },
    createLabelsForDataSet() {
      const res = []
      const startday = new Date(this.from)
      const endday = new Date(this.to)
      for (; startday.getTime() <= endday.getTime(); startday.setDate(startday.getDate() + 1)) {
        res.push(startday.getFullYear()+'-'+('0'+(startday.getMonth()+1)).slice(-2)+'-'+('0'+(startday.getDate())).slice(-2))
      }
      return res
    },
  },
  computed: {
    headers() {
      const res = [
        {
          title: 'Трекер',
          align: 'start',
          sortable: true,
          key: 'identificator',
        },
        {
          title: 'Тип',
          align: 'start',
          sortable: true,
          key: 'type',
        },
        {
          title: 'Активность',
          align: 'start',
          sortable: true,
          key: 'active',
        },
        {
          title: 'Установлен',
          align: 'start',
          sortable: true,
          key: 'onboard',
        },
        {
          title: 'Контейнер',
          align: 'start',
          sortable: true,
          key: 'container',
        },
      ]
      for (let year in this.result.calendar_matrix) {
        for (let month in this.result.calendar_matrix[year].months) {
          res.push({
            title: year + '-' + ('0' + month).slice(-2),
            align: 'start',
            sortable: true,
            key: year + '-' + month
          })
        }
      }
      res.push(...[
        {
          title: 'Время последней связи',
          align: 'start',
          sortable: false,
          key: 'last_data.connect_time',
        },
        {
          title: 'Время последней посылки',
          align: 'start',
          sortable: false,
          key: 'last_data.data_time',
        },
        {
          title: 'Тип Пакета',
          align: 'start',
          sortable: true,
          key: 'last_data.data_type',
        },
        {
          title: 'Последняя Валидная Широта',
          align: 'start',
          sortable: false,
          key: 'last_data.longitude',
        },
        {
          title: 'Последняя Валидная Долгота',
          align: 'start',
          sortable: false,
          key: 'last_data.latitude',
        },
        {
          title: 'Время Последнего Валидного GPS',
          align: 'start',
          sortable: false,
          key: 'last_data.gps_time',
        },
        {
          title: 'АКБ',
          align: 'start',
          sortable: false,
          key: 'last_data.battery_power',
        },
        {
          title: 'Время Температуры',
          align: 'start',
          sortable: false,
          key: 'last_data.temperature_time',
        },
        {
          title: 'Температура',
          align: 'start',
          sortable: false,
          key: 'last_data.temperature',
        },
        {
          title: 'Время LBS',
          align: 'start',
          sortable: false,
          key: 'last_data.LBStime',
        },
        {
          title: 'Страна',
          align: 'start',
          sortable: false,
          key: 'last_data.mcc.country',
        },
        {
          title: 'Оператор',
          align: 'start',
          sortable: false,
          key: 'last_data.mcc.network',
        },
      ])
      return res
    },
    tableData() {
      const res = []
      for (let dev in this.result.devices) {
        const temp = {
          identificator: this.result.devices[dev].identificator,
          type: this.result.devices[dev].type,
          active: this.result.devices[dev].active,
          onboard: this.result.devices[dev].onboard,
          container: this.$store.getters['devices/containerNameByUUID'](this.result.devices[dev].container),
          last_data: {
            connect_time: this.result.devices[dev].last_data.connect_time ? new Date(this.result.devices[dev].last_data.connect_time * 1000).toLocaleString() : 'Нет Данных',
            data_time: this.result.devices[dev].last_data.data_time ? new Date(this.result.devices[dev].last_data.data_time * 1000).toLocaleString() : 'Нет Данных',
            data_type: this.result.devices[dev].last_data.data_type ? this.result.devices[dev].last_data.data_type : 'Нет Данных',
            longitude: this.result.devices[dev].last_data.longitude ? this.result.devices[dev].last_data.longitude : 'Нет Данных',
            latitude: this.result.devices[dev].last_data.latitude ? this.result.devices[dev].last_data.latitude : 'Нет Данных',
            gps_time: this.result.devices[dev].last_data.gps_time ? new Date(this.result.devices[dev].last_data.gps_time * 1000).toLocaleString() : 'Нет Данных',
            temperature_time: this.result.devices[dev].last_data.temperature_time ? new Date(this.result.devices[dev].last_data.temperature_time * 1000).toLocaleString() : 'Нет Данных',
            battery_power: this.result.devices[dev].last_data.battery_power ? this.result.devices[dev].last_data.battery_power : 'Нет Данных',
            temperature: this.result.devices[dev].last_data.temperature ? this.result.devices[dev].last_data.temperature : 'Нет Данных',
            LBStime: this.result.devices[dev].last_data.LBStime ? new Date(this.result.devices[dev].last_data.LBStime * 1000).toLocaleString() : 'Нет Данных',
            mcc: this.result.devices[dev].last_data.mcc.mcc ? this.result.devices[dev].last_data.mcc : {country:"Нет Данных",network:"Нет Данных"}
          },
        }

        for (let year in this.result.devices[dev].years) {
          for (let month in this.result.devices[dev].years[year].months) {
            temp[year + '-' + month] = this.result.devices[dev].years[year].months[month].count
          }
        }
        res.push(temp)
      }
      return res
    },
    dataheight() {
      return window.innerHeight - 300
    },
    ripPreDayDataset() {
      const labels = this.createLabelsForDataSet()
      let dataset = {
        labels: labels,
        datasets: []
      }
      if (this.ripPerDayData !== undefined) {
        const maximaMap = new Map

        let maximadata = {
          backgroundColor: '#ec0e0e',
          label: 'Максима Трекер (Выход из строя)',
          data: []
        }

        this.ripPerDayData.forEach((item) => {
          if (item.type === "maximaTracker") {
            if (maximaMap.get(item.day) === undefined) {
              maximaMap.set(item.day, 1)
            } else {
              maximaMap.set(item.day, maximaMap.get(item.day) + 1)
            }
          }
        })
        if (this.accumulated) {
          let count = 0
          for (const key of maximaMap.keys()) {
            count = maximaMap.get(key) + count
            maximadata.data.push({
              x: key,
              y: count
            })
          }
        } else {
          for (const key of maximaMap.keys()) {
            maximadata.data.push({
              x: key,
              y: maximaMap.get(key)
            })
          }
        }

        dataset.datasets.push(maximadata)
        if (this.countPerDaydata !== undefined) {
          let perDayData = {
            backgroundColor: '#14c9ea',
            label: 'Устройства Максима на связи',
            data: []
          }
          this.countPerDaydata.types.forEach((item) => {
            if (item.type === 'maximaTracker') {
              item.days.forEach(day => {
                perDayData.data.push({
                  x: day.day,
                  y: day.count
                })
              })
            }
          })
          dataset.datasets.push(perDayData)
        }
      }
      if (this.ripPerDayData !== undefined) {

        const chinaMap = new Map

        let chinaData = {
          backgroundColor: '#a9f33b',
          label: 'Китай Трекер (Выход из строя)',
          data: []
        }
        this.ripPerDayData.forEach((item) => {

          if (item.type === "chinaTracker") {
            if (chinaMap.get(item.day) === undefined) {
              chinaMap.set(item.day, 1)
            } else {
              chinaMap.set(item.day, chinaMap.get(item.day) + 1)
            }
          }
        })
        if (this.accumulated) {
          let count = 0
          for (const key of chinaMap.keys()) {
            count = chinaMap.get(key) + count
            chinaData.data.push({
              x: key,
              y: count
            })
          }
        } else {
          for (const key of chinaMap.keys()) {
            chinaData.data.push({
              x: key,
              y: chinaMap.get(key)
            })
          }
        }
        dataset.datasets.push(chinaData)
        if (this.countPerDaydata !== undefined) {
          let perDayData = {
            backgroundColor: '#d0ae07',
            label: 'Устройства Китай на связи',
            data: []
          }
          this.countPerDaydata.types.forEach((item) => {
            if (item.type === 'chinaTracker') {
              item.days.forEach(day => {
                perDayData.data.push({
                  x: day.day,
                  y: day.count
                })
              })
            }
          })
          dataset.datasets.push(perDayData)
        }
      }

      return dataset
    }
  }
}
</script>

<style scoped>
.restart-item {
  z-index: 1;
  position: fixed;
  top: 100px;
  right: 72px;
}

</style>