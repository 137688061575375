<template>
  <div style="display: flex;flex-direction: row;height: 100%">
    <div class="filters">
      <div class="treeContainer">
        <TreeComponent
            v-if="loaded"
            :icon="'mdi-train-car-gondola'"
            :mainColor="'#5d5d5d'"
            :selectedColor="'#2c2b2b'"
            :selectedObject=currentObj
            :setCurrentObject=setCurrentObject
            :showNode=showNode
            :swVisible=visibleFeature
            :treeData=treeComp
        ></TreeComponent>
      </div>
    </div>
    <div id="mapContent">
      <v-btn v-if="trackSource.getFeatures().length" class="track-btn" prepend-icon="mdi-close-box-outline"
             title="Очистить карту" @click="trackSource.clear();trackOnMap=false">
        Убрать трек с карты
      </v-btn>
      <v-btn
          class="search-btn"
          icon="mdi-map-search"
          @click="showsearch=!showsearch"
      >
      </v-btn>
      <v-autocomplete
          v-if="showsearch"
          v-model="selected"
          v-model:loading="searchLoad"
          v-model:search="search"
          :items="searchElems"
          class="search-area"
          clearable
          hide-details
          item-title="name"
          item-value="uuid"
          label="Поиск Геозон и Контейнеров"
          no-data-text="Нет данных"
          return-object
          transition
          variant="outlined"
      >
        <template v-slot:item="{ props,item }">
          <v-list-item
              v-bind="props"
          >
            <p class="search-item-subtitle">{{ item.raw.type }}</p>
          </v-list-item>
        </template>
      </v-autocomplete>
      <div ref="map" style="width: 100%;height: 100%;">
        <div v-if="maploaded" class="layersSwitcher">
          <v-menu>
            <template v-slot:activator="{ props  }">
              <v-btn
                  color="gray"
                  icon="mdi-layers-plus"
                  title="Управление Отображением Геозон"
                  v-bind="props"
              >
              </v-btn>
            </template>
            <v-list>
              <template v-if="olMap.getAllLayers()[1].values_.visible">
                <v-list-item
                    v-for="opt in renderedCats"
                    :key="opt.id"
                >
                  <v-list-item-title>
                    <v-checkbox v-model="opt.visible" :label="opt.name" hide-details
                                v-on:change="setGeoCatVisible"></v-checkbox>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <v-list-item>
                <v-switch v-model="olMap.getAllLayers()[1].values_.visible" color="success"
                          hide-details="true" label="Отображать геозоны"
                          style="width: auto" @click="olMap.render()"></v-switch>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div id="popup" class="ol-popup">
          <a id="popup-closer" class="ol-popup-closer" href="#"></a>
          <div id="popup-content"></div>
        </div>
        <div v-if="currentObj.type==='car' && dataloadeded.lastdata" class="devinform" style="padding: 12px;">
          <div style="display:flex;flex-direction:row;width: 100%;justify-content: end">
            <v-btn
                icon="mdi-close-thick"
                variant="text"
                @click="dataloadeded.lastdata=undefined"
            ></v-btn>
          </div>
          <h3 style="text-align: center">Информация о Контейнере</h3>
          <v-table>
            <tbody>
            <tr>
              <td>
                Название
              </td>
              <td>
                {{ currentObj.label }}
              </td>
            </tr>
            <tr>
              <td>
                Идентификатор
              </td>
              <td>
                {{ currentObj.identificator }}
              </td>
            </tr>
            <tr>
              <td>
                Дата установки оборудования
              </td>
              <td>
                {{
                  currentObj.montage_date ? new Date(currentObj.montage_date * 1000).toLocaleDateString() : 'Не Внесена'
                }}
              </td>
            </tr>

            <tr>
              <td>Вес бруто контейнера</td>
              <td>{{
                  $store.getters['data/lastDevData'](currentObj.uuid).Data.container.weight_brutto
                }}
              </td>
            </tr>
            <tr>
              <td>Тип контейнера</td>
              <td>{{ $store.getters['data/lastDevData'](currentObj.uuid).Data.container.type }}</td>
            </tr>
            <tr>
              <td>Тип контейнера по iso:6346</td>
              <td>{{ $store.getters['data/lastDevData'](currentObj.uuid).Data.container.iso_6346 }}</td>
            </tr>
            <tr>
              <td>Тип контейнера по iso:668</td>
              <td>{{ $store.getters['data/lastDevData'](currentObj.uuid).Data.container.iso668 }}</td>
            </tr>
            <tr>
              <td>Типоразмер контейнера</td>
              <td>{{ $store.getters['data/lastDevData'](currentObj.uuid).Data.container.size_type }}</td>
            </tr>
            <tr>
              <td>ДФЭ</td>
              <td>{{ $store.getters['data/lastDevData'](currentObj.uuid).Data.container.teu }}</td>
            </tr>
            <tr>
              <td>Макс. допустимый вес бруто</td>
              <td>{{
                  $store.getters['data/lastDevData'](currentObj.uuid).Data.container.gross_max_mt
                }}
              </td>
            </tr>
            <tr>
              <td>Вес тары контейнер</td>
              <td>{{
                  $store.getters['data/lastDevData'](currentObj.uuid).Data.container.tare_weight_mt
                }}
              </td>
            </tr>
            <tr>
              <td>Исправность контейнера</td>
              <td>
                <v-icon v-if="$store.getters['data/lastDevData'](currentObj.uuid).Data.container.faulty"
                        color="orange"
                        icon="mdi-alert-circle-outline"
                        title="Неисправен"
                >

                </v-icon>
                <v-icon v-else
                        color="success"
                        icon="mdi-check-circle-outline"
                        title="Исправен"
                >

                </v-icon>
              </td>
            </tr>
            <tr>
              <td>Заказ</td>
              <td>
                <v-btn
                    v-if="$store.getters['data/lastDevData'](currentObj.uuid).Data.order.id"
                    prepend-icon="mdi-order-bool-ascending"
                    variant="outlined"
                    @click="modals.order=true"
                >
                  {{ $store.getters['data/lastDevData'](currentObj.uuid).Data.order.id }}
                </v-btn>
                <p v-else>НЕ НАЙДЕН</p>
              </td>
            </tr>
            <tr>
              <td>
                Установленные геозоны
              </td>
              <td>
                <v-menu location="end">
                  <template v-slot:activator="{ props }">
                    <v-btn icon="mdi-vector-polygon" v-bind="props" variant="text"></v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                        v-for="geofence in currentObj.geofences" v-bind:key="geofence"
                    >
                      <template v-for="geo in geofences" v-bind:key="geo.name">
                        <v-list-item-title v-if="geofence===geo.uuid">{{ geo.name }}<br>
                        </v-list-item-title>
                      </template>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
            <tr>
              <td>Время замены АКБ</td>
              <td>{{ new Date(currentObj.battaryendtime * 1000).toLocaleDateString() }}</td>
            </tr>
            </tbody>
          </v-table>
          <h3 style="text-align: center">Последние данные</h3>
          <v-table
          >
            <tbody>
            <tr>
              <td>Дата</td>
              <td>{{
                  new Date($store.getters['data/lastDevData'](currentObj.uuid).Loc.Timestamp * 1000).toLocaleString()
                }}
              </td>
            </tr>
            <tr>
              <td>Широта</td>
              <td>{{ $store.getters['data/lastDevData'](currentObj.uuid).Loc.Latitude }}</td>
            </tr>
            <tr>
              <td>Долгота</td>
              <td>{{ $store.getters['data/lastDevData'](currentObj.uuid).Loc.Longitude }}</td>
            </tr>
            <tr>
              <td>Скорость</td>
              <td>{{ $store.getters['data/lastDevData'](currentObj.uuid).Loc.Speed }}</td>
            </tr>
            <tr>
              <td>АКБ</td>
              <td>{{ $store.getters['data/lastDevData'](currentObj.uuid).Loc.Battery }} %</td>
            </tr>
            <tr>
              <td>Кол-во спутников</td>
              <td>{{ $store.getters['data/lastDevData'](currentObj.uuid).Loc.Satellites }}</td>
            </tr>
            <template v-if="$store.getters['data/lastDevData'](currentObj.uuid).Loc.Geofences">
              <tr>
                <th colspan="2" style="text-align: center"><h4>Сейчас в геозонах</h4></th>
              </tr>
              <tr>
                <td colspan="2">
                  <template
                      v-if="$store.getters['data/lastDevData'](currentObj.uuid).Loc.Geofences.length">
                    <template
                        v-for="geofence in $store.getters['data/lastDevData'](currentObj.uuid).Loc.Geofences"
                        v-bind:key="geofence">
                      <template v-for="geo in geofences" v-bind:key="geo.name">
                        <template v-if="geofence.Uuid===geo.uuid">
                          <v-chip
                              :text="geo.name"
                              class="ma-2"
                              color="success"
                              prepend-icon="mdi-shape-polygon-plus"
                              size=10
                              style="font-size:10px;padding: 4px;margin: 2px"
                              variant="outlined"
                          ></v-chip>
                        </template>
                      </template>
                    </template>
                  </template>
                </td>
              </tr>
            </template>
            </tbody>
          </v-table>
        </div>
      </div>
    </div>
  </div>
  <!--Track-->
  <v-dialog
      v-model="modals.track"
      max-width="500px"
      transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
          color="black"
          title="Запрос маршрута движения"
      >
      </v-toolbar>
      <v-card-text>
        <v-form v-model="trackquery.formvalid">
          <v-input>
            <v-text-field
                v-model="trackquery.from"
                :rules="trackquery.dateFromRules"
                label="Начало периода"
                required
                type="datetime-local"
                variant="underlined"
            ></v-text-field>
          </v-input>
          <v-input>
            <v-text-field
                v-model="trackquery.to"
                :rules="trackquery.dateToRules"
                label="Окончание периода"
                required
                type="datetime-local"
                variant="underlined"
            ></v-text-field>
          </v-input>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row justify="space-around">
          <v-btn
              @click="modals.track = false"
          >
            Закрыть
          </v-btn>
          <v-btn
              :disabled="!trackquery.formvalid"
              @click="gettrack();modals.track=false"
          >
            Запросить
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!--Order-->
  <v-dialog
      v-model="modals.order"
      max-width="860"
      min-height="600px"
      transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        Информация о заказе {{ $store.getters['data/lastDevData'](currentObj.uuid).Data.order.id }}
      </v-card-title>
      <v-card-subtitle>Дата начала
        {{
          new Date($store.getters['data/lastDevData'](currentObj.uuid).Data.order.period.date_start).toLocaleString()
        }}
      </v-card-subtitle>
      <v-card-text>
        <template v-if="$store.getters['data/lastDevData'](currentObj.uuid)?.Data?.order?.legs?.length">
          <p>
            Состояние заказа:
            <v-chip
                :text="order_state.get($store.getters['data/lastDevData'](currentObj.uuid).Data.order.state)"
                label
            >

            </v-chip>
          </p>
          <br>
          <p>
            Режим перевозки:
            <v-chip
                :text="route_mode.get($store.getters['data/lastDevData'](currentObj.uuid).Data.order.additional.route_mode_id)"
                label
            >

            </v-chip>
          </p>
          <br>
          <v-timeline
              side="end"
          >
            <v-timeline-item
                dot-color="success"
                icon="mdi-ray-start-arrow"
                size="large"
            >
              <template v-slot:opposite>
                <v-chip
                    color="success"
                    prepend-icon="mdi-flag-variant-outline"
                    text="Место Отправления"
                    variant="outlined"
                ></v-chip>
              </template>
              <v-alert
                  :value="true"
                  color="success"
                  width="520"
              >
                <p v-if="$store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs[0].points.from.catalog_id"
                >
                  Идентификатор по каталогу:
                  {{
                    $store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs[0].points.from.catalog_id
                  }}
                </p>
                <p v-if="$store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs[0].points.from.loc_code"
                >
                  Локация:
                  {{
                    $store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs[0].points.from.loc_code
                  }}
                </p>
                <p v-if="$store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs[0].points.from.station_id"
                >
                  Станция:
                  {{
                    $store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs[0].points.from.station_id
                  }}
                </p>
                <p v-if="$store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs[0].points.from.address.str"
                >
                  Адрес:
                  {{
                    $store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs[0].points.from.address.str
                  }}
                </p>
                <p>
                  Координаты: Долгота
                  {{
                    $store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs[0].points.from.address.longitude
                  }}
                  Широта
                  {{
                    $store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs[0].points.from.address.latitude
                  }}
                </p>
              </v-alert>
            </v-timeline-item>
            <template v-if="$store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs.length>1">
              <v-timeline-item
                  v-for="item in $store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs.slice(0,($store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs.length-1))"
                  :key="item.id"
                  dot-color="success"
                  icon="mdi-ray-start-end"
              >
                <v-alert
                    :icon="transport_mode.get(item.transport_mode).icon"
                    :value="true"
                    color="orange"
                    width="520"
                >
                  <v-alert-title>
                    {{ transport_mode.get(item.transport_mode).name }}
                  </v-alert-title>
                  <v-chip v-if="item.points.to.border"
                          prepend-icon="mdi-bookmark-outline"
                          text="Погранпереход"
                          variant="outlined"
                  >
                  </v-chip>
                  <p v-if="item.points.to.catalog_id"
                  >
                    Идентификатор по каталогу: {{ item.points.to.catalog_id }}
                  </p>
                  <p v-if="item.points.to.loc_code"
                  >
                    Локация: {{ item.points.to.loc_code }}
                  </p>
                  <p v-if="item.points.to.station_id"
                  >
                    Станция: {{ item.points.to.station_id }}
                  </p>
                  <p v-if="item.points.to.address.str"
                  >
                    Адрес: {{ item.points.to.address.str }}
                  </p>
                  <p>
                    Координаты: Долгота {{ item.points.from.address.longitude }} Широта
                    {{ item.points.from.address.latitude }}
                  </p>
                </v-alert>
              </v-timeline-item>
            </template>
            <v-timeline-item
                dot-color="success"
                icon="mdi-ray-end-arrow"
                size="large"
            >
              <template v-slot:opposite>
                <v-chip
                    color="success"
                    prepend-icon="mdi-flag-checkered"
                    text=" Место Прибытия"
                    variant="outlined"
                ></v-chip>
              </template>
              <v-alert
                  :icon="transport_mode.get($store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs[$store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs.length-1].transport_mode).icon"
                  :value="true"
                  color="success"
                  width="520"
              >
                <v-alert-title>
                  {{
                    transport_mode.get($store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs[$store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs.length - 1].transport_mode).name
                  }}
                </v-alert-title>
                <v-chip
                    v-if="$store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs[$store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs.length-1].points.to.border"
                    prepend-icon="mdi-bookmark-outline"
                    text="Погранпереход"
                    variant="outlined"
                >
                </v-chip>
                <p v-if="$store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs[$store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs.length-1].points.to.catalog_id"
                >
                  Идентификатор по каталогу:
                  {{
                    $store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs[$store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs.length - 1].points.to.catalog_id
                  }}
                </p>
                <p v-if="$store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs[$store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs.length-1].points.to.loc_code"
                >
                  Локация:
                  {{
                    $store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs[$store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs.length - 1].points.to.loc_code
                  }}
                </p>
                <p v-if="$store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs[$store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs.length-1].points.to.station_id"
                >
                  Станция:
                  {{
                    $store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs[$store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs.length - 1].points.to.station_id
                  }}
                </p>
                <p v-if="$store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs[$store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs.length-1].points.to.address.str"
                >
                  Адрес:
                  {{
                    $store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs[$store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs.length - 1].points.to.address.str
                  }}
                </p>
                <p>
                  Координаты: Долгота
                  {{
                    $store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs[$store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs.length - 1].points.to.address.longitude
                  }}
                  Широта
                  {{
                    $store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs[$store.getters['data/lastDevData'](currentObj.uuid).Data.order.legs.length - 1].points.to.address.latitude
                  }}
                </p>
              </v-alert>
            </v-timeline-item>
          </v-timeline>
        </template>
        <template v-else>
          Информация по данному заказу отсутствует
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import TreeComponent from "@/components/Tree";
import * as olmap from 'ol';
import {Collection, Feature} from 'ol';
import {Cluster} from 'ol/source';
import GeoJSON from 'ol/format/GeoJSON';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM'
import {fromLonLat, toLonLat} from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {MousePosition,} from "ol/control";
import {Fill, Icon, Stroke, Style, Text} from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import {LineString, Point} from 'ol/geom';
import Overlay from "ol/Overlay";
import {boundingExtent} from 'ol/extent';
import {inAndOut} from 'ol/easing';

export default {
  name: 'ContainersPage',
  components: {
    TreeComponent,
  },
  data() {
    return {
      order_state: new Map([
        ["initialize", "Заказ создается"],
        ["resources_check", "Проверка"],
        ["payment", "Оплата"],
        ["processing", "Исполнение"],
        ["complete", "Выполнен"],
        ["archive", "Архив"],
        ["decline", "Отменён"],
        ["resources_free", "Отклонение"],
        ["client_decline", "Отменен"],
        ["data_add", "Исполнение"],
        ["facts_check", "Исполнение"],
        ["releases_check", "Резервирование оборудования"],
        ["timeout_decline", "Отклонение"],
      ]),
      transport_mode: new Map([
        ["RAIL_EU", {name: "ЖД ЕВРОПА", icon: "mdi-train-car-container"}],
        ["RAIL_TM", {name: "ЖД ТУРМЕНИСТАН", icon: "mdi-train-car-container"}],
        ["RAIL_AZ", {name: "ЖД АЗЕРБАЙДЖАН", icon: "mdi-train-car-container"}],
        ["RAIL_CN", {name: "ЖД КИТАЙ", icon: "mdi-train-car-container"}],
        ["RAIL_LV", {name: "ЖД ЛАТВИЯ", icon: "mdi-train-car-container"}],
        ["RAIL_YAMAL", {name: "ЖД ПО ЯМАЛУ (СТР)", icon: "mdi-train-car-container"}],
        ["RAIL_MD", {name: "ЖД ПО МОЛДОВЕ", icon: "mdi-train-car-container"}],
        ["RAIL_MN", {name: "ЖД МОНГОЛИЯ", icon: "mdi-train-car-container"}],
        ["RAIL_EE", {name: "ЖД ПО ЭСТОНИИ", icon: "mdi-train-car-container"}],
        ["RAIL_TJ", {name: "ЖД ТАДЖИКИСТАН", icon: "mdi-train-car-container"}],
        ["SC", {name: "ВТОРИЧНЫЕ СБОРЫ", icon: "mdi-shipping-pallet"}],
        ["AIR", {name: "ВОЗДУШНЫЙ", icon: "mdi-airplane-takeoff"}],
        ["TL", {name: "АВТОМОБИЛЬНОЕ", icon: "mdi-truck-fast"}],
        ["LTL", {name: "МЕНЕЕ ЧЕМ ГРУЗОВИК", icon: "mdi-truck-fast"}],
        ["DRAYAGE", {name: "ПЕРЕВОЗКА НА ПОДВОДАХ", icon: "mdi-shipping-pallet"}],
        ["INTRMDL", {name: "ТЕРМИНАЛЬНОЕ", icon: "mdi-shipping-pallet"}],
        ["EXPRESS", {name: "ЭКСПРЕСС", icon: "mdi-shipping-pallet"}],
        ["PARCEL", {name: "УЧАСТОК ТРАНСПОРТИРОВКИ", icon: "mdi-shipping-pallet"}],
        ["RAIL", {name: "ЖЕЛЕЗНОДОРОЖНОЕ РФ", icon: "mdi-train-car-container"}],
        ["VESSEL-CH", {name: "СУДНО-CH ВОДНЫХ ПЕРЕВОЗКАХ - ЧАРТЕР", icon: "mdi-ferry"}],
        ["VESSEL-NO", {name: "СУДНО-NO ВОДНЫХ ПЕРЕВОЗКАХ - NVOCC", icon: "mdi-ferry"}],
        ["VESSEL-CO", {name: "ВОДНОЕ", icon: "mdi-ferry"}],
        ["BARGE", {name: "БАРЖА", icon: "mdi-ferry"}],
        ["PIPELINE", {name: "ТРУБОПРОВОДНЫЙ ТРАНСПОРТ", icon: "mdi-shipping-pallet"}],
        ["HANDLING", {name: "РУЧНОЙ", icon: "mdi-shipping-pallet"}],
        ["ALL", {name: "ЛЮБОЕ", icon: "mdi-shipping-pallet"}],
        ["MULTI", {name: "MULTI ЗНАЧЕНИЕ, ИСПОЛЬЗУЕМОЕ ДЛЯ GLG", icon: "mdi-shipping-pallet"}],
        ["UNKNOWN", {name: "НЕИЗВЕСТНЫЙ РЕЖИМ ДЛЯ НЕДОПУСТИМОЙ ДОСТАВКИ", icon: "mdi-shipping-pallet"}],
        ["DEMURRAGE", {name: "ПРОСТОЙ", icon: "mdi-shipping-pallet"}],
        ["RAIL_KG", {name: "ЖД КИРГИЗИЯ", icon: "mdi-train-car-container"}],
        ["RAIL_LT", {name: "ЖД ЛИТВА", icon: "mdi-train-car-container"}],
        ["RAIL_GE", {name: "ЖД ПО ГРУЗИИ", icon: "mdi-train-car-container"}],
        ["RAIL_BY", {name: "ЖД БЕЛАРУССИЯ", icon: "mdi-train-car-container"}],
        ["RAILJUNCTION", {name: "ЖД СТЫК/ПОГРАНПЕРЕХОД", icon: "mdi-train-car-container"}],
        ["PSJVS", {name: "ПСЖВС", icon: "mdi-train-car-container"}],
        ["MULTI_EU", {name: "МУЛЬТИМОДАЛ ПО ЕВРОПЕ", icon: "mdi-shipping-pallet"}],
        ["PAROM_KR", {name: "КРЫМ ПАРОМ", icon: "mdi-ferry"}],
        ["RAIL_KZ", {name: "ЖД КАЗАХСТАН", icon: "mdi-train-car-container"}],
        ["RAIL_UZ", {name: "ЖД УЗБЕКИСТАН", icon: "mdi-train-car-container"}],
        ["RAIL_FI", {name: "ЖД ПО ФИНЛЯНДИИ", icon: "mdi-train-car-container"}],
        ["JUNCTION", {name: "ПЕРЕВАЛКА/ПОГРАН ПЕРЕХОД", icon: "mdi-shipping-pallet"}],
        ["RAIL_KP", {name: "КОНТЕЙНЕРНЫЙ ПОЕЗД", icon: "mdi-shipping-pallet"}],
        ["RAIL_UA", {name: "ЖД УКРАИНА", icon: "mdi-train-car-container"}],
        ["OTHER", {name: "OTHER", icon: "mdi-shipping-pallet"}]
      ]),
      route_mode: new Map([
        [1, "Внутрироссийская"],
        [2, "Импорт"],
        [3, "Экспорт"],
        [4, "Транзит"],
        [5, "За пределами РФ"]
      ]),
      loaded: false,
      switchlayers: false,
      tileCashe: new Map(),
      search: undefined,
      searchLoad: false,
      selected: undefined,
      showsearch: false,
      clusters: undefined,
      clusterSource: undefined,
      visibleObjects: [],
      mapObjects: {
        toAdd: new Map(),
        toDelete: new Map()
      },
      tempVisibleObjects: [],
      maploaded: false,
      modals: {
        track: false,
        order: false
      },
      dataloadeded: {
        lastdata: false
      },
      trackquery: {
        from: undefined,
        to: undefined,
        formvalid: false,
        trackName: undefined,
        uuid: undefined,
        dateFromRules: [
          v => !!v || 'Не введена дата начала периода',
        ],
        dateToRules: [
          v => !!v || 'Не введена дата окончания периода',
          () => this.trackquery.from < this.trackquery.to || 'Дата окончания периода должна быть больше даты начала',
        ]
      },
      currentObj: {},
      source: new VectorSource({format: new GeoJSON()}),
      trackSource: new VectorSource({format: new GeoJSON()}),
      vectorSource: new VectorSource({format: new GeoJSON()}),
      olMap: undefined,
      myMapControls: new Collection([
        new MousePosition({
          coordinateFormat: function (coordinate) {
            const c = toLonLat(coordinate);
            return 'Lon: ' + c[0].toFixed(5) + ' Lat: ' + c[1].toFixed(5)
          },
          className: 'mousePos'
        })
      ]),
      overlay: {},
      trackOnMap: false,
      view: undefined
    }
  },
  methods: {
    createMap() {
      this.createPotUpOverlay()
      this.createClusterLayer()
      this.olMap = new olmap.Map({
        target: this.$refs['map'],
        layers: [
          this.createOSMlayer(),
          this.createGeofencesLayer(),
          this.clusters,
          this.createTrackLayer()
        ],
        overlays: [this.overlay],
        view: this.view,
        controls: this.myMapControls
      })
      this.olMap.on('singleclick', evt => this.mapClickCallback(evt));
      this.maploaded = true
    },
    createClusterLayer() {
      const styleCache = {};
      this.clusterSource = new Cluster({
        distance: 40,
        minDistance: 20,
        source: this.source,
        geometryFunction: function (feature) {
          return feature.getGeometry();
        }
      });
      this.clusters = new VectorLayer({
        source: this.clusterSource,
        style: function (feature) {
          const size = feature.get('features').length;
          if (size === 0) {
            return
          }
          let style
          let fts = feature.get('features')
          const now = new Date().getTime()
          if (size > 1) {
            let stroke = new Stroke({
              color: 'rgba(51,136,232,0.45)',
              width: 1
            })
            fts.forEach((ft) => {
              const data = ft.get('data')
              const was = data.Timestamp * 1000
              if ((now - was) > 172800000) {
                stroke = new Stroke({
                  color: "rgb(130,131,131)",
                  width: 2
                })
                fts = []
              }
            })
            if (size > 1 && size <= 10) {
              style = styleCache[size];
              if (!style) {
                style = new Style({
                  image: new CircleStyle({
                    radius: 10,
                    stroke: stroke,
                    fill: new Fill({
                      color: 'rgb(51,136,232)',
                    }),
                  }),
                  text: new Text({
                    text: size.toString(),
                    fill: new Fill({
                      color: '#ffffff',
                    }),
                    justify: 'center',
                    padding: [2, 2, 2, 2]
                  }),
                });
                styleCache[size] = style;
              }
            }
            if (size > 10 && size <= 30) {
              style = styleCache[size];
              if (!style) {
                style = new Style({
                  image: new CircleStyle({
                    radius: 12,
                    stroke: stroke,
                    fill: new Fill({
                      color: 'rgb(34,77,248)',
                    }),
                  }),
                  text: new Text({
                    text: size.toString(),
                    fill: new Fill({
                      color: '#f3efef',
                    }),
                    justify: 'center',
                    padding: [2, 2, 2, 2]
                  }),
                });
                styleCache[size] = style;
              }
            }
            if (size > 30 && size <= 50) {
              style = styleCache[size];
              if (!style) {
                style = new Style({
                  image: new CircleStyle({
                    radius: 14,
                    stroke: stroke,
                    fill: new Fill({
                      color: 'rgb(28,73,250)',
                    }),
                  }),
                  text: new Text({
                    text: size.toString(),
                    fill: new Fill({
                      color: '#f6f5f5',
                    }),
                    justify: 'center',
                    padding: [2, 2, 2, 2]
                  }),
                });
                styleCache[size] = style;
              }
            }
            if (size > 50) {
              style = styleCache[size];
              if (!style) {
                style = new Style({
                  image: new CircleStyle({
                    radius: 18,
                    stroke: stroke,
                    fill: new Fill({
                      color: 'rgb(6,53,245)',
                    }),
                  }),
                  text: new Text({
                    text: size.toString(),
                    fill: new Fill({
                      color: '#faf7f7',
                    }),
                    justify: 'center',
                    padding: [2, 2, 2, 2]
                  }),
                });
                styleCache[size] = style;
              }
            }
          } else {
            const originalFeature = feature.get('features')[0];
            const data = originalFeature.get('data')
            const now = new Date().getTime()
            const was = data.Timestamp * 1000
            let pointcolor = '#11d011'
            if ((now - was) > 172800000) {
              pointcolor = '#484747'
            }
            style = [
              new Style({
                image: new Icon({
                  src: '/img/map-marker.png',
                  scale: 0.7,
                  anchor: [0.5, 1],
                  color: pointcolor
                }),
                text: new Text({
                  text: `${originalFeature.get('name')}`,
                  font: 'bold 12px Roboto',
                  fill: new Fill({
                    color: '#414040',
                    background: 'white'
                  }),
                  backgroundFill: new Fill({
                    color: '#fff'
                  }),
                  padding: [2, 2, 2, 2],
                  offsetY: -44
                })
              }),
              new Style({
                image: new CircleStyle({
                  radius: 4,
                  fill: new Fill({
                    color: 'rgba(17,208,17,0)'
                  })
                })
              })
            ]
          }
          return style;
        },
        name: "clusters",
        visible: true
      });
    },
    createTrackLayer() {
      return new VectorLayer({
        source: this.trackSource,
        style: {
          'fill-color': 'rgba(237,245,245,0.5)',
          'stroke-color': '#335cff',
          'stroke-width': 2,
          'circle-radius': 7,
          'circle-fill-color': '#3437fa',
        },
        visible: true,
        name: 'Track'
      })
    },
    createGeofencesLayer() {
      return new VectorLayer({
        source: this.geofenceVectorSource,
        style: {
          'fill-color': 'rgba(237,245,245,0.5)',
          'stroke-color': '#335cff',
          'stroke-width': 2,
          'circle-radius': 7,
          'circle-fill-color': '#3437fa',
        },
        visible: true,
        name: 'Geofences',
      })
    },
    createOSMlayer() {
      return new TileLayer({
        source: new OSM({
          tileLoadFunction: (imageTile, src) => {
            var imgElement = imageTile.getImage();
            const surce = this.tileCashe.get(src);
            if (surce) {
              imgElement.src = surce
            } else {
              imgElement.onload = () => {
                let canvas = document.createElement("canvas");
                canvas.width = imgElement.width;
                canvas.height = imgElement.height;
                let ctx = canvas.getContext("2d");
                ctx.drawImage(imgElement, 0, 0);
                const dataURL = canvas.toDataURL("image/png");
                this.tileCashe.set(src, dataURL)
              }
              imgElement.src = src;
            }
          }
        }),
        name: 'OSM'
      })
    },
    createPotUpOverlay() {
      const closer = document.getElementById('popup-closer');
      this.overlay = new Overlay({
        element: document.getElementById('popup'),
        autoPan: {
          animation: {
            duration: 500,
          },
        },
      });
      closer.onclick = () => {
        this.overlay.setPosition(undefined);
        closer.blur();
        return false;
      };
    },
    mapClickCallback(evt) {
      const content = document.getElementById('popup-content');
      this.clusters.getFeatures(evt.pixel).then((clickedFeatures) => {
        if (clickedFeatures.length) {
          // Get clustered Coordinates
          const features = clickedFeatures[0].get('features');
          if (features.length > 1) {
            const extent = boundingExtent(
                features.map((r) => r.getGeometry().getCoordinates())
            );
            this.olMap.getView().fit(extent, {easing: inAndOut, duration: 1500});
          }
        }
      });
      let fts = []
      const coordinate = evt.coordinate;
      this.olMap.forEachFeatureAtPixel(evt.pixel, function (feature) {
        fts.push(feature);
      });
      if (fts.length > 0) {
        let names = ''
        let descrs = ''
        fts.forEach(ft => {
          const geomName = ft.getGeometry().getType()
          if (geomName !== 'Point' && geomName !== 'LineString') {
            names += `"${ft.get('name')}", `
            let descr = 'Отсутствует'
            if (ft.get('description') !== undefined && ft.get('description') !== "") {
              descr = ft.get('description')
            }
            descrs += `<p><span style="font-weight: bold">${ft.get('name')}</span>:${descr}</p>`
            content.innerHTML = `<h4>Геозоны в данной локации:</h4><p>${names.slice(0, -2)}</p><hr><span style="font-size: 12px">Долгота:${toLonLat(evt.coordinate)[0]}</span><br><span style="font-size: 12px">Широта: ${toLonLat(evt.coordinate)[1]}</span><hr><h4>Подробнее о геозонах</h4>${descrs}`;
            this.overlay.setPosition(coordinate);
            return;
          }
          if (geomName === 'Point' && ft.get('name') === "Точка трека") {
            const data = ft.get('data')
            const containerName = ft.get('containerName')
            let cont = `
            <h4>Контейнер: ${containerName}</h4>
            <p>Время: ${new Date(data.Timestamp * 1000).toLocaleString()}</p>
            <p>Широта: ${data.Latitude.toFixed(6)}</p>
            <p>Долгота: ${data.Longitude.toFixed(6)}</p>
            <p>Скорость: ${data.Speed} км/ч</p>
            <p>Кол-во спутников: ${data.Satellites}</p>
            <p>АКБ: ${data.Battery} %</p>
`
            content.innerHTML = `
                    <h4>Информация о позиции</h4>${cont}

               `
            this.overlay.setPosition(coordinate);
            fts.length = 0
            return
          }
          if (geomName === 'Point' && ft.get('features').length === 1 && ft.get('features')[0].get('data') !== undefined) {
            const data = ft.get('features')[0].get('data')
            const devtype = ft.get('features')[0].get('type')
            let icon = ''
            if (devtype === 'maximaTracker') {
              icon = '<i class="mdi-alpha-m-circle mdi v-icon v-icon--size-default"></i>'
            }
            if (devtype === 'chinaTracker') {
              icon = '<i class="mdi-alpha-c-circle mdi v-icon v-icon--size-default"></i>'
            }
            let cont = `
            <h4>Контейнер: ${ft.get('features')[0].get('name')} ${icon}</h4>
            <caption>Устройство: ${data.Device}</caption><br>
            <p>Время: ${new Date(data.Timestamp * 1000).toLocaleString()}</p>
            <p>Широта: ${data.Latitude.toFixed(6)}</p>
            <p>Долгота: ${data.Longitude.toFixed(6)}</p>
            <p>Скорость: ${data.Speed} км/ч</p>
            <p>Кол-во спутников: ${data.Satellites}</p>
            <p>АКБ: ${data.Battery} %</p>
            <p>Температура: ${data.Temperature} &deg;C</p>`
            content.innerHTML = `<h4>Информация о позиции</h4>${cont}
            <button id="getTrackBotton" type="button" style="margin: 4px" class="v-btn v-btn--elevated v-theme--light v-btn--density-default v-btn--size-default v-btn--variant-elevated search-btn">
            <span class="v-btn__overlay"></span>
            <span class="v-btn__underlay"></span>
            <span class="v-btn__prepend">
            <i class="mdi-go-kart-track mdi v-icon notranslate v-theme--light v-icon--size-default" aria-hidden="true"></i>
            </span>
            <span class="v-btn__content">Построить трек</span>
            </button>
`
            this.trackquery.trackName = ft.get('features')[0].get('name')
            this.trackquery.uuid = data.uuid
            let btn = document.getElementById('getTrackBotton')
            btn.onclick = () => {
              let closer = document.getElementById('popup-closer');
              this.overlay.setPosition(undefined);
              closer.blur();
              btn.remove()
              this.trackquery.from = undefined
              this.trackquery.to = undefined
              this.modals.track = true
            }
            this.overlay.setPosition(coordinate);
            fts.length = 0
          }
        })
      }
    },
    updateMap() {
      this.olMap.updateSize()
    },
    setGeoCatVisible() {
      const pl = []
      this.renderedCats.forEach(cat => {
        pl.push(cat)
      })
      this.$store.dispatch('options', {name: 'geoCategories', value: pl})
    },
    showCar(node) {
      node.visible = true
      this.mapObjects.toAdd.set(node.identificator, node.uuid)
      this.updateMapObjects()
      this.getLastData(node.uuid)
    }, hideCar(node) {
      node.visible = false
      this.mapObjects.toDelete.set(node.identificator, node.uuid)
      this.updateMapObjects()
    },
    deleteMultipleCarsFromMap() {
      this.source.forEachFeature(ft => {
        if (this.mapObjects.toDelete.has(ft.get('data').Device)) {
          this.source.removeFeature(ft)
        }
      })
      this.mapObjects.toDelete.clear()
    },
    visibleFeature(node) {
      if (node.type === 'folder') {
        this.folderCheck(node)
      }
      if (node.type === 'car') {
        if (node.visible) {
          //this.hideCar(node)
          this.mapObjects.toDelete.set(node.identificator, node.uuid)
        } else {
          //this.showCar(node)
          this.mapObjects.toAdd.set(node.identificator, node.uuid)
        }
        node.visible = !node.visible
      }
      this.updateMapObjects()
    },
    folderCheck(node) {
      //если галочка стояла, то надо убрать ее и все галочки внутри
      if (node.visible) {
        //выключим все внутри ноды
        this.switchInFolder(node, false)
        // удалим все с карты
      } else {
        //включим все ноды внутри
        this.switchInFolder(node, true)
        //запишем результат
      }

    },
    switchInFolder(node, bool) {
      if (bool) {
        //если папка, то смотрим внутрь рекурсивно
        if (node.type === "folder") {
          if (!node.visible) {
            node.visible = true
          }
          node.childrens.forEach(child => {
            this.switchInFolder(child, bool)
          })
          // если контейнер
        } else {
          if (!node.visible) {
            node.visible = true
            this.mapObjects.toAdd.set(node.identificator, node.uuid)
          }
        }
      } else {
        //если папка, то смотрим внутрь рекурсивно
        if (node.type === "folder") {
          if (node.visible) {
            node.visible = false
          }
          node.childrens.forEach(child => {
            this.switchInFolder(child, bool)
          })
          // если контейнер
        } else {
          if (node.visible) {
            node.visible = false
            this.mapObjects.toDelete.set(node.identificator, node.uuid)
          }
        }
      }
    },
    switchCarInFolder(node, bool, uuid) {
      //если папка, то смотрим внутрь рекурсивно
      if (node.type === "folder") {
        node.childrens.forEach(child => {
          this.switchCarInFolder(child, bool, uuid)
        })
        // если контейнер
      } else {
        if (node.uuid === uuid) {
          node.visible = bool
        }
      }
    },
    updateMapObjects() {
      if (this.mapObjects.toAdd.size > 0) {
        this.getMultipleData()
      }
      if (this.mapObjects.toDelete.size > 0) {
        this.deleteMultipleCarsFromMap()
      }
    },
    getMultipleData() {
      const pl = {
        trackers: [...this.mapObjects.toAdd.keys()]
      }
      this.loading = true
      this.$store.dispatch('data/getMultipleLastData', pl)
          .then(resp => this.addMultipleOnMap(resp))
          .catch(err => {
            console.log(err)
          }).finally(() => {
        this.mapObjects.toAdd.clear()
        this.loading = false
      })
    },
    addMultipleOnMap(resp) {
      let fts = []
      // настройка отображения на карте контейнеров о старыми данными
      let barrierTime =Math.floor( ((new Date()).getTime() / 1000) - (60 * 60 * 24 * 30 * this.globalSettings.mapVisibleObjectsMaxTime))
      console.log("barrierTime: ",barrierTime)
      resp.data.forEach(pos => {
        if (pos.Timestamp < barrierTime) {
          return
        }
        let ftname = ""
        let uuid
        let type = ""
        let devId = ""
        this.devices.forEach(dev => {
          if (dev.current_dev.identificator === pos.Device) {
            ftname = dev.name
            uuid = dev.uuid
            type = pos.DeviceType
            devId = pos.Device
          }
        })
        const ft = new Feature({
          geometry: new Point(fromLonLat([pos.Longitude, pos.Latitude])),
          name: ftname,
          type: type,

          data: {
            Device: devId,
            Timestamp: pos.Timestamp,
            Longitude: pos.Longitude,
            Latitude: pos.Latitude,
            Speed: pos.Speed,
            Satellites: pos.Satellites,
            Battery: pos.Battery,
            uuid: uuid,
            Temperature: pos.Temperature ? pos.Temperature : 'нет данных'
          }
        })
        fts.push(ft)
      })
      this.source.addFeatures(fts)
      this.loading = false
    },
    getLastData: function (uuid) {
      this.dataloadeded.lastdata = false
      this.loading = true
      this.$store.dispatch('data/getLastData', uuid)
          .then((r) => {
            this.view.animate({zoom: 4}, {center: fromLonLat([r.data.Loc.Longitude, r.data.Loc.Latitude])}, {zoom: 20}, {duration: 5000}, {easing: inAndOut})
            this.dataloadeded.lastdata = true
            this.loading = false
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
    },
    gettrack: function () {
      if (this.trackquery.from !== '' && this.trackquery.to !== '') {
        this.loading = true
        const from = new Date(this.trackquery.from)
        const to = new Date(this.trackquery.to)
        const device = this.trackquery.uuid
        const payload = {
          device: device,
          from: from.getTime() / 1000,
          to: to.getTime() / 1000
        }
        this.$store.dispatch('data/getDataTrack', payload)
            .then(data => {
              this.addTrackOnMap(data)
              this.loading = false
            })
            .catch(err => {
              console.log(err)
              this.loading = false
            })
      }
    },
    addTrackOnMap: function (data) {
      if (data.data.length > 1) {
        this.trackSource.clear()
        let coords = []
        let fts = []
        data.data.forEach(co => {
          const lonlat = fromLonLat([co.Longitude, co.Latitude])
          coords.push(lonlat)
          let ft = new Feature({
            geometry: new Point(lonlat),
            name: 'Точка трека',
            containerName: this.trackquery.trackName,
            data: {
              Speed: co.GroundSpeed,
              Latitude: co.Latitude,
              Longitude: co.Longitude,
              Satellites: co.Satellites,
              Timestamp: co.Timestamp,
              Battery: co.battery
            }
          })
          ft.setStyle(new Style({
            image: new CircleStyle({
              radius: 15,
              fill: new Fill({
                color: 'rgba(248,249,252,0)'
              })
            })
          }))
          fts.push(ft)
        })
        const trackLine = new Feature({
          geometry: new LineString(coords)
        })
        trackLine.setStyle(this.setTrackStyles)
        this.trackSource.addFeature(trackLine)
        this.trackSource.getFeatures().forEach(ft => {
          const geom = ft.getGeometry()
          this.olMap.getView().fit(geom, {easing: inAndOut, duration: 3000})
          this.trackOnMap = true
        })
        fts.forEach(ft => {
          this.trackSource.addFeature(ft)
        })
      }
    },
    setTrackStyles: function (feature) {
      const geometry = feature.getGeometry();
      const styles = [
        // linestring
        new Style({
          stroke: new Stroke({
            color: '#1b26f1',
            width: 2
          }),
          text: new Text({
            text: `Маршрут следования контейнера ${this.trackquery.trackName}`,
            font: 'bold 12px Roboto',
            fill: new Fill({
              color: '#2f2f2f'
            }),
            placement: 'line',
            offsetY: -12
          })
        }),
      ];
      geometry.forEachSegment(function (start, end) {
        const dx = end[0] - start[0];
        const dy = end[1] - start[1];
        const rotation = Math.atan2(dy, dx);
        // arrows
        styles.push(
            new Style({
              geometry: new Point(end),
              image: new Icon({
                src: '/img/navigation.png',
                anchor: [0.75, 0.5],
                scale: [0.75, 0.5],
                rotateWithView: true,
                rotation: -rotation,
                color: "#b0208e"
              }),
            })
        );
      });
      return styles;
    },
    setCurrentObject: function (obj) {
      this.currentObj = obj
      if (obj.type === 'car') {
        this.trackquery.trackName = obj.label
        this.trackquery.uuid = obj.uuid
      }
    },
    showNode: function (node) {
      if (node.type === 'folder') {
        node.show = !node.show
      }
      if (node.type === 'car') {
        if (node.visible) {
          this.hideCar(node)
        }
        if (!node.visible) {
          this.showCar(node)
        }
      }
    },
  },
  computed: {
    trackerTypes() {
      return this.$store.getters['trackers/trackerTypes']
    },
    drawner() {
      return this.$store.getters['app/navDrawner']
    },
    devgroups: function () {
      return this.$store.getters['devices/devgroups']
    },
    devices: function () {
      return this.$store.getters["devices/devices"]
    },
    treeComp() {
      return this.$store.getters['devices/devNodes']
    },
    user() {
      return this.$store.getters['user/user']
    },
    role() {
      return this.$store.getters['user/role']
    },
    geofences() {
      return this.$store.getters['geofences/geofences']
    },
    geofenceVectorSource() {
      return this.$store.getters['geofences/geofenceVectorSource']
    },
    globalSettings: function () {
      return this.$store.getters['app/globalSettings']
    },
    loading: {
      set: function (newval) {
        this.$store.dispatch('app/loading', newval)
      },
      get: function () {
        return this.$store.getters['app/loader']
      }
    },
    renderedCats() {
      return this.$store.getters['options/renderedCats']
    },
    searchElems() {
      let elems = []
      if (!this.search || this.search.length < 2) {
        return elems
      }
      this.geofences.forEach(geo => {
        elems.push({
          uuid: geo.uuid,
          name: geo.name,
          type: 'Геозона'
        })
      })
      this.devices.forEach(dev => {
        elems.push({
          id: dev.current_dev.identificator,
          uuid: dev.uuid,
          name: dev.name + '-' + dev.current_dev.identificator,
          type: 'Контейнер'
        })
      })
      return elems
    }
  },
  created() {
    this.$store.dispatch('devices/recreateDevTree')
    this.view = new View({
      zoom: 9,
      center: fromLonLat([37.55213985518653, 55.7449717807913]),
      constrainResolution: true,
    })
    this.loaded = true
  },
  mounted() {
    this.createMap()
  },
  watch: {

    'currentObj'(node) {
      if (node.type === 'car') {
        if(!node.visible){
          this.showCar(node)
        }else{
          this.getLastData(node.uuid)
        }
      }
    },

    'selected': {
      handler(newval) {
        if (!newval) {
          return
        }
        switch (newval.type) {
          case 'Геозона': {
            if (!this.olMap.getAllLayers()[1].values_.visible) {
              this.olMap.getAllLayers()[1].values_.visible = true
            }
            this.geofenceVectorSource.getFeatures().forEach(ft => {
              if (ft.values_.uuid === newval.uuid) {
                console.log('ft.values_.uuid===newval.uuid')
                this.olMap.getView().fit(ft.getGeometry(), {easing: inAndOut, duration: 3000});
              }
            })
            break
          }
          case 'Контейнер': {
            let findedcoords = undefined
            this.source.forEachFeature(ft => {
              const data = ft.get('data')
              if (data.Device === newval.id) {
                findedcoords = ft.getGeometry().getCoordinates()
              }
            })
            if (findedcoords) {
              this.view.animate({zoom: 4}, {center: findedcoords}, {zoom: 20}, {duration: 5000}, {easing: inAndOut})
            } else {
              this.switchCarInFolder(this.treeComp, true, newval.uuid)
              this.showCar({identificator: newval.id, uuid: newval.uuid})
            }
            break
          }
          default: {
            break
          }
        }
      },
      deep: true
    },
    'drawner'() {
      setTimeout(this.updateMap, 250)
    }
  }
}
</script>

<style scoped>
.track-btn {
  position: absolute;
  top: .5rem;
  left: .5rem;
  z-index: 20;
  text-transform: none;
  font-weight: bold;
  font-size: 14px;
}

.search-item-subtitle {
  font-style: italic;
  font-size: 12px;
  color: #2f2e2e;
}

.search-btn {
  position: absolute;
  top: 1rem;
  right: .5rem;
  z-index: 20;
}

.search-area {
  position: absolute;
  top: 1rem;
  right: 4.5rem;
  z-index: 20;
  width: 18rem;
  background: rgba(255, 255, 255, 1);
}

.v-icon:hover {
  background: #d9dada;
  border-radius: 4px;
}

.geomodal tbody tr {
  border-bottom: 1px solid gray;
  font-size: 12px;
}

.geomodal thead tr {
  border-bottom: 2px solid gray;

}

.geomodal tbody tr:hover {
  background: #00C3DA;
  transition: 1s;
}

.v-table td {
  height: auto !important;
  padding: 0 8px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  font-size: 12px !important;
}

.devinform {
  background: white;
  overflow: auto;
  position: absolute;
  top: 6rem;
  right: 16px;
  width: 320px;
  height: auto;
  max-height: 70%;
  z-index: 100;
  opacity: 0.9;
  border-radius: 4px;
}
</style>
