
import login from '@/views/login.vue'
import Containers from "@/views/HomePage.vue";
import users from "@/views/users";
import roles from "@/views/roles";
import containers from "@/views/containersPage.vue";
import summaryReport from "@/views/reports/ReportSummary";
import geofencesReport from "@/views/reports/ReportGeofences";
import incidentsReport from "@/views/reports/ReportIncidents";
import superGeoBoard from "@/views/boards/SuperGeoBoard";
import monitoring from "@/views/boards/Monitoring";
import audit from "@/views/audit.vue";
import reportBilling from "@/views/reports/ReportBilling.vue";
import geoCategories from "@/views/geoCategories.vue";
import geofences from "@/views/geofences.vue";
import downtimeReport from "@/views/boards/DowntimeReport.vue";
import DowntimeMap from "@/views/boards/Downtime-Map.vue";
import taskManager from "@/views/TaskManager.vue";
import trackers from "@/views/trackers.vue";
import reportTechMonitoring from "@/views/reports/ReportTechMonitoring.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: Containers,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users',
    name: 'users',
    component: users,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/roles',
    name: 'roles',
    component: roles,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/categories',
    name: 'geocats',
    component: geoCategories,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/tasks',
    name: 'taskmanager',
    component: taskManager,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/containers',
    name: 'containers',
    component: containers,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/trackers',
    name: 'trackers',
    component: trackers,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/geo',
    name: 'geofences',
    component: geofences,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/summary',
    name: 'sunmmary_report',
    component: summaryReport,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/downtime',
    name: 'DownTime',
    component: downtimeReport,
    meta: {
      requiresAuth: true
    }
  },{
    path: '/downtime-map',
    name: 'DownTimeMap',
    component: DowntimeMap,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/geofences',
    name: 'geofences_report',
    component: geofencesReport,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/billing',
    name: 'billing_report',
    component: reportBilling,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/monitoring',
    name: 'operatove_monitoring',
    component: monitoring,
    meta: {
      requiresAuth: true
    }
  },{
    path: '/techmon',
    name: 'tech_monitoring',
    component: reportTechMonitoring,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/incidents',
    name: 'incidents_report',
    component: incidentsReport,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/geocount',
    name: 'superGeoBoard',
    component: superGeoBoard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/audit',
    name: 'Audit',
    component: audit
  }
]

export default routes
