<template>
<v-container>
  <v-col>
    <v-card-title>
      <v-row justify="space-between">
        <v-col cols="10">
          Биллинговый отчет
        </v-col>
        <v-col cols="1" align-self="auto">
          <v-row style="padding: 1rem" justify="end">
            <v-menu
                v-if="
                role.issu
                ||
                user.login==='transcontainer'
                ||
                user.login==='borisovbb@trcont.ru'
                ||
                user.login==='biriukovav'
                ||
                 user.login==='gumerov.b'
                 ||
                 user.login==='d.afanasyev'
">
              <template
                  v-slot:activator="{props}"
              >
                <v-btn
                    v-bind="props"
                    icon="mdi-file-chart-outline"
                    color="success"
                    title="Сравнительные Отчеты"
                >
                </v-btn>
              </template>
              <v-list max-height="300">
                <v-list-item
                    v-for="file in staticFiles"
                    :key="file"
                    :title="file"
                    @click="downloadStaticFile(file)"
                >
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu v-if="repsFromDb && repsFromDb.Items?.length">
              <template
                  v-slot:activator="{props}"
              >
                <v-btn
                    v-bind="props"
                    icon="mdi-file-chart-outline"
                    color="blue"
                    title="Автоматические отчеты"
                >
                </v-btn>
              </template>
              <v-list max-height="300">
                <v-list-item
                    v-for="(rep,i) in repsFromDb.Items"
                    :key="i"
                    @click="getDataFromDb(rep)"
                    :title="getGroupName(rep.GroupId)+' '+getMonthNameFromInt(rep.Month)+' '+rep.Year+' '+'года'"
                >
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu transition="slide-x-transition" v-if="response">
              <template v-slot:activator="{ props }">
                <v-btn
                    icon="mdi-download"
                    color="success"
                    v-bind="props"
                    style="margin-left: 1rem"
                >
                </v-btn>
              </template>
              <v-list>
                <v-list-subheader>Формат выгрузки</v-list-subheader>
                <v-list-item
                    @click="downloadReport"
                >
                  <template v-slot:prepend>
                    <v-icon size="32px" color="#185C11" icon="mdi-microsoft-excel"></v-icon>
                  </template>
                  <v-list-item-title>XLSX</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-subtitle>
      выберите интересующий вас период
    </v-card-subtitle>
    <!--QUERY-->
    <v-card-item>
      <v-card-text>
        <v-form>
          <v-col cols="12">
            <v-row>
              <v-col cols="2">
                <v-text-field
                    type="number"
                    min=2022
                    max=2099
                    label="Год"
                    variant="underlined"
                    v-model="year"
                    v-on:change="response=undefined;diffMode=false;response2=undefined"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-select
                    variant="underlined"
                    label="Месяц"
                    v-model="month"
                    v-on:update:modelValue="response=undefined;diffMode=false;response2=undefined"
                    :items="[
                        {name:'Январь',id:1},
                        {name:'Февраль',id:2},
                        {name:'Март',id:3},
                        {name:'Апрель',id:4},
                        {name:'Май',id:5},
                        {name:'Июнь',id:6},
                        {name:'Июль',id:7},
                        {name:'Август',id:8},
                        {name:'Сентябрь',id:9},
                        {name:'Октябрь',id:10},
                        {name:'Ноябрь',id:11},
                        {name:'Декабрь',id:12},
                    ]"
                    item-value="id"
                    item-title="name"
                >
                </v-select>
              </v-col>
              <v-col cols="2">
                <v-select
                    variant="underlined"
                    label="Группа Контейнеров"
                    v-model="devgroup"
                    v-on:update:modelValue="response=undefined;diffMode=false;response2=undefined"
                    :items="devgroups"
                    item-value="id"
                    item-title="name"
                >
                </v-select>
              </v-col>
              <v-col cols="4">
                  <v-btn
                      variant="flat"
                      color="success"
                      @click="getData"
                  >
                    Сформировать
                    <v-badge
                        :content="difference"
                        floating
                        offset-y="-15"
                        offset-x="5"
                        max="99"
                        v-if="haveDifference"
                        color="orange"
                        :title="difference+' Новых дней'"
                    >
                    </v-badge>
                  </v-btn>
                <v-btn
                    style="margin-left: 1rem"
                    @click="calcDiff()"
                    v-if="haveDifference"
                    color="orange"
                    title="Показать подробности"
                >Показать</v-btn>
              </v-col>
              <v-col cols="2" v-if="role.issu && !diffMode">
                <v-switch
                    v-model="details"
                    label="Показать подробности"
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-col>
        </v-form>
      </v-card-text>
    </v-card-item>
    <!--REPORT-->
    <template
        v-if="response && !diffMode"
    >
      <v-card-title>
        Всего объектов: {{response.ALLitemsCount}}
      </v-card-title>
      <v-card-text>
        <p>Период отчета: <span class="results">{{getMonthNameFromInt(response.Month)}} {{response.Year}} года </span></p>
        <p>Совокупный итог рабочих дней по всем устройствам за период:  <span class="results">{{response.DaysOfWork}}</span></p>
        <v-data-table
            fixed-header
            fixed-footer
            :headers="headers"
            :items="items"
            :items-per-page="itemsPerPage"
            :height="dataheight"
        >
          <template v-slot:item="{ item }">
            <tr>
              <template
                  v-for="(value,name) in item.columns"
                  :key="name"
              >
                <td v-if="name==='DeviceID'||name==='ContainerName'">
                  {{value}}
                </td>
                <template v-else>
                  <td v-if="!details" style="text-align: start">
                    <v-icon
                        :color="value.Count?'success':'error'"
                        :icon="value.Count?'mdi-check-circle':'mdi-alert-circle'"
                    ></v-icon>
                  </td>
                  <td v-else style="text-align: start">
                    <v-menu transition="slide-x-transition">
                      <template v-slot:activator="{ props }">
                        <v-btn
                            v-bind="props"
                            :color="value.Count?'success':'error'"
                        >
                          {{value.Count}}
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-subheader>Подробности</v-list-subheader>
                        <template v-for="(detail,detname,index) in value.Details" v-bind:key="index">
                          <v-list-item>
                            <v-list-item-title>{{ detailsMap.get(detname) }}:{{detail}}</v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-menu>
                  </td>
                </template>

              </template>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </template>
    <!--DIFF MODE-->
    <template v-if="diffMode && diffItems">
        <v-card-title>
          Результат сравнения автоматического Биллингового отчета с текущей ситуацией
        </v-card-title>
        <v-card-text>
          <p>Период отчета: <span class="results">{{getMonthNameFromInt(response.Month)}} {{response.Year}} года </span></p>
          <p>На текущий момент зафиксировано {{difference}} новых рабочих дней за период</p>
          <v-table
              fixed-header
              :height="diffheight"
              v-if="diffItems"
          >
            <thead>
            <tr>
              <td>Контейнер</td>
              <td>Устройство</td>
              <td>Новых дней</td>
              <td v-for="day in dates"
                  :key="day"
              >{{day}}</td>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="item in diffItems"
                :key="item"
            >
              <td>{{item.container}}</td>
              <td>{{item.device}}</td>
              <td>{{item.days}}</td>
              <td
                  v-for="day in item.items"
                  :key="day"
              >
                <v-icon
                    icon="mdi-new-box"
                    v-if="day.newinform"
                    color="success"
                    size="large"
                    :title="day.count+' шт новых сообщений'"
                >
                </v-icon>
                <v-icon
                    v-else
                    icon="mdi-cancel"
                    size="large"
                >
                </v-icon>
              </td>
            </tr>
            </tbody>
          </v-table>
        </v-card-text>

    </template>
  </v-col>
</v-container>
</template>

<script>

let XLSX = require("xlsx");

export default {
  name: "ReportBilling",
  data(){
    return{
      repsFromDb:undefined,
      year:new Date().getFullYear(),
      month:new Date().getMonth()+1,
      devgroup:undefined,
      response:undefined,
      response2:undefined, //for calc difference
      diffMode:false,
      staticFiles:[],
      itemsPerPage:5,
      staticHeaders:[
        {
          title: 'Контейнер',
          key: 'name',
          align: 'end',
          width:'12%'
        },
        {
          title: 'Устройство',
          key: 'type',
          align: 'end',
          width:'12%'
        },
      ],
      dates:[],
      detailsMap:new Map([
        ["Coords", "Координаты"],
        ["Battery", "АКБ"],
        ["Acceleration", "Удар"],
        ["LBS", "LBS"],
        ["Opening", "Вскрытие"],
        ["Separation", "Отрыв"],
        ["Temperature", "Температура"],
        ["Twist", "Переворот"],
      ]),
      details:false
    }
  },
  methods:{
    downloadStaticFile(name){
      this.$http({
        url: `/api/storage?files=${name}`,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    },
    calcDiff(){
      this.diffMode=true
      this.loading=true
      this.response2=undefined
      this.$store.dispatch('reports/getSelectedBilling',{year:Number(this.year),month:Number(this.month),group:Number(this.devgroup)})
          .then(response=>{
            this.dates=[]
            response.data.DaysArray.forEach(item=>{
              this.dates.push(item)
            })
            this.dates.sort(function (a, b){
                  return new Date(a) - new Date(b);
                }
            )
            this.response2=response.data
          })
          .catch(e=>{
            alert(e)
          })
          .finally(()=>{
            this.loading=false
          })
    },
    getData(){
      this.diffMode=false
      this.response2=undefined
      this.loading=true
      console.log("this.devgroup: ",this.devgroup)
      this.$store.dispatch('reports/getBilling',{year:Number(this.year),month:Number(this.month),group:Number(this.devgroup)})
          .then(response=>{
            this.dates=[]
            this.response=response.data
            this.response.DaysArray.forEach(item=>{
              this.dates.push(item)
            })
            this.dates.sort(function (a, b){
                  return new Date(a) - new Date(b);
            }
            )
          })
          .catch(e=>{
            alert(e.response.data)
          })
          .finally(()=>{
            this.loading=false
          })
    },
    getGroupName(GroupId){
      let res=GroupId
      this.devgroups.forEach(group=>{
        if(group.id===GroupId){
          res=group.name
        }
      })
      return res
    },
    getDataFromDb(request){
      this.diffMode=false
      this.response2=undefined
      this.loading=true
      this.$store.dispatch('reports/getSelectedBilling',{year:request.Year,month:request.Month,group:request.GroupId})
          .then(response=>{
            this.dates=[]
            this.response=response.data
            this.response.DaysArray.forEach(item=>{
              this.dates.push(item)
            })
            this.dates.sort(function (a, b){
                  return new Date(a) - new Date(b);
            }
            )
            //console.log(this.dates)
          })
          .catch(e=>{
            alert(e.response.data)
          })
          .finally(()=>{
            this.loading=false
          })
    },
    getRepsFromDb(){
      this.$store.dispatch('reports/getBillingsFromDb')
          .then(r=>{
            this.repsFromDb=r.data
          })
          .catch(e=>{
        console.log(e)
      })
    },
    getFileList(){
      this.$store.dispatch('reports/getBillingsFileList')
          .then(r=>{
            this.staticFiles=[]
            r.data.forEach(fileName=>{
              this.staticFiles.push(fileName)
            })
          })
          .catch(e=>{
            console.log(e)
          })
    },
    downloadReport(){
      if(this.diffMode){
        this.createDiff()
      }else {
        this.createXLSX()
      }
    },
    createXLSX(){
      let wb=XLSX.utils.book_new()
      let data=[
        ["Биллинговый Отчет"],
        ["Период отчета:", this.getMonthNameFromInt(this.response.Month)+' '+ this.response.Year +' года' ],
        [],
        ["Всего устройств:",this.response.ALLitemsCount],
        ["Совокупный итог рабочих дней за период по всем устройствам:",this.response.DaysOfWork],
        [],
        ['Устройство','Контейнер']
      ]
      this.dates.forEach(day=>{
        data[6].push(day)
      })
      let worksheet = XLSX.utils.aoa_to_sheet(data);
      for (let key of Object.keys(this.response.Items)){
        //console.log(key)
          let itemData=[
            this.response.Items[key].DeviceID,
            this.response.Items[key].ContainerName
          ]
          for(let day of Object.keys(this.response.Items[key].Days)){
            if(this.response.Items[key].Days[day].Count){
              itemData.push('+')
            }else{
              itemData.push('-')
            }
          }
          //('itemData',itemData)
          XLSX.utils.sheet_add_aoa(worksheet, [itemData], {origin:-1});
      }
      XLSX.utils.book_append_sheet(wb, worksheet, "Отчет");
      XLSX.writeFile(wb, 'Биллинговый Отчет.xlsx');
    },
    createDiff(){
      let wb=XLSX.utils.book_new()
      let dw=0
      this.repsFromDb.Items.forEach(item=>{
        if(item.Year===this.response.Year && item.Month===this.response.Month){
          dw=item.DaysOfWork
        }
      })
      let data=[
        ["Сравнительный отчет"],
        ["Период отчета:", this.getMonthNameFromInt(this.response.Month)+' '+ this.response.Year +' года' ],
        ["Количество дней в автоматическом отчете:",dw],
        ["Количество дней в текущем отчете:",this.response.DaysOfWork],
        ["Разница:",this.difference],
        [],
        ['Контейнер','Устройство','Кол-во новых дней']
      ]
      this.dates.forEach(day=>{
        data[6].push(day)
      })
      let worksheet = XLSX.utils.aoa_to_sheet(data);
      for (let item in this.diffItems){
          let itemData=[
            this.diffItems[item].container,
            this.diffItems[item].device,
            this.diffItems[item].days,
          ]
          for(let day of Object.keys(this.diffItems[item].items)){
            if(this.diffItems[item].items[day].newinform){
              itemData.push('+')
            }else{
              itemData.push('-')
            }
          }
          XLSX.utils.sheet_add_aoa(worksheet, [itemData], {origin:-1});
      }
      XLSX.utils.book_append_sheet(wb, worksheet, "Отчет");
      XLSX.writeFile(wb, 'Сравнительный Отчет.xlsx');
    },
    getMonthNameFromInt(int){
      switch (int){
        case 1: {
          return "Январь"
        }
        case 2: {
          return "Февраль"
        }
        case 3: {
          return "Март"
        }
        case 4: {
          return "Апрель"
        }
        case 5: {
          return "Май"
        }
        case 6: {
          return "Июнь"
        }
        case 7: {
          return "Июль"
        }
        case 8: {
          return "Август"
        }
        case 9: {
          return "Сентябрь"
        }
        case 10: {
          return "Октябрь"
        }
        case 11: {
          return "Ноябрь"
        }
        case 12: {
          return "Декабрь"
        }
        default: {
          return undefined
        }
      }
    }
  },
  computed:{
    devgroups(){
      return this.$store.getters['devices/devgroups']
    },
    dataheight(){
      return window.innerHeight-530
    },
    diffheight(){
      return window.innerHeight-460
    },
    diffItems(){
      let res=[]
      if(!this.response2){
        return res
      }
      for (let dbItem in this.response2.Items){
        let add=false
        let device=this.response2.Items[dbItem].DeviceID
        let name=this.response2.Items[dbItem].ContainerName
        let diffItem={}
        let newdays=0
        for(let lastItem in this.response.Items){
          if(dbItem+'-'+name===lastItem || dbItem===lastItem){
            this.dates.forEach(day=>{
              diffItem[day]={newinform:false,count:0,newdays:0}
              if(!this.response2.Items[dbItem].Days[day].Count && this.response.Items[lastItem].Days[day].Count){
                add=true
                diffItem[day].newinform=true
                newdays+=1
                diffItem[day].count=this.response.Items[lastItem].Days[day].Count
              }
            })
          }
        }
        if(add){
          res.push({
            container:name,
            device:device,
            items:diffItem,
            days:newdays
          })
        }
      }
      return res
    },
    canCompare(){
      let res=false
      if(this.repsFromDb){
        this.repsFromDb.Items.forEach(item=>{
          if(item.Year===Number(this.year) && item.Month===Number(this.month) && item.GroupId===Number(this.devgroup)){
            res=true
          }
        })
      }
      return res
    },
    haveDifference(){
      let res=false
      let dw=undefined
      if(!this.repsFromDb){
        return res
      }
      if(!this.response){
        return res
      }
      console.log(this.response)
      this.repsFromDb.Items.forEach(item=>{
        if(item.Year===this.response.Year && item.Month===this.response.Month && item.GroupId===this.response.GroupId){
          dw=item.DaysOfWork
        }
      })
      if(!dw){
        return res
      }
      if (this.canCompare){
        res=this.response.DaysOfWork!==dw
      }
      return res
    },
    difference(){
      let diff=0
      if (this.haveDifference){
        this.repsFromDb.Items.forEach(resp=>{
          if(resp.Year===Number(this.year) && resp.Month===Number(this.month)){
            diff=Number(this.response.DaysOfWork)-Number(resp.DaysOfWork)
          }
        })
      }
      return diff
    },
    role(){
      return this.$store.getters['user/role']
    },
    user(){
      return this.$store.getters['user/user']
    },
    loading:{
      set: function (newval) {
        this.$store.dispatch('app/loading',newval)
      },
      get: function (){
        return this.$store.getters['app/loader']
      }
    },
    headers(){
      let res=[{
        title: 'Контейнер',
        key: 'ContainerName',
        align: 'start',
      },
        {
          title: 'Устройство',
          key: 'DeviceID',
          align: 'start',
        }]
      if (this?.dates){
        this.dates.forEach(day=>{
          res.push({
            title: day,
            align: 'start',
            key:day,
            sortable:false
          })
        })
      }
      return res
    },
    items(){
      let items=[]
      if (this.response?.Items){
        for (let item in this.response.Items){
          let it={}
          it.ContainerName=this.response.Items[item].ContainerName
          it.DeviceID=this.response.Items[item].DeviceID
          for (let day in this.response.Items[item].Days){
            it[day]=this.response.Items[item].Days[day]
          }
          items.push(it)
        }
      }
      return items
    }
  },
  created() {
    this.getRepsFromDb()
    this.getFileList()
  }
}
</script>

<style scoped>
.active{
  background: rgba(41, 225, 41, 0.6);
  color: #211f1f;
  text-align: center;
}.inactive{
  background: rgba(225, 151, 41, 0.6);
  color: #2f2e2e;
   text-align: center;
}
.rotate {
  width: auto;
  text-align: center;
  -moz-transform: rotate(-90.0deg);  /* FF3.5+ */
  -o-transform: rotate(-90.0deg);  /* Opera 10.5 */
  -webkit-transform: rotate(-90.0deg);
}

.results{
  font-weight: bolder;
  font-size: 18px;
  color: #2f2e2e;
}
</style>