<template>
  <v-container>
 <v-card>
   <v-card-title>
     <v-col>
       <v-row justify="space-between">
         <h3>Оперативный Мониторинг Контейнеров</h3>
         <v-btn
             @click="getReport"
             icon="mdi-button-pointer"
             color="success"

         >
         </v-btn>
       </v-row>
     </v-col>
   </v-card-title>
   <v-card-subtitle>
     Позволяет получить данные по всем Контейнерам на текущий момент
   </v-card-subtitle>
   <v-card-text>
     <v-form
         v-model="form.valid"
     >
      <v-container>
         <v-row>
           <v-col cols="12">
             <v-row>
               <v-col>
                 <v-text-field

                     label="Нижний порог заряда акб"
                     v-model="form.settings.LowBatPercentage"
                     type="number"
                     variant="solo"
                 >
                 </v-text-field>
               </v-col>
               <v-col>
                 <v-text-field
                     label="Нижний порог температуры"
                     v-model="form.settings.LowTempThreshold"
                     type="number"
                     variant="solo"
                 >
                 </v-text-field>
               </v-col>
               <v-col>
                 <v-text-field
                     label="Верхний порог температуры"
                     v-model="form.settings.BigTempThreshold"
                     type="number"
                     variant="solo"
                 >
                 </v-text-field>
               </v-col>
             </v-row>
             <v-row>
               <v-col>
                 <v-text-field
                     label="Глубина дней для проверки спама"
                     v-model="form.settings.SpammingDays"
                     type="number"
                     variant="solo"
                 >
                 </v-text-field>
               </v-col>
               <v-col>
                 <v-text-field
                     label="кол-во дней без геопозиции"
                     v-model="form.settings.NoPosDays"
                     type="number"
                     variant="solo"

                 >
                 </v-text-field>
               </v-col>
               <v-col>
                 <v-text-field
                     label="кол-во дней без данных"
                     v-model="form.settings.NoDataDays"
                     type="number"
                     variant="solo"
                 >
                 </v-text-field>
               </v-col>
             </v-row>
           </v-col>
         </v-row>

      </v-container>
     </v-form>
   </v-card-text>
       <template v-if="result">
         <v-row justify="space-around">
          <v-col>
            <v-card>
              <v-card-title>Всего Контейнеров</v-card-title>
              <v-card-text>
                {{result.AllItemsCount}}
              </v-card-text>
            </v-card>
          </v-col>
           <v-col>
             <v-card>
               <v-card-title>Из них с проблемами</v-card-title>
               <v-card-text>
                 {{result.ProblemItemsCount}}
               </v-card-text>
             </v-card>
           </v-col>
         </v-row>
         <v-row>
           <v-col cols="12">
             <v-expansion-panels style="max-height: 600px;overflow: auto">
               <v-expansion-panel>
                 <v-expansion-panel-title>
                   Низкий заряд АКБ (менее {{result.Problems.LowBat.Threshold}}%): {{result.Problems.LowBat.DevCount}} Контейнеров
                 </v-expansion-panel-title>
                 <v-expansion-panel-text>
                   <template v-if="result.Problems.LowBat.DevCount">
                     <v-table>
                       <thead>
                       <tr>
                         <th>Контейнер</th>
                         <th>ID Устройства</th>
                         <th>Дата и время</th>
                         <th>Показание АКБ</th>
                       </tr>
                       </thead>
                       <tbody>
                       <template v-for="item in result.Problems.LowBat.Items" v-bind:key="item.DeviceId">
                         <tr>
                           <td>{{item.DeviceName}}</td>
                           <td>{{item.DeviceId}}</td>
                           <td>{{new Date(item.Timestamp*1000).toLocaleString()}}</td>
                           <td>{{item.Percentage}} %</td>
                         </tr>
                       </template>
                       </tbody>
                     </v-table>
                   </template>
                 </v-expansion-panel-text>
               </v-expansion-panel>
               <v-expansion-panel>
                 <v-expansion-panel-title>
                   Нет геопозиции более {{result.Problems.NoPosition.Days}} дней: {{result.Problems.NoPosition.DevCount}} Контейнеров
                 </v-expansion-panel-title>
                 <v-expansion-panel-text>
                   <template v-if="result.Problems.NoPosition.DevCount">
                     <v-table>
                       <thead>
                       <tr>
                         <th>Контейнер</th>
                         <th>Устройство</th>
                         <th>Время последней позиции</th>
                       </tr>
                       </thead>
                       <tbody>
                       <template v-for="item in result.Problems.NoPosition.Items" v-bind:key="item.DeviceId">
                         <tr>
                           <td>{{item.DeviceName}}</td>
                           <td>{{item.DeviceId}}</td>
                           <td>{{new Date(item.LastPosTimestamp*1000).toLocaleString()}}</td>
                         </tr>
                       </template>
                       </tbody>
                     </v-table>
                   </template>
                 </v-expansion-panel-text>
               </v-expansion-panel>
               <v-expansion-panel>
                 <v-expansion-panel-title>
                   Достигнут верхний предел температуры (более {{result.Problems.BigTemp.Threshold}} &#x2103;): {{result.Problems.BigTemp.DevCount}} Контейнеров
                 </v-expansion-panel-title>
                 <v-expansion-panel-text>
                   <template v-if="result.Problems.BigTemp.DevCount">
                     <v-table>
                       <thead>
                       <tr>
                         <th>Контейнер</th>
                         <th>Устройство</th>
                         <th>Температура</th>
                         <th>Время последней позиции</th>
                       </tr>
                       </thead>
                       <tbody>
                       <template v-for="item in result.Problems.BigTemp.Items" v-bind:key="item.DeviceId">
                         <tr>
                           <td>{{item.DeviceName}}</td>
                           <td>{{item.DeviceId}}</td>
                           <td>{{item.Temperature}} &#x2103;</td>
                           <td>{{new Date(item.Timestamp*1000).toLocaleString()}}</td>
                         </tr>
                       </template>
                       </tbody>
                     </v-table>
                   </template>
                 </v-expansion-panel-text>
               </v-expansion-panel>
               <v-expansion-panel>
                 <v-expansion-panel-title>
                   Достигнут нижний предел температуры (менее {{result.Problems.LowTemp.Threshold}} &#x2103;) : {{result.Problems.LowTemp.DevCount}} Контейнеров
                 </v-expansion-panel-title>
                 <v-expansion-panel-text>
                   <template v-if="result.Problems.LowTemp.DevCount">
                     <v-table>
                       <thead>
                       <tr>
                         <th>Контейнер</th>
                         <th>Устройство</th>
                         <th>Температура</th>
                         <th>Время последней позиции</th>
                       </tr>
                       </thead>
                       <tbody>
                       <template v-for="item in result.Problems.LowTemp.Items" v-bind:key="item.DeviceId">
                         <tr>
                           <td>{{item.DeviceName}}</td>
                           <td>{{item.DeviceId}}</td>
                           <td>{{item.Temperature}} &#x2103;</td>
                           <td>{{new Date(item.Timestamp*1000).toLocaleString()}}</td>
                         </tr>
                       </template>
                       </tbody>
                     </v-table>
                   </template>
                 </v-expansion-panel-text>
               </v-expansion-panel>
               <v-expansion-panel>
                 <v-expansion-panel-title>
                   Превышено максимальное количество сообщений в сутки (за последние {{result.Problems.Spamming.Days}} дней): {{result.Problems.Spamming.DevCount}}
                 </v-expansion-panel-title>
                 <v-expansion-panel-text>
                   <template v-if="result.Problems.Spamming.DevCount">
                     <v-table>
                       <thead>
                       <tr>
                         <th>Контейнер</th>
                         <th>Устройство</th>
                         <th :colspan="result.Problems.Spamming.Days">Посылок по дням</th>
                       </tr>
                       </thead>
                       <tbody>
                       <template v-for="item in result.Problems.Spamming.Items" v-bind:key="item.DeviceId">
                         <tr>
                           <td>{{item.DeviceName}}</td>
                           <td>{{item.DeviceId}}</td>
                           <td>
                             <v-table>
                               <tbody>
                               <tr>
                                 <th>Дата</th>
                                 <template v-for="day in item.Days" v-bind:key="day.Day">
                                   <td style="text-align: center">{{new Date(day.Day).toLocaleDateString()}}</td>
                                 </template>
                               </tr>
                               <tr>
                                 <th>Кол-во</th>
                                 <template v-for="day in item.Days" v-bind:key="day.Day">
                                   <td v-if="day.Count>4" style="text-align: center;background: rgba(243,54,54,0.66);color: white;font-weight: bold">{{day.Count}}</td>
                                   <td style="text-align: center" v-else>{{day.Count}}</td>
                                 </template>
                               </tr>
                               </tbody>
                              </v-table>
                           </td>
                         </tr>
                       </template>
                       </tbody>
                     </v-table>
                   </template>
                 </v-expansion-panel-text>
               </v-expansion-panel>
               <v-expansion-panel>
                 <v-expansion-panel-title>
                   Отсутствуют данные (более чем {{result.Problems.NoData.Days}} дня) : {{result.Problems.NoData.DevCount}} Контейнеров
                 </v-expansion-panel-title>
                 <v-expansion-panel-text>
                   <template v-if="result.Problems.NoData.DevCount">
                     <v-table>
                       <thead>
                       <tr>
                         <th>Контейнер</th>
                         <th>Устройство</th>
                         <th>Дата и время последнего сообщения</th>
                       </tr>
                       </thead>
                       <tbody>
                       <template v-for="item in result.Problems.NoData.Items" v-bind:key="item.DeviceId">
                         <tr>
                           <td>{{item.DeviceName}}</td>
                           <td>{{item.DeviceId}}</td>
                           <td>{{new Date(item.LastTimestamp*1000).toLocaleString()}}</td>
                         </tr>
                       </template>
                       </tbody>
                     </v-table>
                   </template>
                 </v-expansion-panel-text>
               </v-expansion-panel>
             </v-expansion-panels>
           </v-col>
         </v-row>
       </template>
 </v-card>
  </v-container>
</template>

<script>
export default {
  name: "MonitoringPage",
  data(){
    return{
      result:undefined,
      lasterr:undefined,
      form:{
        valid:true,
        settings:{
          LowBatPercentage:30,
          LowTempThreshold:-50,
          BigTempThreshold:60,
          SpammingDays:14,
          NoDataDays:2,
          NoPosDays:2
        },
        rules:{
          nameRules: [
            v => !!v || 'Name is required',
            v => (v && v.length <= 10) || 'Name must be less than 10 characters',
          ],
        }
      }
    }
  },
  methods:{
    getReport(){
      const pl={
        npd:this.form.settings.NoPosDays,
        ndd:this.form.settings.NoDataDays,
        sd:this.form.settings.SpammingDays,
        btt:this.form.settings.BigTempThreshold,
        ltt:this.form.settings.LowTempThreshold,
        lbp:this.form.settings.LowBatPercentage,
      }
      this.result=undefined
      this.$store.dispatch('reports/getMonitoring',pl)
          .then(resp=>{
            this.result=resp.data
          })
          .catch(e=>{
            console.log(e)
            this.lasterr=e.response.data
          })
    }
  }
}
</script>

<style scoped>

</style>