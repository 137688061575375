export default {
    updateMe({commit},payload){
        commit('loading')
        return new Promise((resolve, reject) => {
            this.$http.put('/api/users/updateme',payload)
                .then(() => {
                    resolve()
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    changePassword({commit},payload){
        commit('loading')
        return new Promise((resolve, reject) => {
            this.$http.put('/api/users/changepassword',payload)
                .then(() => {
                    resolve()
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}