<template>
  <v-container>
    <v-col cols="12">
      <v-row>
        <v-table>
          <thead>
          <tr>
            <th>Логин</th>
            <th>Имя</th>
            <th>Email</th>
            <th>Время последнего входа в систему</th>
            <th>Активен</th>
            <th>Группа устройств</th>
            <th>Роль</th>
            <th v-if="myrole.issu || myrole.permissions.roles.write">Действия</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="user in users" v-bind:key="user.uuid">
            <tr>
              <td>{{user.login}}</td>
              <td>{{user.name}}</td>
              <td>{{user.email}}</td>
              <td>{{new Date(user.lastlogintime).toLocaleString()}}</td>
              <td>
                <v-icon color="green" icon="mdi-checkbox-marked" v-if="user.isactive"></v-icon>
                <v-icon color="error" icon="mdi-checkbox-intermediate" v-else></v-icon>
              </td>
              <td>{{$store.getters['devices/devgroupNameById'](user.devgroup)}}</td>
              <td>{{getRoleNameByuuid(user.roleid)}}</td>
              <td v-if="myrole.issu || myrole.permissions.users.write"><v-btn-group>
                <v-btn icon="mdi-account-edit-outline" @click="editedUser=user;modals.edit=true"></v-btn>
                <v-btn icon="mdi-trash-can-outline" @click="userToDelete.name=user.name;userToDelete.uuid=user.uuid;modals.delete=true" ></v-btn>
              </v-btn-group></td>
            </tr>
          </template>
          </tbody>
        </v-table>
      </v-row>
    </v-col>
  </v-container>
  <v-btn
      v-if="myrole.issu || myrole.permissions.users.write"
      class="add-new-item"
      icon="mdi-account-plus"
      color="success"
      @click="modals.create=true"
  ></v-btn>
  <!--create-->
  <v-dialog
      max-width="600px"
      v-model="modals.create"
  >
    <v-card>
      <v-toolbar
          color="black"
          title="Добавление пользователя"
      ></v-toolbar>
      <v-card-text>
        <v-form v-model="newUser.formRules.valid">
          <v-input
          >
            <v-text-field
                v-model="newUser.login"
                variant="underlined"
                label="Логин"
                :rules="newUser.formRules.loginRules"
                :counter="6"
                required
            ></v-text-field>
          </v-input>
          <v-input>
            <v-text-field
                v-model="newUser.name"
                variant="underlined"
                label="Имя"
                :rules="newUser.formRules.nameRules"
                :counter="6"
                required
            ></v-text-field>
          </v-input>
          <v-input>
            <v-text-field
                :append-inner-icon="newUser.password1vis?'mdi-eye-outline':'mdi-eye-off-outline'"
                @click:appendInner="newUser.password1vis = !newUser.password1vis"
                v-model="newUser.password1"
                variant="underlined"
                :type="newUser.password1vis ? 'text' : 'password'"
                label="Пароль"
                :counter="8"
                :rules="newUser.formRules.password"
                aria-valuemin="8"
                aria-required="true"

            >
            </v-text-field>
          </v-input>
          <v-input>
            <v-text-field
                :rules="newUser.formRules.confirmPassword"
                :append-inner-icon="newUser.password2vis?'mdi-eye-outline':'mdi-eye-off-outline'"
                @click:appendInner="newUser.password2vis = !newUser.password2vis"
                v-model="newUser.password2"
                variant="underlined"
                :type="newUser.password2vis ? 'text' : 'password'"
                label="Повторите пароль"
                :counter="8"
            >
            </v-text-field>
          </v-input>
          <v-input>
            <v-text-field
                v-model="newUser.email"
                variant="underlined"
                type="email"
                label="Email"
                :rules="newUser.formRules.emailRules"
            ></v-text-field>
          </v-input>
          <v-input>
            <v-switch
                v-model="newUser.isactive"
                color="green"
                label="Активен"
            >
            </v-switch>
            <v-select
                variant="underlined"
                v-model="newUser.roleid"
                :items="roles"
                item-title="name"
                item-value="uuid"
                :rules="newUser.formRules.role"
            ></v-select>
          </v-input>
          <v-input>
            <v-select
                variant="underlined"
                v-model="newUser.devgroup"
                :items="devgroups"
                item-value="id"
                item-title="name"
                :rules="newUser.formRules.group"
                label="Группа устройств"
            ></v-select>
          </v-input>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row justify="space-around" >
          <v-btn @click="modals.create=false">
            Закрыть
          </v-btn>
          <v-btn
              :color="newUser.formRules.valid?'green':'error'"
              :disabled="!newUser.formRules.valid"
              @click="createUser()">
            Добавить
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!--update-->
  <v-dialog
      max-width="600px"
      v-model="modals.edit"
  >
    <v-card>
      <v-toolbar
          color="black"
          title="Изменение пользователя"
      ></v-toolbar>
      <v-card-text>
        <v-form>
          <v-input
          >
            <v-text-field
                v-model="editedUser.login"
                variant="underlined"
                label="Логин"
                disabled
            ></v-text-field>
          </v-input>
          <v-input>
            <v-text-field
                v-model="editedUser.name"
                variant="underlined"
                label="Имя"
                required
            ></v-text-field>
            <v-text-field
                v-model="editedUser.email"
                variant="underlined"
                type="email"
                label="Email"
            ></v-text-field>
          </v-input>
          <v-input>
            <v-switch
                v-model="editedUser.isactive"
                color="green"
                label="Активен"
            >
            </v-switch>
            <v-select
                variant="underlined"
                v-model="editedUser.roleid"
                :items="roles"
                item-title="name"
                item-value="uuid"
                :rules="newUser.formRules.role"
            ></v-select>
          </v-input>
          <v-input>
            <v-select
                variant="underlined"
                v-model="editedUser.devgroup"
                :items="devgroups"
                item-value="id"
                item-title="name"
                :rules="newUser.formRules.group"
                label="Группа устройств"
            ></v-select>
          </v-input>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row justify="space-around" >
          <v-btn @click="modals.edit=false">
            Закрыть
          </v-btn>
          <v-btn
              @click="updateUser()">
            Обновить
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!--delete-->
  <v-dialog
      v-model="modals.delete"
      max-width="300px"
  >
    <v-card>
      <v-toolbar
          color="red"
          title="Требуется подтверждение"
      >
      </v-toolbar>
      <v-card-text>
          Вы действительно хотите удалить пользователя <span style="font-weight: bold">"{{userToDelete.name}}"</span>?
      </v-card-text>
      <v-card-actions>
        <v-row justify="space-around">
          <v-btn
              @click="modals.delete=false"
          >
            Отмена
          </v-btn>
          <v-btn
              @click="deleteUser()"
          >
            Удалить
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "usersPage",
  data(){
    return{
      modals:{
        create:false,
        edit:false,
        delete:false
      },
      editedUser:undefined,
      userToDelete:{uuid:undefined,name:undefined},
      newUser:{
        formRules:{
          valid:undefined,
          nameRules: [
            v => !!v || 'Имя обязательное поле',
            v => v.length <= 30 || 'Имя не должно содержать более 30 символов',
          ],
          loginRules: [
            v => !!v || 'Логин обязательное поле',
            v => (v.length >= 6 && v.length <= 20) || 'Логин должен быть от 6 до 20 символов',
          ],
          emailRules: [
            v => !!v || 'E-mail обязательное поле',
            v => /.+@.+/.test(v) || 'E-mail must be valid',
          ],
          password:[
              v=> !!v || 'Пароль должен быть обязательно задан',
              v=>  /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(v) || 'Пароль должен содержать буквы в ВЕРХНЕМи нижнем регистрах и цифры'
          ],
          confirmPassword:[
            v=> !!v || 'Пароль должен быть обязательно задан',
            v=> v===this.newUser.password1 || 'Пароли должны совпадать'
          ],
          role:[
            v=> !!v || 'Роль должен быть обязательно задана'
          ],
          group:[
            v=> v!==undefined || 'Группа должна быть обязательно задана'
          ]
        },
        login:undefined,
        password1:undefined,
        password1vis:false,
        password2vis:false,
        password2:undefined,
        name:undefined,
        email:undefined,
        isactive:true,
        devgroup:undefined,
        geogroup:undefined,
        roleid:undefined
      }
    }
  },
  computed:{
    users: function (){
      return this.$store.getters['users/users']
    },
    roles: function (){
      return this.$store.getters['roles/roles']
    },
    myrole: function () {
      return this.$store.getters['user/role']
    },
    devgroups: function () {
      return this.$store.getters['devices/devgroups']
    },
    geogroups: function () {
      return this.$store.getters['geofences/geogroups']
    }
  },
  methods:{
    createUser(){
      const payload={
        login:this.newUser.login,
        password:this.newUser.password1,
        name:this.newUser.name,
        email:this.newUser.email,
        isactive:this.newUser.isactive,
        devgroup:this.newUser.devgroup,
        geogroup:this.newUser.geogroup,
        roleid:this.newUser.roleid
      }
      this.$store.dispatch('users/createUser',payload)
          .then(()=>{
            this.modals.create=false
          })
          .catch(err=>{
            alert(err)
          })
    },
    deleteUser(){
      this.$store.dispatch('users/deleteUser',{uuid:this.userToDelete.uuid})
          .then(()=>{
            this.modals.delete=false
          })
          .catch(err=>{
            alert(err)
          })
    },
    updateUser(){
      this.$store.dispatch('users/updateUser',{
        uuid:this.editedUser.uuid,
        name:this.editedUser.name,
        email:this.editedUser.email,
        devgroup:this.editedUser.devgroup,
        geogroup:this.editedUser.geogroup,
        roleid:this.editedUser.roleid,
        isactive:this.editedUser.isactive
      })
          .then(()=>{
            this.modals.edit=false
          })
          .catch(err=>{
            alert(err)
          })
    },
    getRoleNameByuuid(uuid){
      let name=""
      this.roles.forEach(role=>{
        if(role.uuid===uuid){
          name=role.name
        }
      })
      return name
    }
  },
  created() {
      this.$store.dispatch('roles/getRoles')
      this.$store.dispatch('users/getUsers')
  }
}
</script>

<style scoped>
.add-new-item{
  position: fixed;
  bottom: 24px;
  right: 24px;
}
</style>