<template>
  <v-app
      style="font-family: 'Roboto', sans-serif;font-weight: 400;"
      theme="light"
  >
    <v-app-bar>
      <v-app-bar-nav-icon>
        <v-img
            src="./img/logo.png"
            width="36"
            @click="$router.push('/')"
        >
        </v-img>
      </v-app-bar-nav-icon>
      <v-app-bar-title>
        Maxima Cargo
      </v-app-bar-title>
      <template v-slot:append v-if="isLoggedIn">
        <v-btn icon v-if="role.issu">
          <v-icon @click="settings=true" icon="mdi-cogs" title="Настрйки Портала">
          </v-icon>
        </v-btn>
        <span @click="about=true" style="cursor: pointer">{{ user.name }}</span>
        <v-btn icon>
          <v-icon @click="logout" icon="mdi-logout" title="Выход">
          </v-icon>
        </v-btn>
      </template>
    </v-app-bar>
    <v-navigation-drawer
        expand-on-hover
        rail
        v-if="isLoggedIn"
    >
      <v-list
          direction="vertical"
      >
        <v-list-item
            v-for="(item,i) in routes"
            :to="item.to"
            :key="i"
            :title="item.name"
            :prepend-icon="item.icon"
            v-show="item.show"
        ></v-list-item>
        <v-list
            :nav=true
            variant="text"

        >
          <v-list-group fluid>
            <template v-slot:activator="{ props }">
              <v-list-item
                  v-bind="props"
                  title="Отчеты"
                  prepend-icon="mdi-file-chart"
              ></v-list-item>
            </template>
            <v-list-item
                v-for="([title, icon,to], i) in reports"
                :to=to
                :key="i"
                :value="title"
                :title="title"
                :prepend-icon=icon
            ></v-list-item>
            <template v-if="role.issu">
              <v-list-item
                  v-for="([title, icon,to], i) in saReports"
                  :to=to
                  :key="i"
                  :value="title"
                  :title="title"
                  :prepend-icon=icon
              ></v-list-item>
            </template>
          </v-list-group>
        </v-list>
        <v-list
            :nav=true
            variant="text"
        >
          <v-list-group fluid>
            <template v-slot:activator="{ props }">
              <v-list-item
                  v-bind="props"
                  title="Дашборды"
                  prepend-icon="mdi-view-dashboard"
              ></v-list-item>
            </template>
            <v-list-item
                v-for="([title, icon,to], i) in Boards"
                :to=to
                :key="i"
                :value="title"
                :title="title"
                :prepend-icon=icon
            ></v-list-item>
            <template v-if="role.issu">
              <v-list-item
                  v-for="([title, icon,to], i) in saBoards"
                  :to=to
                  :key="i"
                  :value="title"
                  :title="title"
                  :prepend-icon=icon
              ></v-list-item>
            </template>
          </v-list-group>
        </v-list>
      </v-list>
    </v-navigation-drawer>
    <v-main
    >
      <router-view/>
    </v-main>
  </v-app>
  <v-dialog v-model="settings" max-width="600px">
    <v-card>
      <v-toolbar
          title="Глобальные настройки портала"
          color="black"
      >
      </v-toolbar>
      <v-card-text>
        <v-input>
          <v-select
              variant="outlined"
              hide-details
              v-model="globalSettings.mapVisibleObjectsMaxTime"
              :items="globalsettingsItems.mapVisibleObjectsMaxTime"
              item-title="name"
              item-value="value"
              label="Не обтображать, если нет данных более чем"
          >
            <template v-slot:prepend>
              <span>Отображение на карте</span>
            </template>
            <template v-slot:append>
              <v-icon
                  title="Настраивает отображение контейнера на карте в зависимости от того, как долго от него не было данных, если данные отсутствуют блее чем текущее значение, то такой контейнер не будет отображаться на карте"
              >mdi-help-circle
              </v-icon>
            </template>
          </v-select>
        </v-input>
        <v-input>
          <v-text-field
              variant="outlined"
              hide-details
              type="number"
              v-model.number="globalSettings.stdDowntimeRadiusMeters"
              label="Стандартный радиус простоя для геозоны"
          >
            <template v-slot:prepend>
              <span>Радис простоя (метров)</span>
            </template>
            <template v-slot:append>
              <v-icon
                  title="Устанавливает значение по умолчанию для регистрации простоя контейнеров в геозоне">
                mdi-help-circle
              </v-icon>
            </template>
          </v-text-field>
        </v-input>
      </v-card-text>
      <v-card-actions>
        <v-row justify="space-around">
          <v-btn @click="settings=false">Закрыть</v-btn>
          <v-btn @click="saveGlobalSettings()">Сохранить</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="about" max-width="500px">
    <v-card>
      <v-toolbar
          title="Ваши данные"
          color="black"
      >
      </v-toolbar>
      <v-card-text>
        <h4>Логин: {{ user.login }}</h4>
        <v-input>
          <v-text-field
              v-model="user.name"
              type="text"
              variant="underlined"
              label="Имя"
          ></v-text-field>
        </v-input>
        <v-input>
          <v-text-field
              v-model="user.email"
              type="email"
              variant="underlined"
              label="Email"
          ></v-text-field>
        </v-input>
        <v-btn @click="passchange=true">Изменить пароль</v-btn>
      </v-card-text>
      <v-card-actions>
        <v-row justify="space-around">
          <v-btn @click="about=false">Закрыть</v-btn>
          <v-btn @click="updateMe()">Сохранить</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="passchange" max-width="400px">
    <v-card>
      <v-toolbar
          title="Изменение текущего пароля"
          color="black"
      ></v-toolbar>
      <v-card-text>
        <v-form v-model="newPass.valid">
          <v-input>
            <v-text-field
                variant="underlined"
                v-model="newPass.oldpass"
                :append-inner-icon="newPass.oldpassvis?'mdi-eye-off':'mdi-eye'"
                label="Текущий пароль"
                @click:appendInner="newPass.oldpassvis=!newPass.oldpassvis"
                :type="newPass.oldpassvis?'text':'password'"
            >
            </v-text-field>
          </v-input>
          <v-input>
            <v-text-field
                variant="underlined"
                v-model="newPass.newpass1"
                :append-inner-icon="newPass.newpass1vis?'mdi-eye-off':'mdi-eye'"
                label="Новый пароль"
                :rules="newPass.rules.password"
                @click:appendInner="newPass.newpass1vis=!newPass.newpass1vis"
                :type="newPass.newpass1vis?'text':'password'"
            >
            </v-text-field>
          </v-input>
          <v-input>
            <v-text-field
                variant="underlined"
                v-model="newPass.newpass2"
                :append-inner-icon="newPass.newpass2vis?'mdi-eye-off':'mdi-eye'"
                label="Повторите новый пароль"
                :rules="newPass.rules.confirmPassword"
                @click:appendInner="newPass.newpass2vis=!newPass.newpass2vis"
                :type="newPass.newpass2vis?'text':'password'"
            ></v-text-field>
          </v-input>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row justify="space-around">
          <v-btn @click="passchange=false">Отмена</v-btn>
          <v-btn :disabled="!newPass.valid" @click="cahangePass">Изменить</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!--Loader-->
  <v-overlay :model-value="loading" class="align-center justify-center">
    <v-progress-circular
        indeterminate="indeterminate"
        size="128"
        color="success"
        bg-color="blue"
        :width="8"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>

export default {
  data() {
    return {
      reports: [
        ['Сводный', 'mdi-chart-tree', '/summary'],
        ['По Геозонам', 'mdi-vector-polygon', '/geofences'],
        ['По Инцидентам', 'mdi-calendar-alert', '/incidents'],
        ['Биллинг', 'mdi-chart-box-outline', '/billing'],
      ],
      saReports: [
        ['Техн. Мониторинг', 'mdi-monitor-screenshot', '/techmon'],
      ],
      Boards: [
        ['По странам', 'mdi-map-legend', '/geocount'],
        ['Простои', 'mdi-clipboard-text-clock-outline', '/downtime'],
      ],
      saBoards: [
        ['Мониторинг', 'mdi-tablet-dashboard', '/monitoring'],
        ['Простои с Картой', 'mdi-clipboard-text-clock-outline', '/downtime-map'],
      ],
      about: false,
      passchange: false,
      settings: false,
      globalsettingsItems: {
        mapVisibleObjectsMaxTime: [
          {
            name: '1 месяц',
            value: 1,
          },
          {
            name: '2 месяца',
            value: 2,
          },
          {
            name: '3 месяца',
            value: 3,
          },
          {
            name: '4 месяца',
            value: 4,
          },
          {
            name: '5 месяцев',
            value: 5,
          },
          {
            name: '6 месяцев',
            value: 6,
          },
        ]
      },
      newPass: {
        valid: undefined,
        oldpass: undefined,
        oldpassvis: false,
        newpass1: undefined,
        newpass2: undefined,
        newpass1vis: false,
        newpass2vis: false,
        rules: {
          password: [
            v => !!v || 'Пароль должен быть обязательно задан',
            v => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(v) || 'Пароль должен содержать буквы в ВЕРХНЕМи нижнем регистрах и цифры'
          ],
          confirmPassword: [
            v => !!v || 'Пароль должен быть обязательно задан',
            v => v === this.newPass.newpass1 || 'Пароли должны совпадать'
          ]
        }
      }
    }
  },
  async created() {
    if (this.$store.getters['auth/isLoggedIn'] && this.$store.getters['auth/first']) {
      this.loading = true
      Promise.all([
        this.$store.dispatch('devices/getDevices'),
        this.$store.dispatch('devices/getDevGroups'),
        this.$store.dispatch('geofences/getCatGeofsfromDB'),//get geofences from idb
        this.$store.dispatch('geofences/getGeoCategories'),
        this.$store.dispatch('app/getGlobalSettings'),
      ])
          .then(() => {
            this.$store.dispatch('auth/firstin', false)
            this.$store.dispatch('devices/recreateDevTree', false)
          })
          .catch(err => console.log(err))
          .finally(() => {
            this.loading = false
          })
      if (this.$store.getters['auth/isLoggedIn']) {
        await this.$store.dispatch('geofences/syncGeofances')
      }

    }
  },
  computed: {
    routes() {
      return [
        {
          icon: "mdi-home-account",
          name: "Главная",
          to: "/",
          show: this.role.issu ? true : this.role.permissions.devices.read
        },
        {
          icon: "mdi-vector-polygon-variant",
          name: "Геозоны",
          to: "/geo",
          show: this.role.issu ? true : this.role.permissions.geofences.read
        },
        {
          icon: "mdi-selection-multiple-marker",
          name: "Категории Геозон",
          to: "/categories",
          show: this.role.issu ? true : this.role.permissions.devgroups.read
        },
        {
          icon: "mdi-account-group",
          name: "Пользователи",
          to: "/users",
          show: this.role.issu ? true : this.role.permissions.users.read
        },
        {
          icon: "mdi-account-multiple",
          name: "Роли",
          to: "/roles",
          show: this.role.issu ? true : this.role.permissions.roles.read
        },
        {
          icon: "mdi-calendar-check-outline",
          name: "Задания",
          to: "/tasks",
          show: this.role.issu
        },
        {
          icon: "mdi-train-car-container",
          name: "Контейнеры",
          to: "/containers",
          show: this.role.issu ? true : this.role.permissions.devices.read
        },
        {
          icon: "mdi-tablet-cellphone",
          name: "Устройства",
          to: "/trackers",
          show: this.role.issu
        }
      ]
    },
    loading: {
      set: function (newval) {
        this.$store.dispatch('app/loading', newval)
      },
      get: function () {
        return this.$store.getters['app/loader']
      }
    },
    drawner: {
      set: function (newval) {
        this.$store.dispatch('app/drawner', newval)
      },
      get: function () {
        return this.$store.getters['app/navDrawner']
      }
    },
    isLoggedIn: function () {
      if (this.$store.getters['auth/isLoggedIn']) {
        return this.$store.getters['auth/isLoggedIn']
      } else {
        return false
      }
    },
    user: function () {
      return this.$store.getters['user/user']
    },
    role: function () {
      return this.$store.getters['user/role']
    },
    globalSettings: function () {
      return this.$store.getters['app/globalSettings']
    },
  },
  methods: {
    saveGlobalSettings() {
      this.$store.dispatch('app/setGlobalSettings', this.globalSettings)
          .then((resp) => {
            alert(resp.data)
          })
          .catch((e) => {
            alert(e.data)
          })
    },
    logout: function () {
      this.$store.dispatch('auth/logout')
          .then(() => {
            this.$router.push('/login')
          })
    },
    cahangePass() {
      this.$store.dispatch('user/changePassword', {
        uuid: this.user.uuid,
        realpwd: this.newPass.oldpass,
        newpasword: this.newPass.newpass1
      })
          .then(() => {
            this.passchange = false
          })
          .catch(err => {
            alert(err)
          })
    },
    updateMe() {
      this.$store.dispatch('user/updateMe', {
        name: this.user.name,
        email: this.user.email
      })
          .then(() => {
            this.about = false
          })
          .catch(err => {
            alert(err)
          })
    }
  },
  mounted() {
    let elHtml = document.getElementsByTagName('html')[0]
    elHtml.style.overflowY = 'auto'
  }
}
</script>

<style>

.treeContainer {
  width: 100%;
  max-height: calc(100vh - 100px);
  overflow: auto;
  font-weight: normal;
}

#mapContent {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 85%;
  height: 100%;
}

.filters {
  width: 16%;
  height: 100%;
  background: white;
  padding: 8px 0 0 8px;
  display: flex;
  flex-direction: column;
}

.mousePos {
  position: absolute;
  background: none;
  height: auto;
  width: auto;
  bottom: 0.5rem;
  left: 0.5rem;
  font-weight: bold;
  font-size: small;
}

.mapZoom {
  position: absolute;
  display: inline-flex;
  background: #DCDCE1;
  height: auto;
  width: auto;
  top: 0.5rem;
  left: 0.5rem;
}

.saveZones {
  position: absolute;
  background: #DCDCE1;
  height: auto;
  width: auto;
  top: 3rem;
  left: 0.5rem;
  z-index: 2;
}

.layersSwitcher {
  position: absolute;
  z-index: 1;
  bottom: 2rem;
  left: 0.5rem;
  width: auto;
  height: auto;
}

.attr {
  position: absolute;
  display: inline-flex;
  background: #DCDCE1;
  height: auto;
  width: auto;
  bottom: 1rem;
  z-index: 100;
  padding: 0;
  margin: 0;
}

.edt {
  position: absolute;
  display: inline-flex;
  background: #DCDCE1;
  height: auto;
  width: auto;
  bottom: 2rem;
  z-index: 1;
}

.actionscontainer {
  width: 100%;
  padding: 16px;
}

.actions {
  color: gray;
  display: flex;
  flex-direction: row;
  justify-content: end;
  box-sizing: border-box;
  flex-wrap: nowrap;
  width: 100%;
}

.actions * {
  display: block;
  width: auto;
  margin-left: 8px;
  margin-right: 8px;
}

.actions:hover {
  color: black;
  cursor: pointer;
}

.v-tab {
  text-transform: none !important;
  font-weight: normal !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 8px;
  overflow: auto;
  color: black;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 24px 16px 16px 16px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  width: 260px;
  min-height: 130px;
}

.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

#popup-content {
  height: auto;
  overflow: auto;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}

.ol-popup-closer:after {
  content: "✖";
  color: black;
}
</style>
