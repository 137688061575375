<template>
  <li v-if="node?.type &&node.type==='folder'">
    <v-btn v-if="node.childrens.length&&node.show" @click="showNode(node)" width="auto"  height="auto" style="font-size: 12px;margin-right: 8px" icon="mdi-minus-box-outline" variant="text"></v-btn>
    <v-btn v-if="node.childrens.length&&!node.show" width="auto"  @click="showNode(node)" height="auto" style="font-size: 12px;margin-right: 8px" icon="mdi-plus-box-outline" variant="text"></v-btn>
    <v-btn v-if="!node.childrens.length" width="auto"   height="auto" style="font-size: 12px;margin-right: 8px" icon="mdi-checkbox-blank-off-outline" variant="text"></v-btn>
    <template v-if="node.childrens.length">
      <v-btn  v-if="node.visible" @click="swVisible(node)" width="auto"  height="auto" style="font-size: 12px;margin-right: 8px" icon="mdi-checkbox-marked-outline" variant="text"></v-btn>
      <v-btn  v-if="!node.visible" @click="swVisible(node)" width="auto"  height="auto" style="font-size: 12px;margin-right: 8px" icon="mdi-checkbox-blank-outline" variant="text"></v-btn>
    </template>
    <template v-if="!node.childrens.length">
      <v-btn  width="auto"  height="auto" style="font-size: 12px;margin-right: 8px" icon="mdi-checkbox-blank-off" variant="text"></v-btn>
    </template>
    <v-chip
        size="small"
        label
        variant="text"
        v-bind:style="{color:setcolor}"
        v-bind:class="classObject"
        v-if="node.childrens.length&&node.show"
    >
      <span @click="showNode(node);setCurrentObject(node)">{{node.label}}</span>
    </v-chip>
    <v-chip
        size="small"
        label
        variant="text"
        v-bind:style="{color:setcolor}"
        v-bind:class="classObject"
        v-else
    >
      <span @click="showNode(node);setCurrentObject(node)">{{node.label}}</span>
    </v-chip>
    <ul v-if="node.childrens.length&&node.show">
      <template v-for="child in node.childrens" v-bind:key="child.id">
        <NodeTree
            :sw-visible="swVisible"
            :node="child"
            :show-node="showNode"
            :set-current-object="setCurrentObject"
            :icon="icon"
            :selectedObject="selectedObject"
            :main-color="mainColor"
            :selected-color="selectedColor"
            >
        </NodeTree>
      </template>
    </ul>
  </li>
  <li
      v-if="node?.type &&node.type==='car'"
  >
    <span style="display: flex;flex-direction: row;flex-wrap: nowrap;margin-left: 8px">
        <v-btn v-if="node.visible" @click="swVisible(node)" width="auto"  height="auto" style="font-size: 12px;margin:0" icon="mdi-checkbox-marked-outline" variant="text" ></v-btn>
        <v-btn v-if="!node.visible" @click="swVisible(node)" width="auto"  height="auto" style="font-size: 12px;margin:0" icon="mdi-checkbox-blank-outline" variant="text" ></v-btn>

      <v-btn
          variant="text"
          width="auto"
          height="auto"
          style="font-size: 12px;text-transform: none;margin:0"
          v-bind:style="{color:setcolor}"
          v-bind:class="classObject"
          :prepend-icon="icon"
      >
        <span @click="setCurrentObject(node)">{{node.label}}</span>
      </v-btn>
    </span>
  </li>
</template>

<script>
export default {
  name: 'NodeTree',
  props: {
    node: Object,
    showNode:Function,
    swVisible:Function,
    setCurrentObject:Function,
    icon:{
      default: 'mdi-album',
      type: String
    },
    selectedObject:Object,
    mainColor:{
      default: 'black',
      type:String
    },
    selectedColor:{
      default: 'black',
      type:String
    }
  },
  computed: {
    classObject: function () {
      return {
        active: this.node ===this.selectedObject,
      }
    },
    setcolor:function (){
      if(this.node ===this.selectedObject){
        return this.selectedColor
      }else{
        return this.mainColor
      }
    }
  }
};
</script>
<style scoped>
.active{
  font-weight: 900 !important;
}
</style>