import api from "@/api/idb";

export default {
    options:{
        root:true,
        handler({commit,dispatch},pl){
            commit('setOption',pl)
            if(pl.name==='geoCategories'){
                dispatch('geofences/renderGeofences',null,{root:true})
            }
        }
    },
    getOptions:{
        root:true,
        async handler({commit}){
        let opts = await api.getOptions()
        const pl={}
        opts.forEach(opt=>{
            pl[opt.name]=opt.value
        })
        commit('options',pl)
    }
    },
    saveOption:{
        root:true,
        handler({commit} ,option){
                commit('loading')
                return api.saveOption(option)
            }
    },
    getOption:{
        root:true,
        handler({commit} ,name){
            commit('loading')
            return api.getOption(name)
        }

    },
}