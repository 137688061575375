export default {
    getDevices({commit,dispatch,rootGetters}) {
        return new Promise((resolve, reject) => {
            const user=rootGetters['user/user']
            const role=rootGetters["user/role"]
            let key='notnull'
            if (role.issu){
                key='all'
            }
            this.$http.get(`/api/devices?type=${key}`)
                .then(resp => {
                    commit('getDevicesSuccess', resp.data)
                    commit('setRootNodes',{root:user.devgroup,groups:resp.data})
                    dispatch('recreateDevTree')
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    getDevGroups({commit,dispatch, rootGetters}) {
        return new Promise((resolve, reject) => {
            this.$http.get('/api/devgroups')
                .then(resp => {
                    commit('getDevGroupsSuccess', resp.data)
                    const user=rootGetters['user/user']
                    commit('setRootNodes',{root:user.devgroup,groups:resp.data})
                    dispatch('recreateDevTree')
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    updataDevice({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            this.$http.put('/api/devices',payload)
                .then(resp => {
                    dispatch('getDevices')
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    reconnectTracker({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            const pl={
                action:payload.action,
                uuid:payload.uuid,
                trackerid:payload.trackerid?payload.trackerid:0
            }
            this.$http.patch(`/api/devices?uuid=${pl.uuid}&trackerid=${pl.trackerid}&action=${pl.action}`)
                .then(resp => {
                    dispatch('getDevices')
                    dispatch('trackers/getTrackers',undefined,{root:true})
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    updataDevCategory({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            this.$http.put('/api/devgroups',payload)
                .then(resp => {
                    dispatch('getDevGroups')
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    createDevice({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            console.log(payload)
            const pl = {
                name: payload.name,
                GROUPID: payload.group.id
            }
            if (payload.current_dev) {
                pl.tracker_id = payload.current_dev
            }
            this.$http.post('/api/devices', pl)
                .then(resp => {
                    dispatch('getDevices')
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    createDevCategory({dispatch}, payload) {
        const pl={
            name:payload.label,
            parent:payload.group.id
        }
        return new Promise((resolve, reject) => {
            this.$http.post('/api/devgroups',pl)
                .then(resp => {
                    dispatch('getDevGroups')
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    deleteDevice({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            this.$http.delete('/api/devices',{data:payload})
                .then(resp => {
                    dispatch('getDevices')
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    deleteDevCategory({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            this.$http.delete('/api/devgroups',{data:payload})
                .then(resp => {
                    dispatch('getDevGroups')
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    recreateDevTree({dispatch,commit, rootGetters ,state}){
        const user=rootGetters['user/user']
        commit("setRootNodes",{root:user.devgroup})
        dispatch('createDevTree', state.devNodes)
        dispatch('createDevGroupsTree', state.devTreeNodes)
    },
    createDevTree({dispatch, state}, parent) {
        for (let i = 0; i < state.devgroups.length; i++) {
            let node = {
                label: '',
                id: null,
                parent:null,
                childrens: [],
                show: false,
                type: 'folder',
                visible:false
            }
            if ( state.devgroups[i].parent === parent.id) {
                node.label = state.devgroups[i].name
                node.id = state.devgroups[i].id
                node.parent=state.devgroups[i].parent
                dispatch('createDevTree', node)
                parent.childrens.push(node)
            }
        }
        for (let j = 0; j < state.devices.length; j++) {
            if (parent?.id && state.devices[j].current_dev && state.devices[j].GROUPID === parent.id) {
                parent.childrens.push({
                    type: 'car',
                    label: state.devices[j].name,
                    uuid: state.devices[j].uuid,
                    identificator: state.devices[j].current_dev.identificator,
                    battaryendtime: state.devices[j].current_dev.battaryendtime,
                    montage_date: state.devices[j].current_dev.montage_date,
                    iccid: state.devices[j].current_dev.iccid,
                    msisdn: state.devices[j].current_dev.msisdn,
                    parent: state.devices[j].GROUPID,
                    geofences: state.devices[j].geofences,
                    devtype:state.devices[j].current_dev.type,
                    visible:false,
                    old_devs:state.devices[j].old_devs,
                    current_dev_id:state.devices[j].current_dev_id,
                })
            }
        }
    },
    createDevGroupsTree({dispatch, state}, parent) {
        for (let i = 0; i < state.devgroups.length; i++) {
            let node = {
                label: '',
                id: null,
                parent:null,
                childrens: [],
                show: false,
                type: 'folder'
            }
            if (state.devgroups[i].parent === parent.id) {
                node.label = state.devgroups[i].name
                node.id = state.devgroups[i].id
                node.parent=state.devgroups[i].parent
                dispatch('createDevGroupsTree', node)
                parent.childrens.push(node)
            }
        }
    },
}