import {fromLonLat} from "ol/proj";
import {Feature} from "ol";
import {Circle, MultiPolygon} from "ol/geom";
import {Fill, Stroke, Style, Text} from "ol/style";
import api from "@/api/idb";

export default {
    async getGeofs({commit,dispatch}){
        let geofs = await api.getGeofs()
        commit('getGeofencesSuccess', geofs)
        dispatch('renderGeofences')
    },
    async deleteGeofFromDb ({dispatch}, uuid ) {
        api.deleteGeo(uuid)
        dispatch('delteteGeofenceFromMap',uuid)
    },
    async updateGeoInDB ({commit,dispatch}, geof ) {
        commit('loading')
        await api.deleteGeo(geof.uuid)
        await api.saveGeof(geof)
        await dispatch('renderGeofence', geof)
    },
    async syncGeofances({dispatch,commit}){
        let uuids=await dispatch('getUUIDSfromDB')
        let lastUpdate= await dispatch('getOption','lastUpdate',{root:true})
        const pl={
            uuids:uuids.result,
            lastUpdate:lastUpdate?.value || 0
        }
        let res=await this.$http.post('/api/geofences',pl)
        commit('syncGeofencesSuccess',res.data)
        dispatch('saveOption',{name:'lastUpdate',value:res.data.timestamp},{root:true})
        dispatch('getGeofs')
    },
    async renderGeofences({state,dispatch,rootGetters}) {
        let fts=[]
        const geofs=[]
        const supers=await dispatch('getSupersfromDB')
        geofs.push(...supers)
        const options=rootGetters["options/options"]
        for (let i in options['geoCategories']){
            if(options['geoCategories'][i].visible){
                if (options['geoCategories'][i].id===-1){//если административное деление
                    const catgeofs= await dispatch('getAdminfromDB')
                    geofs.push(...catgeofs)
                }else{
                    const catgeofs= await dispatch('getCatGeofsfromDB',options['geoCategories'][i].id)
                    geofs.push(...catgeofs)
                }

            }
        }
        return new Promise((resolve)=>{
            state.geofenceVectorSource.refresh()
            geofs.forEach(geofence=>{
                if (geofence.type === 'polygon') {
                    let coords = []
                    geofence.coords.coords.forEach(function (poly) {
                        let polycoord=[]
                        poly.forEach(lineRing=>{
                            let coords=[]
                            lineRing.forEach(coord=>{
                                coords.push(fromLonLat([coord[0],coord[1]]))
                            })
                            polycoord.push(coords)
                        })
                        coords.push(polycoord)
                    })
                    const ft = new Feature({
                        geometry: new MultiPolygon(coords),
                        name: geofence.name,
                        uuid: geofence.uuid,
                        groupid:geofence.groupid,
                        description:geofence.description,
                        img:geofence.image.compressed,
                        watch_days:geofence.watch_days,
                        min_period_sec:geofence.min_period_sec
                    })
                    if(typeof (geofence.style.fill.color)!=="undefined"){
                        ft.setStyle(new Style({
                            fill:new Fill({
                                color:geofence.style.fill.color
                            }),
                            stroke:new Stroke({
                                color:geofence.style.stroke.color,
                                width:geofence.style.stroke.width
                            }),
                            text:new Text({
                                text:geofence.name,
                                font:'bold 16px Roboto',
                                overflow:false,

                            })
                        }))
                    }
                    fts.push(ft)
                }
                if (geofence.type === 'circle') {
                    const center = fromLonLat(geofence.coords.center)
                    let c = new Circle(center)
                    c.setCenter(center)
                    c.setRadius(geofence.coords.radius)
                    const ft = new Feature({
                        geometry: c,
                        name: geofence.name,
                        uuid: geofence.uuid,
                        groupid:geofence.groupid,
                        description:geofence.description,
                        img:geofence.image.compressed,
                        watch_days:geofence.watch_days,
                        min_period_sec:geofence.min_period_sec
                    })
                    if(typeof (geofence.style.fill.color)!=="undefined"){
                        var circleTextStyle = new Style({
                            text:new Text({
                                text:geofence.name,
                                font:'bold 26px Roboto',
                                placement:'point',
                                overflow:true
                            })})
                        var CircleStyle=new Style({
                            fill:new Fill({
                                color:geofence.style.fill.color
                            }),
                            stroke:new Stroke({
                                color:geofence.style.stroke.color,
                                width:geofence.style.stroke.width
                            }),
                        })
                        var cStyle = function(feature, resolution) {
                            var styles = [CircleStyle];
                            if (resolution<5) {
                                circleTextStyle.getText().setText(feature.get('name'))
                                styles.push(circleTextStyle);
                            }
                            return styles;
                        }
                        ft.setStyle(cStyle)
                    }
                    fts.push(ft)
                }
            })
            state.geofenceVectorSource.addFeatures(fts)
            resolve()
        })
    },
    addGeofToDb ({commit} ,geof ) {
        commit('loading')
        return api.saveGeof(geof)
    },
    addManyGeofsToDb ({commit} ,geofs ) {
        commit('loading')
        return api.saveManyGeofs(geofs)
    },
    clearGeofDb ({commit}) {
        commit('loading')
        return api.clear()
    },
    getUUIDSfromDB({commit}){
        commit('loading')
        return api.getALLuuids()
    },
    getSupersfromDB({commit}){
        commit('loading')
        return api.getSuperGeofs()
    },
    getAdminfromDB({commit}){
        commit('loading')
        return api.getAdministrativGeofs()
    },
    getCatGeofsfromDB({commit},catID){
        commit('loading')
        return api.getCatGeofs(catID)
    },
    getGeoCategories({commit,dispatch}) {
        return new Promise((resolve, reject) => {
            this.$http.get('/api/geocats')
                .then(resp => {
                    commit('getGeoCatsSuccess',resp.data)
                    const geocats=[
                        {
                            visible: false,
                            id:0,
                            name:'Без Категории',
                        },
                        {
                            visible: false,
                            id:-1,
                            name:'Адм. деление',
                        }
                        ]
                    resp.data.forEach(d=>{
                        geocats.push({
                            visible: false,
                            id:d.ID,
                            name:d.NAME,
                        })
                    })
                    dispatch('saveOption',{name:'geoCategories',value:geocats},{root:true})
                    dispatch('getOptions',{},{root:true})
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    updataGeoCat({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            this.$http.put(`/api/geocats?id=${payload.id}&name=${payload.name}`)
                .then(() => {
                    dispatch('getGeoCategories')
                    resolve()
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    updataGeofences({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            this.$http.patch('/api/geofences',payload)
                .catch(err => {
                    reject(err)
                })
                .then((resp)=>{
                    dispatch('updateGeoInDB',resp.data)
                        .then(()=>{
                            dispatch('delteteGeofenceFromStore',resp.data.uuid)
                            dispatch('addGeofenceToStore',resp.data)
                            dispatch('renderGeofence',resp.data)
                            resolve()
                        })
                        .catch(err=>{
                            reject(err)
                        })
                })
        })
    },
    createGeoCat({dispatch}, name) {
        return new Promise((resolve, reject) => {
            this.$http.post(`/api/geocats?name=${name}`)
                .then(() => {
                    dispatch('getGeoCategories')
                    resolve()
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    addGeofences({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            this.$http.put('/api/geofences',payload)
                .catch(err => {
                    reject(err)
                })
                .then((r)=>{
                    dispatch('addGeofenceToStore',r.data)
                    dispatch('renderGeofence',r.data)
                    resolve()
                })
        })
    },
    addGeofenceToStore({commit,dispatch}, payload) {
        commit('addGeofencetoStore',payload)
        dispatch('addGeofToDb',payload)
    },
    delteteGeofenceFromMap({commit},uuid){
        commit('deleteGeofenceFromMap',uuid)
    },
    delteteGeofenceFromStore({commit},uuid){
        commit('deleteGeofenceFromStore',uuid)
    },
    deleteGeoCat({dispatch}, id) {
        return new Promise((resolve, reject) => {
            this.$http.delete(`/api/geocats?id=${id}`)
                .then(() => {
                    dispatch('getGeoCategories')
                    resolve()
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    deleteGeoFence({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            this.$http.delete('/api/geofences',{data:payload})
                .then(() => {
                    dispatch('deleteGeofFromDb',payload.uuid)
                        .then(()=>{
                                dispatch('delteteGeofenceFromStore',payload.uuid)
                                resolve()
                            }
                        )
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    renderGeofence({state,dispatch},geof) {
        return new Promise((resolve)=>{
            //delete old geo if in source
            dispatch('delteteGeofenceFromMap',geof.uuid)
            if (geof.type === 'polygon') {
                let coords = []
                geof.coords.coords.forEach(function (poly) {
                    let polycoord=[]
                    poly.forEach(lineRing=>{
                        let coords=[]
                        lineRing.forEach(coord=>{
                            coords.push(fromLonLat([coord[0],coord[1]]))
                        })
                        polycoord.push(coords)
                    })
                    coords.push(polycoord)
                })
                const ft = new Feature({
                    geometry: new MultiPolygon(coords),
                    name: geof.name,
                    uuid: geof.uuid,
                    groupid:geof.groupid,
                    description:geof.description,
                    img:geof.image.compressed,
                    watch_days:geof.watch_days,
                    min_period_sec:geof.min_period_sec
                })
                if(typeof (geof.style.fill.color)!=="undefined"){
                    ft.setStyle(new Style({
                        fill:new Fill({
                            color:geof.style.fill.color
                        }),
                        stroke:new Stroke({
                            color:geof.style.stroke.color,
                            width:geof.style.stroke.width
                        }),
                        text:new Text({
                            text:geof.name,
                            font:'bold 16px Roboto',
                            overflow:false,

                        })
                    }))
                }
                state.geofenceVectorSource.addFeature(ft)
            }
            if (geof.type === 'circle') {
                const center = fromLonLat(geof.coords.center)
                let c = new Circle(center)
                c.setCenter(center)
                c.setRadius(geof.coords.radius)
                const ft = new Feature({
                    geometry: c,
                    name: geof.name,
                    uuid: geof.uuid,
                    groupid:geof.groupid,
                    description:geof.description,
                    img:geof.image.compressed,
                    watch_days:geof.watch_days,
                    min_period_sec:geof.min_period_sec
                })
                if(typeof (geof.style.fill.color)!=="undefined"){
                    var circleTextStyle = new Style({
                        text:new Text({
                            text:geof.name,
                            font:'bold 26px Roboto',
                            placement:'point',
                            overflow:true
                        })})
                    var CircleStyle=new Style({
                        fill:new Fill({
                            color:geof.style.fill.color
                        }),
                        stroke:new Stroke({
                            color:geof.style.stroke.color,
                            width:geof.style.stroke.width
                        }),
                    })
                    var cStyle = function(feature, resolution) {
                        var styles = [CircleStyle];
                        if (resolution<5) {
                            circleTextStyle.getText().setText(feature.get('name'))
                            styles.push(circleTextStyle);
                        }
                        return styles;
                    }
                    ft.setStyle(cStyle)
                }
                state.geofenceVectorSource.addFeature(ft)
            }
            resolve()
        })
    },
}
