export default {
    getDevicesSuccess(state, payload) {
        state.devices=[]
        payload.forEach(function (item) {
            state.devices.push(item)
        })
    },
    getDevGroupsSuccess(state, payload) {
        state.devgroups=[]
        payload.forEach( (item)=> {
            state.devgroups.push(item)
        })
    },
    setRootNodes(state,payload) {
        state.devgroups.forEach(group=>{
            if (group.id===payload.root){
                state.devNodes = {
                    label: group.name,
                    id: group.id,
                    childrens: [],
                    show: false,
                    type: 'folder',
                    visible: false
                }
                state.devTreeNodes = {
                    label: group.name,
                    id: group.id,
                    childrens: [],
                    show: true,
                    type: 'folder'
                }
            }
        })
    },
    loading(state) {
        state.status = 'loading'
    },
}