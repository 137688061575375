export default {
    getSummaryReport({commit}, payload) {
        commit('loading')
        return new Promise((resolve, reject) => {
            this.$http.get(`/api/reports/summary/?from=${payload.from}&to=${payload.to}&uuid=${payload.device}&address=${payload.address}`)
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    getBillingsFileList({commit}) {
        commit('loading')
        return new Promise((resolve, reject) => {
            this.$http.get(`/api/storage?files=all`)
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    getDevsInSuperZones() {
        return new Promise((resolve, reject) => {
            this.$http.get(`/api/reports/insuperzones/`)
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    getIncidentsReport({commit}, payload) {
        commit('loading')
        return new Promise((resolve, reject) => {
            this.$http.get(`/api/reports/incidents/?from=${payload.from}&to=${payload.to}`)
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    getMonitoring({commit}, payload) {
        commit('loading')
        return new Promise((resolve, reject) => {
            this.$http.get(`/api/reports/monitoring/?npd=${payload.npd}&ndd=${payload.ndd}&sd=${payload.sd}&btt=${payload.btt}&ltt=${payload.ltt}&lbp=${payload.lbp}`)
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    getTechMonitoring({commit}, payload) {
        commit('loading')
        return new Promise((resolve, reject) => {
            this.$http.get(`/api/reports/techmon/?from=${payload.from}&to=${payload.to}`)
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    getTechMonitoringCountPerDays({commit}, payload) {
        commit('loading')
        return new Promise((resolve, reject) => {
            this.$http.get(`/api/reports/devsonlineperdays/?from=${payload.from}&to=${payload.to}`)
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    getTechMonitoringRipPerDays({commit}, payload) {
        commit('loading')
        return new Promise((resolve, reject) => {
            this.$http.get(`/api/reports/lastdevsdays/?from=${payload.from}&to=${payload.to}`)
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    getBilling({commit}, payload) {
        commit('loading')
        return new Promise((resolve, reject) => {
            this.$http.get(`/api/reports/billing/?year=${payload.year}&month=${payload.month}&group=${payload.group}`)
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    getSelectedBilling({commit}, payload) {
        commit('loading')
        return new Promise((resolve, reject) => {
            this.$http.get(`/api/reports/selectedbillingfromdb/?year=${payload.year}&month=${payload.month}&group=${payload.group}`)
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    getBillingsFromDb({commit}) {
        commit('loading')
        return new Promise((resolve, reject) => {
            this.$http.get(`/api/reports/billingsfromdb/`)
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    getDownTime({commit}, days) {
        commit('loading')
        return new Promise((resolve, reject) => {
            this.$http.get(`/api/reports/downtime/?days=${days}`)
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}