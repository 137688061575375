export default {
    getUsersSuccess(state, payload) {
        state.users.splice(0, state.users.length)
        payload.forEach(function (item) {
            state.users.push(item)
        })
    },
    loading(state) {
        state.status = 'loading'
    },
}