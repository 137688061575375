<template>
  <v-container>
    <v-col cols=12>
      <v-table>
        <thead>
        <tr>
          <th>Название категории</th>
          <th>Идентификатор</th>
          <th v-if="role.issu || role.permissions.geogroups.write">Действие</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="item in categories" v-bind:key="item.id">
          <tr>
            <td>{{item.NAME}}</td>
            <td>{{item.ID}}</td>
            <td v-if="role.issu || role.permissions.geogroups.write">
              <v-btn-group>
                <v-btn icon="mdi-vector-square-edit" @click="setEditedObj(item.ID)"></v-btn>
                <v-btn icon="mdi-trash-can-outline" @click="daletedID=item.ID;modals.delete=true"></v-btn>
              </v-btn-group>
            </td>
          </tr>
        </template>
        </tbody>
      </v-table>
    </v-col>
  </v-container>
  <!--Edit-->
  <v-dialog
      v-model="modals.update"
      max-width="500px"
      transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
          color="black"
          title="Редактирование Категории"
      >
      </v-toolbar>
      <v-card-text>
        <v-input>
          <v-text-field type="text" v-model="editedObj.name"></v-text-field>
        </v-input>
      </v-card-text>
      <v-card-actions>
        <v-row justify="space-around">
          <v-btn
              @click="update"
          >
            Сохранить
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!--Create-->
  <v-dialog
      v-model="modals.create"
      max-width="500px"
      transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
          color="black"
          title="Добавление категории"
      >
      </v-toolbar>
      <v-card-text>
        <v-input>
          <v-text-field type="text"
                        label="Введите название"
                        v-model="newName"
          >
          </v-text-field>
        </v-input>
      </v-card-text>
      <v-card-actions>
        <v-row justify="space-around">
          <v-btn
              @click="create()"
          >
            Создать
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!--Del-->
  <v-dialog
      v-model="modals.delete"
      max-width="300px"
      transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
          color="error"
          title="Требуется подтверждение"
      >
      </v-toolbar>
      <v-card-text>
        Вы дествительно ходите удалить данную категорию?
      </v-card-text>
      <v-card-actions>
        <v-row justify="space-around">
          <v-btn
              @click="modals.delete = false;daletedID=undefined"
          >
            Закрыть
          </v-btn>
          <v-btn
              @click="delObj();modals.delete=false"
          >
            Удалить
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-btn
      v-if="role.issu || role.permissions.geogroups.write"
      class="add-new-item"
      icon="mdi-plus-circle"
      color="success"
      @click="modals.create=true"
  ></v-btn>
</template>

<script>
export default {
  name: "geoCategories",
  data(){
    return{
      editedObj:{
        id:undefined,
        name:undefined
      },
      newName:undefined,
      daletedID:undefined,
      modals:{
        create:false,
        update:false,
        delete:false
      }
    }
  },
  methods:{
    setEditedObj(id){
      this.categories.forEach(cat=>{
        if(cat.ID===id){
          this.editedObj={
            id:cat.ID,
            name:cat.NAME
          }
        }
      })
      this.modals.update=true
    },
    update(){
      this.$store.dispatch('geofences/updataGeoCat',{id:this.editedObj.id,name:this.editedObj.name})
          .catch(e=>{
            alert(e.response.data)
          }).finally(()=>{
        this.modals.update=false
        this.editedObj.id=undefined
        this.editedObj.name=undefined
      })

    },
    create(){
      this.$store.dispatch('geofences/createGeoCat',this.newName)
          .catch(e=>{
            alert(e.response.data)
          })
      this.modals.create=false
      this.newName=undefined
    },
    delObj(){
      this.$store.dispatch('geofences/deleteGeoCat',this.daletedID)
          .catch(e=>{
            alert(e.response.data)
          })
      this.daletedID=undefined
    }
  },
  computed:{
    categories(){
      return this.$store.getters['geofences/geocats']
    },
    role(){
      return this.$store.getters['user/role']
    }
  }
}

</script>

<style scoped>
.add-new-item{
  position: fixed;
  bottom: 24px;
  right: 24px;
}
</style>