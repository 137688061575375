import Vuex from 'vuex'

//import modules
import userModule from '@/store/modules/user'
import appModule from '@/store/modules/app'
import authModule from '@/store/modules/auth'
import dataModule from '@/store/modules/data'
import databaseModule from '@/store/modules/database'
import devicesModule from '@/store/modules/devices'
import geofencesModule from '@/store/modules/geofences'
import optionsModule from '@/store/modules/options'
import reportsModule from '@/store/modules/reports'
import rolesModule from '@/store/modules/roles'
import usersModule from '@/store/modules/users'
import tasksModule from '@/store/modules/tasks'
import trackersModule from '@/store/modules/trackers'

const actions = ({})
const mutations = ({})
const getters = ({})
const state=({})

const modules = {
    user: userModule,
    app: appModule,
    auth: authModule,
    data: dataModule,
    database: databaseModule,
    devices: devicesModule,
    geofences: geofencesModule,
    options: optionsModule,
    reports: reportsModule,
    roles: rolesModule,
    users: usersModule,
    tasks:tasksModule,
    trackers:trackersModule
}

export default new Vuex.Store({
    state,
    actions,
    mutations,
    getters,
    modules
})