<template>
<v-container>
  <v-col>
    <v-card>
      <v-card-title>
        Управление заданиями на портале
      </v-card-title>
      <v-card-text>
        <v-table
            :height="tableHeight"
            fixed-header
        >
          <thead>
            <tr>
              <th>ID</th>
              <th>Название</th>
              <th>Расписание</th>
              <th>Тип расписания</th>
              <th>Функция</th>
              <th>Опции</th>
              <th>Активно</th>
              <th>Дата Создания</th>
              <th>Действия</th>
            </tr>
          </thead>
          <tbody>
          <tr
              v-for="t in tasks"
              :key="t.id"
          >
            <td>{{t.id}}</td>
            <td>{{t.name}}</td>
            <td>
              <template v-if="t.schedule_type==='Month'">
                <p>Дата выполнения: {{t.schedule.start_day}} число месяца</p>
                <p>Время запуска: {{('0'+t.schedule.start_hour).slice(-2)+':'+('0'+t.schedule.start_minute).slice(-2)+':'+('0'+t.schedule.start_second).slice(-2)}} </p>
              </template>
              <template v-if="t.schedule_type==='Week'">
                <p>Время запуска: {{('0'+t.schedule.start_hour).slice(-2)+':'+('0'+t.schedule.start_minute).slice(-2)+':'+('0'+t.schedule.start_second).slice(-2)}}</p>
                <v-btn-group>
                  <v-checkbox
                      disabled
                      label="ПН"
                      v-model="t.schedule.week_days[0]"
                      hide-details
                  ></v-checkbox>
                  <v-checkbox
                      disabled
                      label="ВТ"
                      v-model="t.schedule.week_days[1]"
                      hide-details
                  ></v-checkbox>
                  <v-checkbox
                      disabled
                      label="СР"
                      v-model="t.schedule.week_days[2]"
                      hide-details
                  ></v-checkbox>
                  <v-checkbox
                      disabled
                      label="ЧТ"
                      v-model="t.schedule.week_days[3]"
                      hide-details
                  ></v-checkbox>
                  <v-checkbox
                      disabled
                      label="ПТ"
                      v-model="t.schedule.week_days[4]"
                      hide-details
                  ></v-checkbox>
                  <v-checkbox
                      disabled
                      label="СБ"
                      v-model="t.schedule.week_days[5]"
                      hide-details
                  ></v-checkbox>
                  <v-checkbox
                      disabled
                      label="ВС"
                      v-model="t.schedule.week_days[6]"
                      hide-details
                  ></v-checkbox>
                </v-btn-group>
              </template>
              <template v-if="t.schedule_type==='Once'">
                Дата и время выполнения: {{new Date(t.schedule.date_time).toLocaleString()}}
              </template>
              <template v-if="t.schedule_type==='Duration'">
                Время повторения {{t.schedule.duration}} сек.
              </template>
            </td>
            <td>{{scheduleTypes.get(t.schedule_type)}}</td>
            <td>{{scheduleFuncs.get(t.func_name)}}</td>
            <td>
              <template v-if="t.func_name==='billing'">
                Группа Контейнеров <v-chip label variant="outlined">{{getGroupName(t.options.group_id)}}</v-chip>
              </template>
            </td>
            <td>
              <v-icon
                  v-if="t.active"
                  icon="mdi-timer-outline"
                  color="success"
                  size="x-large"
              ></v-icon>
              <v-icon
                  v-else
                  icon="mdi-timer-off-outline"
                  color="orange"
                  size="x-large"
              >
              </v-icon>
            </td>
            <td>{{new Date(t.date_create*1000).toLocaleDateString()}}</td>
            <td>
              <v-btn-group>
                <v-btn
                    icon="mdi-calendar-edit-outline"
                    variant="text"
                    @click="settEditedTask(t)"
                >
                </v-btn>
                <v-btn
                    icon="mdi-delete-outline"
                    variant="text"
                    @click="setToDelete(t)"
                >
                </v-btn>
              </v-btn-group>
            </td>
          </tr>
          </tbody>
        </v-table>
      </v-card-text>
    </v-card>
  </v-col>
</v-container>
  <v-btn
      class="add-new-item"
      icon="mdi-calendar-plus"
      color="success"
      title="Добавить новое задание"
      @click="setNewTask"
  ></v-btn>
  <v-btn
      class="restart-item"
      icon="mdi-restart"
      color="orange"
      title="Рестарт всех заданий"
      @click="restartTaskManager"
      :loading="loading"
  ></v-btn>
  <!--CREATE-->
  <v-dialog width="800" min-height="600" v-model="modals.create">
    <v-card>
      <v-card-title>Создание Задания</v-card-title>
      <v-card-subtitle>Позволяет создать автоматически выполняемое задание на портале</v-card-subtitle>
      <v-card-text>
        <v-input>
          <v-text-field
              label="Название задания"
              v-model="newTask.name"
          ></v-text-field>
        </v-input>
        <v-select
            label="Правило Срабатывания"
            :items="defaultShedules"
            item-title="name"
            item-value="value"
            v-model="newTask.schedule"
        >
        </v-select>
        <template
            v-if="newTask?.schedule && newTask.schedule.type==='Week'"
        >
            <v-input>
              <v-text-field
                  label="Часы"
                  type="number"
                  min="0"
                  max="23"
                  v-model="newTask.schedule.schedule.start_hour"
              >
              </v-text-field>
              <v-text-field
                  label="Минуты"
                  type="number"
                  min="0"
                  max="59"
                  v-model="newTask.schedule.schedule.start_minute"
              >
              </v-text-field>
              <v-text-field
                  label="Секунды"
                  type="number"
                  min="0"
                  max="59"
                  v-model="newTask.schedule.schedule.start_second"
              >
              </v-text-field>
            </v-input>
          <v-btn-group>
            <v-checkbox
                label="ПН"
                v-model="newTask.schedule.schedule.week_days[0]"
            ></v-checkbox>
            <v-checkbox
                label="ВТ"
                v-model="newTask.schedule.schedule.week_days[1]"
            ></v-checkbox>
            <v-checkbox
                label="СР"
                v-model="newTask.schedule.schedule.week_days[2]"
            ></v-checkbox>
            <v-checkbox
                label="ЧТ"
                v-model="newTask.schedule.schedule.week_days[3]"
            ></v-checkbox>
            <v-checkbox
                label="ПТ"
                v-model="newTask.schedule.schedule.week_days[4]"
            ></v-checkbox>
            <v-checkbox
                label="СБ"
                v-model="newTask.schedule.schedule.week_days[5]"
            ></v-checkbox>
            <v-checkbox
                label="ВС"
                v-model="newTask.schedule.schedule.week_days[6]"
            ></v-checkbox>
          </v-btn-group>
        </template>
        <template
            v-if="newTask?.schedule && newTask.schedule.type==='Month'"
        >
          <v-input>
            <v-text-field
                label="Число"
                type="number"
                min="0"
                max="28"
                v-model="newTask.schedule.schedule.start_day"
            ></v-text-field>
            <v-text-field
                label="Часы"
                type="number"
                min="0"
                max="23"
                v-model="newTask.schedule.schedule.start_hour"
            >
            </v-text-field>
            <v-text-field
                label="Минуты"
                type="number"
                min="0"
                max="59"
                v-model="newTask.schedule.schedule.start_minute"
            >
            </v-text-field>
            <v-text-field
                label="Секунды"
                type="number"
                min="0"
                max="59"
                v-model="newTask.schedule.schedule.start_second"
            >
            </v-text-field>
          </v-input>
        </template>
        <template
            v-if="newTask?.schedule && newTask.schedule.type==='Duration'"
        >
          <v-input>
            <v-text-field
                label="Секунды"
                type="number"
                v-model="newTask.schedule.schedule.duration"
            ></v-text-field>
          </v-input>
        </template>
        <template
            v-if="newTask?.schedule && newTask.schedule.type==='Once'"
        >
          <v-input>
            <v-text-field
                label="Дата и Время"
                type="datetime-local"
                v-model="newTask.schedule.schedule.date_time"
            ></v-text-field>
          </v-input>
        </template>
        <v-select
          label="Планируемая к выполнению задача"
            :items="defaultOptions"
            item-title="name"
            item-value="value"
            v-model="newTask.options"
        ></v-select>
        <template v-if="newTask?.options">
          <template v-if="newTask.options.func==='billing'">
            <v-input>
              <v-select
                  label="Выберите Категорию Контейнеров по которой будет создаваться отчет"
                  v-model="newTask.options.options.group_id"
                  :items="devgroups"
                  item-title="name"
                  item-value="id"
              ></v-select>
            </v-input>
          </template>
        </template>
        <v-switch
            label="Активно"
            color="success"
            v-model="newTask.active"
        ></v-switch>
      </v-card-text>
      <v-card-actions>
        <v-row justify="space-around">
          <v-btn @click="modals.create=false">
            Закрыть
          </v-btn>
          <v-btn @click="saveNewTask">
            Добавить
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!--EDIT-->
  <v-dialog width="800" min-height="600" v-model="modals.edit">
    <v-card>
      <v-card-title>Редактирование Задания</v-card-title>
      <v-card-subtitle>Позволяет отредактировать автоматически выполняемое задание на портале</v-card-subtitle>
      <v-card-text>
        <v-input>
          <v-text-field
              label="Название задания"
              v-model="editedTask.name"
          ></v-text-field>
        </v-input>
        <v-select
            label="Правило Срабатывания"
            :items="defaultShedules"
            item-title="name"
            item-value="value"
            v-model="editedTask.schedule"
            return-object
        >
        </v-select>
        <template
            v-if="editedTask?.schedule && editedTask.schedule.value.type==='Week'"
        >
          <v-input>
            <v-text-field
                label="Часы"
                type="number"
                min="0"
                max="23"
                v-model="editedTask.schedule.value.schedule.start_hour"
            >
            </v-text-field>
            <v-text-field
                label="Минуты"
                type="number"
                min="0"
                max="59"
                v-model="editedTask.schedule.value.schedule.start_minute"
            >
            </v-text-field>
            <v-text-field
                label="Секунды"
                type="number"
                min="0"
                max="59"
                v-model="editedTask.schedule.value.schedule.start_second"
            >
            </v-text-field>
          </v-input>
          <v-btn-group>
            <v-checkbox
                label="ПН"
                v-model="editedTask.schedule.value.schedule.week_days[0]"
            ></v-checkbox>
            <v-checkbox
                label="ВТ"
                v-model="editedTask.schedule.value.schedule.week_days[1]"
            ></v-checkbox>
            <v-checkbox
                label="СР"
                v-model="editedTask.schedule.value.schedule.week_days[2]"
            ></v-checkbox>
            <v-checkbox
                label="ЧТ"
                v-model="editedTask.schedule.value.schedule.week_days[3]"
            ></v-checkbox>
            <v-checkbox
                label="ПТ"
                v-model="editedTask.schedule.value.schedule.week_days[4]"
            ></v-checkbox>
            <v-checkbox
                label="СБ"
                v-model="editedTask.schedule.value.schedule.week_days[5]"
            ></v-checkbox>
            <v-checkbox
                label="ВС"
                v-model="editedTask.schedule.value.schedule.week_days[6]"
            ></v-checkbox>
          </v-btn-group>
        </template>
        <template
            v-if="editedTask?.schedule && editedTask.schedule.value.type==='Month'"
        >
          <v-input>
            <v-text-field
                label="Число"
                type="number"
                min="0"
                max="28"
                v-model="editedTask.schedule.value.schedule.start_day"
            ></v-text-field>
            <v-text-field
                label="Часы"
                type="number"
                min="0"
                max="23"
                v-model="editedTask.schedule.value.schedule.start_hour"
            >
            </v-text-field>
            <v-text-field
                label="Минуты"
                type="number"
                min="0"
                max="59"
                v-model="editedTask.schedule.value.schedule.start_minute"
            >
            </v-text-field>
            <v-text-field
                label="Секунды"
                type="number"
                min="0"
                max="59"
                v-model="editedTask.schedule.value.schedule.start_second"
            >
            </v-text-field>
          </v-input>
        </template>
        <template
            v-if="editedTask?.schedule && editedTask.schedule.value.type==='Duration'"
        >
          <v-input>
            <v-text-field
                label="Секунды"
                type="number"
                v-model="editedTask.schedule.value.schedule.duration"
            ></v-text-field>
          </v-input>
        </template>
        <template
            v-if="editedTask?.schedule && editedTask.schedule.value.type==='Once'"
        >
          <v-input>
            <v-text-field
                label="Дата и Время"
                type="datetime-local"
                v-model="editedTask.schedule.value.schedule.date_time"
            ></v-text-field>
          </v-input>
        </template>
        <v-select
            label="Планируемая к выполнению задача"
            :items="defaultOptions"
            item-title="name"
            item-value="value"
            v-model="editedTask.options"
            return-object
        ></v-select>
        <template v-if="editedTask?.options">
          <template v-if="editedTask.options.value.func==='billing'">
            <v-input>
              <v-select
                  label="Выберите Категорию Контейнеров по которой будет создаваться отчет"
                  v-model="editedTask.options.value.options.group_id"
                  :items="devgroups"
                  item-title="name"
                  item-value="id"
              ></v-select>
            </v-input>
          </template>
        </template>
        <v-switch
            label="Активно"
            color="success"
            v-model="editedTask.active"
        ></v-switch>
      </v-card-text>
      <v-card-actions>
        <v-row justify="space-around">
          <v-btn @click="modals.edit=false">
            Закрыть
          </v-btn>
          <v-btn @click="saveEditedTask">
            Обновить
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!--DELETE-->
  <v-dialog
      width="500"
      height="300"
      v-model="modals.delete"
  >
    <v-card>
      <v-toolbar color="black">
        <v-toolbar-title>Требуется подтверждение</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        Вы действительно хотите удалить задание <span style="font-weight:bold">"{{toDelete?.name}}"</span>?
      </v-card-text>
      <v-card-actions>
          <v-row justify="space-around">
            <v-btn
                @click="modals.delete=false;toDelete=undefined"
            >Отмена</v-btn>
            <v-btn
                @click="deleteTask"
            >Удалить</v-btn>
          </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>

export default {
  name: "TaskManager",
  data(){
    return{
      loading:false,
      scheduleTypes:new Map([
          ["Month","Ежемесячное"],
          ["Week","Ежедневное"],
          ["Once","Одноразовое"],
          ["Duration","Циклическое"],
      ]),
      scheduleFuncs:new Map([
          ["billing","Биллинговый отчет"],
      ]),
      defaultShedules:[
        {
          name:'Ежемесячное',
          value:{
            type:'Month',
            schedule:{
              start_day:1,
              start_hour:0,
              start_minute:0,
              start_second:0
            }
          }
        },
        {
          name:'Ежедневное',
          value:{
            type:'Week',
            schedule:{
              week_days:[true,true,true,true,true,true,true],
              start_hour:0,
              start_minute:0,
              start_second:0
            }
          }
        },
        {
          name:'Одноразовое',
          value:{
            type:'Once',
            schedule:{
              date_time:new Date()
            }
          }
        },
        {
          name:'Циклическое',
          value:{
            type:'Duration',
            schedule:{
              duration:10
            }
          }
        },
      ],
      defaultOptions:[{
        name:"Биллинговый отчет",
        value:{
          func:"billing",
          options:{
            group_id:undefined
          }
        }
      }],
       modals:{
        create:false,
        edit:false,
        delete:false
      },
      editedTask:undefined,
      newTask:undefined,
      toDelete:undefined
    }
  },
  computed:{
    tableHeight(){
      return window.innerHeight-180
    },
    tasks(){
      return this.$store.getters['tasks/tasks']
    },
    devgroups(){
      return this.$store.getters['devices/devgroups']
    }
  },
  created() {
    this.$store.dispatch('tasks/getTasks')
  },
  methods:{
    settEditedTask(obj){
      this.editedTask={
        id:obj.id,
        name:obj.name,
        schedule:{
          name:undefined,
          value:{schedule:obj.schedule,
            type:obj.schedule_type
          }
        },
        options:{
          name:undefined,
          value: {
            func: obj.func_name,
            options: obj.options
          }
        },
        active:obj.active
      }
      switch (obj.schedule_type){
        case 'Week':
          this.editedTask.schedule.name='Ежедневное'
              break;
        case 'Month':
          this.editedTask.schedule.name='Ежемесячное'
              break;
        case 'Once':
          this.editedTask.schedule.name='Одноразовое'
              break;
        case 'Duration':
          this.editedTask.schedule.name='Циклическое'
              break;
      }
      switch (obj.func_name){
        case 'billing':
          this.editedTask.options.name='Биллинговый отчет'
      }
      this.modals.edit=true
    },
    saveEditedTask(){
      const Payload={
        id:this.editedTask.id,
        name:this.editedTask.name,
        schedule:{},
        schedule_type:this.editedTask.schedule.value.type,
        func_name:this.editedTask.options.value.func,
        options:this.editedTask.options.value.options,
        active:this.editedTask.active
      }
      switch (Payload.schedule_type){
        case "Week":
          Payload.schedule={
            start_hour:Number(this.editedTask.schedule.value.schedule.start_hour),
            start_minute:Number(this.editedTask.schedule.value.schedule.start_minute),
            start_second:Number(this.editedTask.schedule.value.schedule.start_second),
            week_days:this.editedTask.schedule.value.schedule.week_days
          }
          break;
        case "Month":
          Payload.schedule={
            start_day:Number(this.editedTask.schedule.value.schedule.start_day),
            start_hour:Number(this.editedTask.schedule.value.schedule.start_hour),
            start_minute:Number(this.editedTask.schedule.value.schedule.start_minute),
            start_second:Number(this.editedTask.schedule.value.schedule.start_second),
          }
          break;
        case "Once":
          Payload.schedule={
            date_time:Math.floor(new Date(this.editedTask.schedule.value.schedule.date_time).getTime()/1000)
          }
          break;
        case 'Duration':
          Payload.schedule={
            duration:Number(this.editedTask.schedule.value.schedule.duration)
          }
          break;
      }
      this.$store.dispatch('tasks/updateTask',Payload)
          .catch(e=>{
            console.log(e)
          })
          .finally(()=>{
            this.modals.edit=false
          })
    },
    setNewTask(){
      this.newTask={
        name:undefined,
        schedule:undefined,
        options:undefined,
        active:true
      }
      this.modals.create=true
    },
    setToDelete(obj){
      this.toDelete={
        id:obj.id,
        name:obj.name
      }
      this.modals.delete=true
    },
    getGroupName(id){
      let res=id
      this.devgroups.forEach(group=>{
        if (group.id===id){
          res=group.name
        }
      })
      return res
    },
    saveNewTask(){
      const Payload={
        name:this.newTask.name,
        schedule:{},
        schedule_type:this.newTask.schedule.type,
        func_name:this.newTask.options.func,
        options:this.newTask.options.options,
        active:this.newTask.active
      }
      switch (Payload.schedule_type){
        case "Week":
          Payload.schedule={
            start_hour:Number(this.newTask.schedule.schedule.start_hour),
            start_minute:Number(this.newTask.schedule.schedule.start_minute),
            start_second:Number(this.newTask.schedule.schedule.start_second),
            week_days:this.newTask.schedule.schedule.week_days
          }
          break;
        case "Month":
          Payload.schedule={
            start_day:Number(this.newTask.schedule.schedule.start_day),
            start_hour:Number(this.newTask.schedule.schedule.start_hour),
            start_minute:Number(this.newTask.schedule.schedule.start_minute),
            start_second:Number(this.newTask.schedule.schedule.start_second),
          }
          break;
        case "Once":
          Payload.schedule={
            date_time:Math.floor(new Date(this.newTask.schedule.schedule.date_time).getTime()/1000)
          }
          break;
        case 'Duration':
          Payload.schedule={
            duration:Number(this.newTask.schedule.schedule.duration)
          }
          break;
      }
      //console.log(Payload)
      this.modals.create=false
      this.$store.dispatch('tasks/createTask',Payload)
          .then(()=>{console.log('Success')})
          .catch(e=>{console.log(e)})
    },
    deleteTask(){
      this.modals.delete=false
      this.$store.dispatch('tasks/deleteTask',this.toDelete.id)
          .then(()=>{
            console.log("Успешно Удалено")
          })
          .catch(e=>{
            console.log(e)
          })
    },
    restartTaskManager(){
      this.loading=true
      this.modals.delete=false
      this.$store.dispatch('tasks/restartTaskManager')
          .catch(e=>{
            console.log(e)
          })
          .finally(()=>{
            this.loading=false
          })
    },
  }
}
</script>

<style scoped>
.add-new-item{
  position: fixed;
  bottom: 36px;
  right: 72px;
}
.restart-item{
  position: fixed;
  top: 100px;
  right: 72px;
}
</style>