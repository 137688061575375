<template>
<v-container>
  <v-col>
    <v-row justify="center">
      <v-col>
        <v-row justify="space-between">
          <h2>Отчет по Инцидентам</h2><template v-if="reportReady">
          <v-menu transition="slide-x-transition">
            <template v-slot:activator="{ props }">
              <v-btn
                  icon="mdi-download"
                  color="success"
                  v-bind="props"
              >
              </v-btn>
            </template>
            <v-list>
              <v-list-subheader>Формат выгрузки</v-list-subheader>
              <v-list-item
                  @click="createPDF()"
              >
                <template v-slot:prepend>
                  <v-icon size="32px" color="#F40407" icon="mdi-file-pdf-box"></v-icon>
                </template>
                <v-list-item-title>PDF</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="createXLSX()"
              >
                <template v-slot:prepend>
                  <v-icon size="32px" color="#185C11" icon="mdi-microsoft-excel"></v-icon>
                </template>
                <v-list-item-title>XLSX</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        </v-row>
        <v-table>
          <thead>
          <tr>
            <td style="text-align: center">Начало периода</td>
            <td style="text-align: center">Конец периода</td>
            <td style="width: 50%;text-align: center">Фильтры типа инцидентов</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <v-text-field variant="underlined" type="date" v-model="request.startdate"></v-text-field>
            </td>
            <td>
              <v-text-field variant="underlined" type="date" v-model="request.enddate"></v-text-field>
            </td>
            <td>
              <v-select
                  v-model="selectedFilter"
                  label="Фильтр по типу инцидента"
                  :items="filterItems"
                  item-title="name"
                  item-value="value"
                  multiple
                  closable-chips
                  counter
                  clearable
                  variant="underlined"
              >
                <template v-slot:selection="{ item }">
                  <v-chip
                      variant="outlined"
                      color="orange"
                      size="small"
                  >
                    {{ item.raw.name }}
                  </v-chip>
                </template>
              </v-select>
            </td>
          </tr>
          </tbody>
        </v-table>
        <v-data-table
            v-if="filteraddata"
            v-model:items-per-page="itemsPerPage"
            :headers="headers"
            :items="filteraddata"
            item-value="name"
            class="elevation-1"
            :height="dataheight"
            fixed-header
            fixed-footer
        >
          <template v-slot:[`item.Geofences`]="{ item }">
            <v-chip-group>
              <template v-for="(g,i) in item.raw.Geofences" v-bind:key="i">
                <v-chip
                    variant="outlined"
                    label
                    size="small"
                >
                  {{ g }}
                </v-chip>
              </template>
            </v-chip-group>
          </template>
          <template v-slot:[`item.Timestamp`]="{ item }">
            {{new Date(item.raw.Timestamp*1000).toLocaleString()}}
          </template>
          <template v-slot:[`item.CoordsTS`]="{ item }">
            {{new Date(item.raw.CoordsTS*1000).toLocaleString()}}
          </template>
          <template v-slot:[`item.Latitude`]="{ item }">
            {{item.raw.Latitude.toFixed(5)}}
          </template>
          <template v-slot:[`item.Longitude`]="{ item }">
            {{item.raw.Longitude.toFixed(5)}}
          </template>
          <template v-slot:[`item.IncidentType`]="{ item }">
            <v-chip
                variant="outlined"
                color="orange"
                size="small"
            >
              {{incMap.get(item.raw.IncidentType)}}
            </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-col>
</v-container>
</template>

<script>
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
let XLSX = require("xlsx");
export default {
  name: "IncidentsReport",
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      request: {
        startdate: '',
        enddate:''
      },
      filterItems: [
        {value:"separation", name:"Отрыв устройства"},
        {value:"acceleration",name:"Детектор Удар"},
        {value:"opening",name:"Вскрытие устройства"},
        {value:"twist",name:"Переворот"},
        {value:"temperature",name:"Температура"},
        {value:"battery",name:"Низкий Заряд АКБ"}
      ],
      selectedFilter:[ "opening", "temperature", "battery", "acceleration", "separation", "twist" ],
      itemsPerPage: 10,
      headers: [
        {
          title: 'Дата и время',
          align: 'center',
          sortable: true,
          key: 'Timestamp',
        },
        {
          title: 'Контейнер',
          align: 'center',
          key: 'ContainerID'
        },
        {
          title: 'Устройство',
          align: 'center',
          key: 'DeviceID'
        },
        {
          title: 'Тип инцидента',
          align: 'center',
          key: 'IncidentType'
        },
        {
          title: 'Порог значения',
          align: 'center',
          key: 'Threshold'
        },
        {
          title: 'Значение',
          align: 'center',
          key: 'EventValue'
        },
        /*{
          title: 'Время Координат',
          align: 'center',
          key: 'CoordsTS'
        },*/
        {
          title: 'Спутники',
          align: 'center',
          key: 'Salellites'
        },
        {
          title: 'Широта',
          align: 'center',
          key: 'Latitude'
        },
        {
          title: 'Долгота',
          align: 'center',
          key: 'Longitude'
        },
        {
          title: 'Скорость',
          align: 'center',
          key: 'Speed'
        },
        {
          title: 'Зоны',
          align: 'center',
          key: 'Geofences'
        },
      ],
      responseData:[],
      reportReady:false,
      incMap : new Map([
        ["separation","Отрыв устройства"],
        ["acceleration","Детектор Удара"],
        ["opening","Вскрытие устройства"],
        ["twist","Переворот"],
        ["temperature","Температура"],
        ["battery","Низкий Заряд АКБ"],
      ])
    }
  },
  computed:{
    dataheight(){
      return window.innerHeight-320
    },
    loading:{
      set: function (newval) {
        this.$store.dispatch('app/loading',newval)
      },
      get: function (){
        return this.$store.getters['app/loader']
      }
    },
    geofences: function (){
      return this.$store.getters['geofences/geofences']
    },
    filteraddata:function (){
      if(this.responseData.length){
        return this.responseData.filter(d=>{
          let res=false
          if(this.selectedFilter.length){
            this.selectedFilter.forEach(fi=>{
              if(d.IncidentType===fi){
                res=true
              }
            })
          }
          return res
        })
      }else{
        return undefined
      }
    }
  },
  watch:{
    'request':{
      handler(newval){
        if (newval.startdate&&newval.enddate&&newval.startdate<newval.enddate){
          this.loading=true
          this.reportReady=true
          const from=new Date(newval.startdate)
          const to=new Date(newval.enddate)
          const payload={
            from:from.getTime()/1000,
            to:to.getTime()/1000
          }
          this.$store.dispatch('reports/getIncidentsReport', payload)
              .then(response => {
                this.responseData=response.data
                this.loading=false
              })
              .catch(err => {
                this.responseData=[]
                console.log(err)
                this.loading=false
              })
        }
        else{
          this.reportReady=false
          this.loading=false
        }
      }, deep: true
    }
  },
  methods: {
    createXLSX(){
      const tz=Date().toString().match(/([A-Z]+[\\+-][0-9]+.*)/)[1]
      let wb=XLSX.utils.book_new()
      let worksheet = XLSX.utils.aoa_to_sheet([
        ["Отчет по Инцидентам"],
        ["Дата начала периода:",new Date(this.request.startdate)],
        ["Дата окончания периода:",new Date(this.request.enddate)],
        ["Временная зона отчета:", tz],
        [],
        ['Дата и время','Контейнер','Устройство','Тип инцидента','Порог значения','Значение','Спутники','Широта','Долгота','Скорость','Зоны']
      ]);
      worksheet["!autofilter"]={ ref: `A6:K${String(this.filteraddata.length+6)}` };
      this.filteraddata.forEach((row)=>{
        let geofs=""
        if(row.Geofences.length){
          row.Geofences.forEach(geof=>{
            geofs+=geof+', '
          })
        }
        if (geofs.length>2){
          geofs=geofs.slice(0,geofs.length-2)
        }
        XLSX.utils.sheet_add_aoa(worksheet, [
          [
            new Date(row.Timestamp*1000).toLocaleString(),
            row.ContainerID,
            row.DeviceID,
            this.incMap.get(row.IncidentType),
            row.Threshold,
            row.EventValue,
            row.Salellites,
            row.Latitude,
            row.Longitude,
            row.Speed,
            geofs,
          ]
        ], {origin:-1});
      })
      XLSX.utils.book_append_sheet(wb, worksheet, "Отчет");
      XLSX.writeFile(wb, 'Отчет по Инцидентам.xlsx');
    },
    deviceName() {
      let name=''
      this.containers.forEach(dev=>{
        if(dev.uuid===this.request.uuid){
          name=dev.name
        }
      })
      return name
    },
    groupName() {
      let name=''
      this.devgroups.forEach(group=>{
        if(group.id===this.request.groupid){
          name=group.name
        }
      })
      return name
    },
    createPDF() {
      const tz=Date().toString().match(/([A-Z]+[\\+-][0-9]+.*)/)[1]
      var dd = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [ 40, 60, 40, 60 ],
        content: [
          {
            text: 'Отчет по инцидентам',
            style: 'header',
            alignment: 'left'
          },
          {
            text: 'Начало периода: '+new Date(this.request.startdate).toLocaleString(),
            style: 'time',
            alignment: 'left'
          },
          {
            text: 'Конец периода: '+new Date(this.request.enddate).toLocaleString(),
            style: 'time',
            alignment: 'left'
          },
          {
            text: 'Временная зона: '+ tz,
            style: 'time',
            alignment: 'left'
          },
          {
            style: 'tableExample',
            table: {
              body: [
                ['Дата и время','Контейнер','Устройство','Тип инцидента','Порог значения','Значение','Спутники','Широта','Долгота','Скорость','Зоны'],
              ]
            }
          }
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            alignment: 'justify',
            margin: [0, 20],
            color: 'black'
          },
          time:{
            fontSize: 10,
            bold: false,
            alignment: 'justify',
            margin: [0, 10]
          },
          tableExample: {
            margin: [5, 5, 5, 5],
            bold:false,
            fontSize:10
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
          }
        }
      }
      this.filteraddata.forEach(row=>{
        let geofs=""
        if(row.Geofences.length){
          row.Geofences.forEach(geof=>{
            geofs+=geof+', '
          })
        }
        if (geofs.length>2){
          geofs=geofs.slice(0,geofs.length-2)
        }
        //const link={text:"Карта", link:"http://www.google.com/maps/place/"+Latitude+","+Longitude, fontSize:12,color:'blue'}
        dd.content[4].table.body.push(
            [
              new Date(row.Timestamp*1000).toLocaleString(),
              row.ContainerID,
              row.DeviceID,
              this.incMap.get(row.IncidentType),
              row.Threshold,
              row.EventValue,
              row.Salellites,
              row.Latitude.toFixed(5),
              row.Longitude.toFixed(5),
              row.Speed,
              geofs,
            ]
        )
      })
      pdfMake.createPdf(dd).open();
    }
  }
}
</script>

<style scoped>

</style>