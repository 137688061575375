import {expandUrl} from "ol/tileurlfunction";

export default {
    getAddress({commit},request) {
        commit('loading')
        return new Promise((resolve, reject) => {
            this.$http.get(
                'https://nominatim.openstreetmap.org/search?q='+expandUrl(request)+'&format=json&polygon_geojson=1&addressdetails=1')
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    loading({commit},pl){
        commit('load',pl)
    },
    drawner({commit},pl){
        commit('drawner',pl)
    },
    getGlobalSettings({commit}) {
        return new Promise((resolve, reject) => {
            this.$http.get('/api/settings/global')
                .then(resp => {
                    commit('setGlobalSettingsToStorage',resp.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    setGlobalSettings({commit},pl) {
        commit('loading')
        return new Promise((resolve, reject) => {
            this.$http.put('/api/settings/global',pl)
                .then(resp => {
                    commit('setGlobalSettingsToStorage',pl)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}