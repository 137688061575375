export default {
    getRoles({commit}) {
        return new Promise((resolve, reject) => {
            this.$http.get('/api/roles')
                .then(resp => {
                    commit('getRolesSuccess', resp.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    updateRole({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            this.$http.put('/api/roles',payload)
                .then(resp => {
                    dispatch('getRoles')
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    createRole({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            this.$http.post('/api/roles',payload)
                .then(()=>{
                    dispatch('getRoles')
                    resolve()
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    deleteRole({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            this.$http.delete('/api/roles',{data:payload})
                .then(resp => {
                    dispatch('getRoles')
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}